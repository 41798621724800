import styled from 'styled-components'

export const Container = styled.div`

    font-family: Roboto;
    font-size: 20px;
    padding: 20px 60px 20px 20px;
    width: 80vw;
    background-color: #F2F2F3;
    padding-top: 137px;
    height: 100%;

`