/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Container } from './style'

import { api } from '../../api'

import { useLocation, Link } from 'react-router-dom'

// habilitar thema dark
import { createTheme , MuiThemeProvider } from '@material-ui/core/styles'

import Config from '../../Config'

const theme = createTheme ({
    palette: {
        primary: {
            main: Config.primary_color,
        },
        secondary: {
            main: Config.secondary_color,
        },
        type: 'dark'
    }
})

const Login = () => {

    const [ loading, setLoading ] = useState(false)

    const location = useLocation()
    
    // A custom hook that builds on useLocation to parse
    // the query string for you.
    // function useQuery() { return new URLSearchParams(useLocation().search) }

    // var query = useQuery()
    // var returnTo = query.get("returnTo")

    const handleSubmit = async (e) => {

        e.preventDefault()

        var email = document.getElementById('email').value.toLowerCase()
        var password = document.getElementById('password').value

        if (email === '' || password === '') return alert('Preencha todos os campos')
        
        email = email.toLowerCase()

        var dadosLogin = {
            email,
            password
        }

        try {
            setLoading(true)

            var { data } = await api.post('/login', dadosLogin)

            localStorage.setItem('lastUserLogged', email)
            localStorage.setItem('userId', data.user.id)
            localStorage.setItem('token', data.token)
            localStorage.setItem('captador', email)
            
            var returnTo = localStorage.getItem('returnTo')

            // rediciona com window.locaton pra atualizar a tela e aparecer o menu do dash
            if (returnTo) {
                localStorage.removeItem('returnTo')

                if (returnTo.includes('returnTo=')) {
                    returnTo = returnTo.split('=')[1]
                }

                window.location.href = returnTo
            } else {
                window.location.href = Config.URL_home
            }
        }
        
        catch(err) {
            alert('Usuario ou senha invalido')
            console.error('Usuario ou senha invalido', err, location.pathname)
        }
        
        setLoading(false)
    }

    useEffect(() => {

        var lastUserLogged = localStorage.getItem('lastUserLogged')

        if (lastUserLogged) {
            document.getElementById('email').value = lastUserLogged
            document.getElementById('password').focus()
        }

    }, [location])

    return (
        <Container>
        <MuiThemeProvider theme={theme}>

            <Link to="/">
                <img src="/logo-site-alpha-select.png" id="logo-jeb" alt="Logo Alpha Select" />
            </Link>

            <br />
            
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    id="email"
                    label="seu usuário"
                    type="text"
                    variant="outlined"
                    required
                />

                <br />
                <br />

                <TextField
                    fullWidth
                    id="password"
                    label="Senha"
                    type="password"
                    variant="outlined"
                    required
                />

                <br />
                <br />

                <div id="buttons-entrar-esqueci-senha">
                    <Button
                        variant="contained"
                        color={loading ? 'seconday' : 'primary'}
                        // onClick={() => handleSubmit()}
                        type="submit"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : ''}
                    >
                        {loading ? 'Acessando...' : 'Entrar na conta'}
                    </Button>

                    {/* <p>Esqueci a senha</p> */}
                </div>

                <hr />

                {/* <div id="cadastre-se">
                    <h2>Não tem uma conta?</h2>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled
                    >
                    Cadastre-se
                    </Button>
                </div> */}
            </form>

        </MuiThemeProvider>
        </Container>
    )
}

export default Login
