/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { Container } from './style'

const EncontrarImovel = () => {

    return (
        <Container>

        </Container>
    )
}

export default EncontrarImovel