/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'

import { useLocation, Link, useHistory } from 'react-router-dom'

import { Container, ContainerCarousel } from './style'

import { apiWithCache } from '../../api'
import { globalStateContext } from '../../GlobalState'

import Chip from '@material-ui/core/Chip'

import Skeleton from '@material-ui/lab/Skeleton'
import Config from '../../Config'

import { Carousel } from 'react-responsive-carousel'

import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { shuffleArray } from '../../Functions'

import Footer from '../../Layout/Footer'

import Icon from '@material-ui/core/Icon'

import { Helmet } from 'react-helmet'

import AutoCompleteLocalizacao from '../../Components/autoCompleteLocalizacao'

const Feed = () => {

    const [GlobalState, setGlobalState] = globalStateContext.use()

    const [firstLoading, setFirstLoading] = useState(false)

    const location = useLocation()
    const history = useHistory()

    const [condominioDestaque,] = useState(
        shuffleArray([
            {
                id: 1,
                nome: "LAGO DA BOA VISTA",
                enabled: false
            },
            {
                id: 2,
                nome: "CHACARA ONDINA",
                enabled: true
            },
            {
                id: 3,
                nome: "FAZENDA IMPERIAL",
                enabled: true
            },
            {
                id: 4,
                nome: "IBITI ROYAL",
                enabled: true
            },
            {
                id: 5,
                nome: "LE FRANCE",
                enabled: true
            },
            {
                id: 6,
                nome: "HORTO VILLAGIO",
                enabled: true
            },
            {
                id: 7,
                nome: "CYRELA LANDSCAPE",
                enabled: true
            },
            {
                id: 8,
                nome: "SUNLAKE",
                enabled: true
            },
            {
                id: 9,
                nome: "VILLA DOS INGLEZES",
                enabled: true
            },
            {
                id: 10,
                nome: "ALPHA DIJON",
                enabled: true
            },
            {
                id: 11,
                nome: "IBITI RESERVA",
                enabled: true
            },
            {
                id: 12,
                nome: "GIVERNY",
                enabled: true
            },
            {
                id: 13,
                nome: "TERRA NOVA",
                enabled: true
            },
            {
                id: 14,
                nome: "VILLAGIO MILANO",
                enabled: true
            }
        ])
    )

    const [items, setItems] = useState([])

    const [items2, setItems2] = useState([])

    const [items3, setItems3] = useState([])

    const [items4, setItems4] = useState([])

    // const [imovelConfigs, setImovelConfigs] = useState({
    //     tipoNegociacao: localStorage.getItem('tipoNegociacao') ?? 'compra'
    // })

    useEffect(() => {

        async function getItems() {

            document.getElementById('autocomplete-highlight-search').value = ''
            
            setGlobalState({ ...GlobalState, loading: true, searchText: null })
            setFirstLoading(true)

            var firstResponse = await apiWithCache.get('/feed?tipo=fast')
            setItems(firstResponse.data.data)
            setFirstLoading(false)

            var response = await apiWithCache.get('/feed')

            var data2 = [...response.data.data.slice(0, 19)]
            var data3 = [...response.data.data.slice(20, 39)]
            var data4 = [...response.data.data.slice(40, 60)]

            setItems2(data2)
            setItems3(data3)
            setItems4(data4)

            setGlobalState({ ...GlobalState, loading: false, searchText: null })
        }

        function removeIncorrectTipoNegocioLocalStorage() {
            let tipoNegocio = localStorage.getItem('tipoNegociacao')

            if (tipoNegocio !== 'compra' && tipoNegocio !== 'aluguel') {
                localStorage.removeItem('tipoNegociacao')
            }
        }

        getItems()


        // antigamente estava sendo setado um valor incorreto no localStorage que dava erro
        // o valor setado pelo frontend era diferente do que está no banco alugar e aluguel
        // essa função apaga se tiver o antigo guardado - remover futuramente quando o site for lançado
        removeIncorrectTipoNegocioLocalStorage()

    }, [location])

    // function handleChangeTipoNegociacao(tipo) {
    //     setImovelConfigs({ ...imovelConfigs, tipoNegociacao: tipo })
    //     localStorage.setItem('tipoNegociacao', tipo)
    // }

    function scrollSlide(direction, element) {

        element = document.getElementById(element)

        if (direction === 'left') {
            element.scrollBy(-500, 0)
        }

        if (direction === 'right') {
            element.scrollBy(500, 0)
        }
    }

    const [filtros, setFiltros] = useState({
        localizacao: 'selecione',
        tipoImovel: 'selecione',
        categoriaPreco: 'selecione',
        qtdQuartos: 'selecione',
    })

    function doSearchWithFilter () {

        let filtroQuarto = ''
        let filtroPreco = ''
        let filtroTipo = ''
        
        if (filtros.qtdQuartos !== 'selecione') {
            filtroQuarto = `&qtdQuartos=${filtros.qtdQuartos}`
        }
        
        if (filtros.categoriaPreco !== 'selecione') {
            filtroPreco = `&categoriaPreco=${filtros.categoriaPreco}`
        }
        
        if (filtros.tipoImovel !== 'selecione') {
            filtroTipo = `&tipoImovel=${filtros.tipoImovel}`
        }

        // para que não faça a busca com palavra null - se enviar sem texto a busca funciona encontrando tudo
        let searchText = GlobalState.searchText ?? ''

        history.push('/encontrar-imovel-sorocaba/' + searchText + '?' + filtroPreco + filtroQuarto + filtroTipo)
    }

    return (

        <>

            <Helmet>‍
                <title>Alpha Select Imobiliaria - Casas em condominios - Sorocaba/SP</title>‍
                <meta property="og:title" content="Alpha Select Imobiliaria - Casas em condominios - Sorocaba/SP" />
                <meta property="og:description" content="Alpha Select Imobiliaria - Casas em condominios - Sorocaba/SP" />
            </Helmet>

            <ContainerCarousel>
                <Carousel showThumbs={false} infiniteLoop autoPlay stopOnHover={false} interval={4000} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
                    <div className="slider-home">
                        <img src="/slides-index/slide-1-desktop.jpg" alt="Banner Alpha Select" className="slide-desktop" />
                        <img src="/slides-index/slide-1-mobile.jpg" alt="Banner Alpha Select" className="slide-mobile" />
                    </div>
                    <div className="slider-home">
                        <img src="/slides-index/slide-2-desktop.jpg" alt="Banner Alpha Select" className="slide-desktop" />
                        <img src="/slides-index/slide-2-mobile.jpg" alt="Banner Alpha Select" className="slide-mobile" />
                    </div>
                    <div className="slider-home">
                        <img src="/slides-index/slide-3-desktop.jpg" alt="Banner Alpha Select" className="slide-desktop" />
                        <img src="/slides-index/slide-3-mobile.jpg" alt="Banner Alpha Select" className="slide-mobile" />
                    </div>
                    <div className="slider-home">
                        <img src="/slides-index/slide-4-desktop.jpg" alt="Banner Alpha Select" className="slide-desktop" />
                        <img src="/slides-index/slide-4-mobile.jpg" alt="Banner Alpha Select" className="slide-mobile" />
                    </div>
                    {/* EXEMPLO USANDO BG-CONTENT - TEXTO COMO COMPONENTE E NÃO NA IMAGEM
                    <div className="slider-home">
                        <a href="https://mlbconstrutora.com.br/empreendimentos/versalhes">
                            <div className="bg-content">
                                <h2>LANÇAMENTO<br/><strong>CONSTRUTORA MLB</strong></h2>
                                <Button variant="contained" color="primary" size="small">CONHEÇA APTO DE ALTO PADRÃO</Button>
                            </div>
                        </a>
                        <img src="/slides-index/slide-3.png" alt="Banner Alpha Select" className="slide-desktop" />
                        <img src="/slides-index/slide-3-mobile.png" alt="Banner Alpha Select" className="slide-mobile" />
                    </div> */}
                </Carousel>
                <div id="container-filtros">
                    <div id="titulo-filtros">
                        <Icon>travel_explore</Icon><h2>ENCONTRE SEU LAR</h2>
                    </div>

                    <div id="padding-filtros">
                        {/* <Select
                                variant="outlined"
                                value={filtros.localizacao}
                                fullWidth
                                onChange={e => {
                                    setFiltros({ ...filtros, localizacao: e.target.value })
                                    localStorage.setItem('localizacao', e.target.value)
                                }}
                            >
                                <MenuItem value="selecione">Localização</MenuItem>
                                <MenuItem value="Logística e armamazenagem">Bairros</MenuItem>
                            </Select> */}

                        <Select
                            variant="outlined"
                            value={filtros.tipoImovel}
                            fullWidth
                            onChange={e => {
                                setFiltros({ ...filtros, tipoImovel: e.target.value })
                                localStorage.setItem('tipoImovel', e.target.value)
                            }}
                        >
                            <MenuItem value="selecione">Tipo de imóvel</MenuItem>
                            <MenuItem value='Apartamento'>Apartamento</MenuItem>
                            <MenuItem value='Casa'>Casa</MenuItem>
                            <MenuItem value='Casa Condomínio'>Casa Condomínio</MenuItem>
                            <MenuItem value='Terreno'>Terreno</MenuItem>
                            <MenuItem value='Lote Condomínio'>Lote Condomínio</MenuItem>
                            {/* <MenuItem value='Galpão'>Galpão</MenuItem> */}
                            {/* <MenuItem value='Sala Comercial'>Sala Comercial</MenuItem> */}
                            <MenuItem value='Chácara'>Chácara</MenuItem>
                        </Select>

                        <Select
                            variant="outlined"
                            value={filtros.qtdQuartos}
                            style={{ width: '49%', marginRight: '2%' }}
                            onChange={e => {
                                setFiltros({ ...filtros, qtdQuartos: e.target.value })
                                localStorage.setItem('qtdQuartos', e.target.value)
                            }}
                        >
                            <MenuItem value="selecione">Quartos</MenuItem>
                            <MenuItem value="0">Qualquer</MenuItem>
                            <MenuItem value="1">1+</MenuItem>
                            <MenuItem value="2">2+</MenuItem>
                            <MenuItem value="3">3+</MenuItem>
                            <MenuItem value="4">4+</MenuItem>
                            <MenuItem value="5">5+</MenuItem>
                        </Select>

                        <Select
                            variant="outlined"
                            value={filtros.categoriaPreco}
                            style={{ width: '49%', marginBottom: -50 }}
                            onChange={e => {
                                setFiltros({ ...filtros, categoriaPreco: e.target.value })
                                localStorage.setItem('categoriaPreco', e.target.value)
                            }}
                        >
                            <MenuItem value="selecione">Faixa de Valor</MenuItem>
                            <MenuItem value="1">Até R$220 mil</MenuItem>
                            <MenuItem value="2">De R$200 mil a R$ 500 mil</MenuItem>
                            <MenuItem value="3">De R$500 mil a R$ R$800 mil</MenuItem>
                            <MenuItem value="4">De R$800 mil a R$ 1,2 milhões</MenuItem>
                            <MenuItem value="5">Acima de R$ 1,2 milhões</MenuItem>
                        </Select>

                        <AutoCompleteLocalizacao gotoOnSelect={false} isTopBar={false} size="large" />

                        <span style={{ fontSize: 14 }}>* Nenhum dos campos é obrigatório</span>

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            // style={{ width: '50%', marginTop: 10 }}
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={doSearchWithFilter}
                            startIcon={<Icon>search</Icon>}
                        >
                            BUSCAR
                        </Button>

                        {/* <Button
                            variant="text"
                            size="small"
                            className='hideMobile'
                            style={{ width: '50%' }}
                        >
                            Anuncie seu imóvel
                        </Button> */}

                    </div>
                </div>
            </ContainerCarousel>

            <Container>

                {/* <div id="main-feed">

                    <h1 style={{ marginTop: 40 }}>Compre ou Alugue <br />seu imóvel em Sorocaba</h1>

                    <p>Busque e encontre tudo fácil logo abaixo</p>
                    <br />

                    <div id="select-alugar-ou-comprar">

                        <div
                            id="select-comprar"
                            className={imovelConfigs.tipoNegociacao === 'compra' ? 'select-activated' : ''}
                            onClick={() => handleChangeTipoNegociacao('compra')}>
                            <span>COMPRAR</span>
                        </div>

                        <div
                            id="select-alugar"
                            className={imovelConfigs.tipoNegociacao === 'aluguel' ? 'select-activated' : ''}
                            onClick={() => handleChangeTipoNegociacao('aluguel')}>
                            <span>ALUGAR</span>
                        </div>

                    </div>

                    <br />

                    <Link to="encontrar-imovel-sorocaba" id="barra-buscar">
                        <div id="goto-search" className="gradient-border">
                            <Icon id="goto-search-icon">search</Icon>
                            <p>Busque por bairro, rua, condominio</p>
                        </div>
                    </Link>

                    <br />

                    <p id="proprietario-anuncie">
                        Proprietário, anuncie seu imóvel conosco e tenha alta visualização
                    </p>

                </div> */}

                <div id="padding">

                    <h2 id="condominio-destaque-texto">CONDOMIÍNIOS EM DESTAQUE</h2>

                    <div className="feed-category" id="condominios-destaque">

                        <div id="arrow-left" onClick={() => scrollSlide('left', 'condominios-destaque')}></div>

                        {
                            condominioDestaque.map((item) =>

                                <Link to={'/encontrar-imovel-sorocaba/' + item.nome} key={item.id} className={item.enabled ? 'card-link loaded' : 'disabled'}>
                                    <div className="card" key={item.id}>
                                        <div className="card-img">
                                            <img
                                                effect="opacity"
                                                src={`/slides-condominio/condominio-${item.id}.png`}
                                                alt={item.nome}
                                                loading="lazy"
                                            />
                                        </div>

                                        <div className="condominio-nome">
                                            <span>{item.nome}</span>
                                        </div>

                                    </div>
                                </Link>
                            )
                        }

                        <div id="arrow-right" onClick={() => scrollSlide('right', 'condominios-destaque')}></div>

                    </div>

                    <br />
                    <br />

                    {firstLoading ?

                        <div className="feed-category" style={{ overflowX: 'hidden' }}>
                            <div className="card">
                                <Skeleton animation="wave" variant="rect" className="card-img" />
                                <div className="card-infos">

                                    <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                    <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                    <Skeleton animation="wave" variant="rect" className="localizacao" />

                                    <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                    <Skeleton animation="wave" variant="rect" className="valor" />
                                    <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                </div>
                            </div>

                            <div className="card">
                                <Skeleton animation="wave" variant="rect" className="card-img" />
                                <div className="card-infos">

                                    <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                    <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                    <Skeleton animation="wave" variant="rect" className="localizacao" />

                                    <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                    <Skeleton animation="wave" variant="rect" className="valor" />
                                    <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                </div>
                            </div>

                            <div className="card">
                                <Skeleton animation="wave" variant="rect" className="card-img" />
                                <div className="card-infos">

                                    <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                    <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                    <Skeleton animation="wave" variant="rect" className="localizacao" />

                                    <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                    <Skeleton animation="wave" variant="rect" className="valor" />
                                    <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                </div>
                            </div>

                            <div className="card">
                                <Skeleton animation="wave" variant="rect" className="card-img" />
                                <div className="card-infos">

                                    <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                    <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                    <Skeleton animation="wave" variant="rect" className="localizacao" />

                                    <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                    <Skeleton animation="wave" variant="rect" className="valor" />
                                    <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                </div>
                            </div>
                        </div>

                        :

                        <>
                            {/* <p>Imóveis que você navegou com 4 quartos no Campolim</p> */}
                            {/* <br /> */}

                            <div className="feed-category feed-vertical-mobile" id="slide1">

                                <div id="arrow-left" onClick={() => scrollSlide('left', 'slide1')}></div>

                                {
                                    items?.map((item, index) =>

                                        <Link target={Config.OPEN_IMOVEL_NEW_TAB ? '_blank' : '_self'} to={`/imovel/${item.cidade}/${item.tipoImovel}/${item.bairro}/${item.id}`.trim().replaceAll(' ','-')} key={item.id} id={'items-img' + index} className="card-link">
                                            <div className="card" key={item.id}>

                                                <div className="card-img">
                                                    <img
                                                        effect="opacity"
                                                        src={Config.AWS_S3_BUCKET_DOMAIN + item?.img}
                                                        alt={item.title}
                                                        onError={() => {
                                                            if (!document.getElementById('img' + index)) return
                                                            document.getElementById('img' + index).style.display = 'none'
                                                        }
                                                        }
                                                        loading="lazy"
                                                        onLoad={() => document.getElementById('items-img' + index).classList.add('loaded')}
                                                    />
                                                    <Chip
                                                        className="chip"
                                                        label={item.incluso?.includes('lancamento') ? 'Lançamento' : item.aceitaPermuta ? 'Aceita Permuta' : item.incluso?.includes('construcao') ? 'Em Construção' : 'A Venda'}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                </div>

                                                <div className="card-infos">

                                                    <span className="tipoImovel hideMobile">{item.tipoImovel} - </span>

                                                    <h2 className='hideDesktop'>
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.tipoImovel?.replace('Condomínio', '')} ${item.bairro?.replace('Jardim', '')} com ${item.metragemTerreno}m² - ${item.cidade}`

                                                                :

                                                                `${item.tipoImovel} ${item.bairro?.replace('Jardim', '')} com ${item.qtdQuartos} quartos, ${item.metragemConstrucao}m² - ${item.cidade}`

                                                        }
                                                    </h2>

                                                    <span className="localizacao hideMobile">{item.tipoImovel === 'Casa Condominio' ? item.condominio.nomeCondominio : item.bairro}</span>

                                                    <p className="metragem-quartos">
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.metragemTerreno}m²`

                                                                :

                                                                `${item.metragemConstrucao}m² - ${item.qtdQuartos} quartos`

                                                        }
                                                    </p>

                                                    {/* <p className="valor">{item.tipoNegocio === 'aluguel' ? "Aluguel " + item.precoAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "Condo. + IPTU " + (item.precoCondominio + item.precoIPTU).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p> */}
                                                    <p className="valorTotal">Total {item.tipoNegocio === 'aluguel' ? item.precoTotalAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : item.precoTotalCompra.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                                </div>

                                            </div>
                                        </Link>
                                    )
                                }

                                <div id="arrow-right" onClick={() => scrollSlide('right', 'slide1')}></div>

                            </div>

                        </>
                    }

                    {GlobalState.loading ?

                        <>

                            <br />

                            <div className="feed-category" style={{ overflowX: 'hidden' }}>
                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>

                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>

                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>

                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="feed-category" style={{ overflowX: 'hidden' }}>
                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>

                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>

                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>

                                <div className="card">
                                    <Skeleton animation="wave" variant="rect" className="card-img" />
                                    <div className="card-infos">

                                        <Skeleton animation="wave" variant="rect" className="tipoImovel" />

                                        <Skeleton animation="wave" variant="rect" className="tituloImovel" />

                                        <Skeleton animation="wave" variant="rect" className="localizacao" />

                                        <Skeleton animation="wave" variant="rect" className="metragem-quartos" />

                                        <Skeleton animation="wave" variant="rect" className="valor" />
                                        <Skeleton animation="wave" variant="rect" className="valorTotal" />

                                    </div>
                                </div>
                            </div>

                        </>

                        :
                        <>

                            {/* <p>Imóveis que você visualizou</p> */}
                            <br />

                            <div className="feed-category feed-vertical-mobile" id="slide2">

                                <div id="arrow-left" onClick={() => scrollSlide('left', 'slide2')}></div>

                                {
                                    items2.map((item, index) =>

                                        <Link target={Config.OPEN_IMOVEL_NEW_TAB ? '_blank' : '_self'} to={`/imovel/${item.cidade}/${item.tipoImovel}/${item.bairro}/${item.id}`.replaceAll(' ','-')} key={item.id} id={'items2-img' + index} className="card-link">
                                            <div className="card" key={item.id}>

                                                <div className="card-img">
                                                    <img
                                                        effect="opacity"
                                                        src={Config.AWS_S3_BUCKET_DOMAIN + item?.img}
                                                        alt={item.title}
                                                        onError={() => {
                                                            if (!document.getElementById('img' + index)) return
                                                            document.getElementById('img' + index).style.display = 'none'
                                                        }
                                                        }
                                                        loading="lazy"
                                                        onLoad={() => document.getElementById('items2-img' + index).classList.add('loaded')}
                                                    />
                                                    <Chip
                                                        className="chip"
                                                        label={item.incluso?.includes('lancamento') ? 'Lançamento' : item.aceitaPermuta ? 'Aceita Permuta' : item.incluso?.includes('construcao') ? 'Em Construção' : 'A Venda'}
                                                        size="small"
                                                        variant="outlined" />
                                                </div>

                                                <div className="card-infos">

                                                    <span className="tipoImovel hideMobile">{item.tipoImovel} - </span>

                                                    <h2 className='hideDesktop'>
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.tipoImovel?.replace('Condomínio', '')} ${item.bairro?.replace('Jardim', '')} com ${item.metragemTerreno}m² - ${item.cidade}`

                                                                :

                                                                `${item.tipoImovel} ${item.bairro?.replace('Jardim', '')} com ${item.qtdQuartos} quartos, ${item.metragemConstrucao}m² - ${item.cidade}`

                                                        }
                                                    </h2>

                                                    <span className="localizacao hideMobile">{item.tipoImovel === 'Casa Condominio' ? item.condominio.nomeCondominio : item.bairro}</span>

                                                    <p className="metragem-quartos">
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.metragemTerreno}m²`

                                                                :

                                                                `${item.metragemConstrucao}m² - ${item.qtdQuartos} quartos`

                                                        }
                                                    </p>

                                                    {/* <p className="valor">{item.tipoNegocio === 'aluguel' ? "Aluguel " + item.precoAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "Condo. + IPTU " + (item.precoCondominio + item.precoIPTU).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p> */}
                                                    <p className="valorTotal">Total {item.tipoNegocio === 'aluguel' ? item.precoTotalAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : item.precoTotalCompra.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                                </div>

                                            </div>
                                        </Link>
                                    )
                                }

                                <div id="arrow-right" onClick={() => scrollSlide('right', 'slide2')}></div>

                            </div>



                            {/* <p>Últimos imóveis anunciados em Sorocaba</p> */}
                            <br />

                            {/* adicionado classe disabled para não aparecer - no dia 12/04/2022 tinhamos poucos imóveis e esse slide ficava vazio */}
                            <div className="feed-category disabled" id="slide3">

                                <div id="arrow-left" onClick={() => scrollSlide('left', 'slide3')}></div>

                                {
                                    items3.map((item, index) =>

                                        <Link target={Config.OPEN_IMOVEL_NEW_TAB ? '_blank' : '_self'} to={`/imovel/${item.cidade}/${item.tipoImovel}/${item.bairro}/${item.id}`.replaceAll(' ','-')} key={item.id} id={'items3-img' + index} className="card-link">
                                            <div className="card" key={item.id}>

                                                <div className="card-img">
                                                    <img
                                                        effect="opacity"
                                                        src={Config.AWS_S3_BUCKET_DOMAIN + item?.img}
                                                        alt={item.title}
                                                        onError={() => {
                                                            if (!document.getElementById('img' + index)) return
                                                            document.getElementById('img' + index).style.display = 'none'
                                                        }
                                                        }
                                                        loading="lazy"
                                                        onLoad={() => document.getElementById('items3-img' + index).classList.add('loaded')}
                                                    />
                                                    <Chip
                                                        className="chip"
                                                        label={item.incluso?.includes('lancamento') ? 'Lançamento' : item.aceitaPermuta ? 'Aceita Permuta' : item.incluso?.includes('construcao') ? 'Em Construção' : 'A Venda'}
                                                        size="small"
                                                        variant="outlined" />
                                                </div>

                                                <div className="card-infos">

                                                    <span className="tipoImovel hideMobile">{item.tipoImovel} - </span>

                                                    <h2 className='hideDesktop'>
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.tipoImovel?.replace('Condomínio', '')} ${item.bairro?.replace('Jardim', '')} com ${item.metragemTerreno}m² - ${item.cidade}`

                                                                :

                                                                `${item.tipoImovel} ${item.bairro?.replace('Jardim', '')} com ${item.qtdQuartos} quartos, ${item.metragemConstrucao}m² - ${item.cidade}`

                                                        }
                                                    </h2>

                                                    <span className="localizacao hideMobile">{item.tipoImovel === 'Casa Condominio' ? item.condominio.nomeCondominio : item.bairro}</span>

                                                    <p className="metragem-quartos">
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.metragemTerreno}m²`

                                                                :

                                                                `${item.metragemConstrucao}m² - ${item.qtdQuartos} quartos`

                                                        }
                                                    </p>

                                                    {/* <p className="valor">{item.tipoNegocio === 'aluguel' ? "Aluguel " + item.precoAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "Condo. + IPTU " + (item.precoCondominio + item.precoIPTU).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p> */}
                                                    <p className="valorTotal">Total {item.tipoNegocio === 'aluguel' ? item.precoTotalAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : item.precoTotalCompra.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                                </div>

                                            </div>
                                        </Link>
                                    )
                                }

                                <div id="arrow-right" onClick={() => scrollSlide('right', 'slide3')}></div>

                            </div>



                            {/* <p>Imóveis que baixaram o preço</p>
                            <br /> */}

                            {/* adicionado classe disabled para não aparecer - no dia 12/04/2022 tinhamos poucos imóveis e esse slide ficava vazio */}
                            <div className='feed-category disabled' id="slide4">

                                <div id="arrow-left" onClick={() => scrollSlide('left', 'slide4')}></div>

                                {
                                    items4.map((item, index) =>

                                        <Link target={Config.OPEN_IMOVEL_NEW_TAB ? '_blank' : '_self'} to={`/imovel/${item.cidade}/${item.tipoImovel}/${item.bairro}/${item.id}`.replaceAll(' ','-')} key={item.id} id={'items4-img' + index} className="card-link">
                                            <div className="card" key={item.id}>

                                                <div className="card-img">
                                                    <img
                                                        effect="opacity"
                                                        src={Config.AWS_S3_BUCKET_DOMAIN + item?.img}
                                                        alt={item.title}
                                                        onError={() => {
                                                            if (!document.getElementById('img' + index)) return
                                                            document.getElementById('img' + index).style.display = 'none'
                                                        }
                                                        }
                                                        loading="lazy"
                                                        onLoad={() => document.getElementById('items4-img' + index).classList.add('loaded')}
                                                    />
                                                    <Chip
                                                        className="chip"
                                                        label={item.incluso?.includes('lancamento') ? 'Lançamento' : item.aceitaPermuta ? 'Aceita Permuta' : item.incluso?.includes('construcao') ? 'Em Construção' : 'A Venda'}
                                                        size="small"
                                                        variant="outlined" />
                                                </div>

                                                <div className="card-infos">

                                                    <span className="tipoImovel hideMobile">{item.tipoImovel} - </span>

                                                    <h2 className='hideDesktop'>
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.tipoImovel?.replace('Condomínio', '')} ${item.bairro?.replace('Jardim', '')} com ${item.metragemTerreno}m² - ${item.cidade}`

                                                                :

                                                                `${item.tipoImovel} ${item.bairro?.replace('Jardim', '')} com ${item.qtdQuartos} quartos, ${item.metragemConstrucao}m² - ${item.cidade}`

                                                        }
                                                    </h2>

                                                    <span className="localizacao hideMobile">{item.tipoImovel === 'Casa Condominio' ? item.condominio.nomeCondominio : item.bairro}</span>

                                                    <p className="metragem-quartos">
                                                        {
                                                            item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                                `${item.metragemTerreno}m²`

                                                                :

                                                                `${item.metragemConstrucao}m² - ${item.qtdQuartos} quartos`

                                                        }
                                                    </p>

                                                    {/* <p className="valor">{item.tipoNegocio === 'aluguel' ? "Aluguel " + item.precoAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "Condo. + IPTU " + (item.precoCondominio + item.precoIPTU).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p> */}
                                                    <p className="valorTotal">Total {item.tipoNegocio === 'aluguel' ? item.precoTotalAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : item.precoTotalCompra.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                                </div>

                                            </div>
                                        </Link>
                                    )
                                }

                                <div id="arrow-right" onClick={() => scrollSlide('right', 'slide4')}></div>

                            </div>
                        </>
                    }
                </div>

                <br />
                <br />
                <br />
                <br />

            </Container>
            <Footer />
        </>
    )
}

export default Feed
