import styled from 'styled-components'

import Config from '../../Config'

export const WhatsAppFloating = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;

    @media screen and (min-width: 1200px) {
        display: none;
    }
`

export const Container = styled.div`

    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    height: 88px;
    width: 100vw;
    background-color: #fff;
    z-index: 1000;
    position: fixed;
    border-bottom: solid 2px ${Config.primary_color};

    @media screen and (max-width: 1300px) {
        height: 84px;
    }

    #header-items {
        display: flex;
        padding: 5px 48px 14px 36px;
        justify-content: space-between;

        @media screen and (max-width: 1300px) {
            padding: 5px 48px 17px 15px;
        }

        #menu-e-logo {
            display: flex;

            #logo-alpha-select {
                img {
                    width: 240px;
                    margin-top: 20px;
                }
            }

            #menuIcon {
                margin-top: 9px;
                margin-right: 24px;
                font-size: 3.5rem;
                
                @media screen and (max-width: 1300px) {
                    margin-top: 15px;
                    font-size: 2.5rem;
                }
            }
        }

        /* #goto-search {
            @media screen and (max-width: 1300px) {
                display: none;
            }

            margin-top: 8px;
            width: 544px;
            height: 50px;
            border: 3px solid ${Config.primary_color};
            box-sizing: border-box;
            border-radius: 9px;
            display: flex;
            padding: 10px 25px;
            margin-top: 13px;

            #goto-search-icon {
                margin-right: 10px;
                color: ${Config.primary_color}
            }

            p {
                font-family: Roboto;
                font-size: 16px;
                line-height: 0;
                margin-top: 12px;
                color: ${Config.primary_color}
            }
        } */
        
        @media screen and (max-width: 1200px) {
            #telefone {
                display: none !important;
            }
        }

        #telefone {
            display: flex;
            align-items: center;
            margin-top: 17px;

            img {
                width: 40px;
                height: 40px;
                margin-right: 5px;
                margin-top: 4px;
            }

            span {
                font-family: 'Roboto';
                font-size: 18px;
            }
        }
    }

`
