/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import Footer from '../../Layout/Footer'

import { useLocation, useParams, Link, useHistory } from 'react-router-dom'

import { Container } from './style'

import { apiWithCache } from '../../api'
import { checkVisible } from '../../Functions'
import Config from '../../Config'
import { globalStateContext } from '../../GlobalState'

import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'

import Skeleton from '@material-ui/lab/Skeleton'

import Chip from '@material-ui/core/Chip'

// pop-up dialog
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import { Carousel } from 'react-responsive-carousel'

import CircularProgress from '@material-ui/core/CircularProgress'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { Helmet } from 'react-helmet'

import Map from '../../Components/map'
import { IconButton, InputAdornment } from '@material-ui/core'

function Imovel() {

    const [GlobalState, setGlobalState] = globalStateContext.use()
    const [loadingButton, setLoadingButton] = useState(false)

    const { idImovel, hash } = useParams()

    const location = useLocation()
    const history = useHistory()

    const [imovel, setImovel] = useState({
        title: "",
        id: "",
        descImovel: "",
        imgLarge: {
            "img1": ''
        },
        condominio: {
            nomeCondominio: '',
            precoCondominio: 0,
            enderecoCondominio: '',
            bairroCondominio: '',
            cidadeCondomonio: '',
            lazerCondomonio: '',
            sobreCondominio: '',
        },
        incluso: []
    })

    const [pageTitle, setPageTitle] = useState(null)

    const [similares, setSimilares] = useState([])

    // const [lastImg, setLastImg] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)

    const [owner, setOwner] = useState({})

    async function getOwnerData() {
        setDialogOpen(true)

        setOwner({
            ...owner,
            nome: 'carregando...',
            tel: 'carregando...',
            email: 'carregando'
        })

        let includeOwner = {
            "owners": true
        }

        var responseOwner = await apiWithCache.get('/publicproducts/' + idImovel.toString() + '?include=' + JSON.stringify(includeOwner))
        var dataOwner = responseOwner.data.data[0].owners

        setOwner(dataOwner)
    }

    // gera um base64 na url que da acesso a um imóvel desabilitado 
    function generateByPassDisabledImovel() {

        let hashGenerated = btoa(`alpha-${idImovel}-${new Date().toLocaleDateString()}`)

        alert('Link temporário gerado com isso. Validade 24 horas')

        alert(`${window.location}/${hashGenerated}`)
    }

    useEffect(() => {

        async function getItems() {

            setGlobalState({ ...GlobalState, loading: true })

            let includeImovel = {
                "imgLarge": true
            }

            var responseImovel = []

            try {
                responseImovel = await apiWithCache.get('/publicproducts/' + idImovel.toString() + '?include=' + JSON.stringify(includeImovel))
            }
            catch (err) {
                console.error(err)
                window.location.reload()
            }

            var dataImovel = responseImovel.data.data[0]

            if ((dataImovel?.disabled || !dataImovel?.published) && !hash) {

                alert('Este imóvel foi desabilitado pela administração')

                if (!Boolean(localStorage.getItem('token')) && !Boolean(localStorage.getItem('enableSearchAdmin#0000'))) {
                    return history.push('/')
                }
            }

            if (dataImovel?.disabled && hash) {
                let hashDecoded = atob(hash)

                // console.log(hashDecoded)
                if (hashDecoded !== `alpha-${idImovel}-${new Date().toLocaleDateString()}`) {
                    return alert('Este imóvel foi desabilitado pela administração - Código Hash inválido')
                }
            }

            delete dataImovel.imgLarge.id

            if (dataImovel.tipoImovel === 'Terreno' || dataImovel.tipoImovel === 'Lote Condomínio') {

                setPageTitle(`${dataImovel.tipoImovel} ${dataImovel.bairro?.replace('Jardim', '')} com ${dataImovel.metragemTerreno}m² - ${dataImovel.cidade}`)

            } else {

                setPageTitle(`${dataImovel.tipoImovel} ${dataImovel.bairro?.replace('Jardim', '')} com ${dataImovel.qtdQuartos} quartos, ${dataImovel.metragemConstrucao}m² - ${dataImovel.cidade}`)

            }

            setImovel(dataImovel)

            setGlobalState({ ...GlobalState, loading: false })

            // as imagens do carousel mobile tinham um bug que não funcionava o touch, então, inicia a posição no 99 e quando carrega volta pro 0
            setTimeout(() => {
                setSelectedFImgMobile(0)

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                })

            }, 100)

            //guarda qual é a última imagem para verificar e remover a seta da direita na função verifySliderScroll()
            // let lastIndex = Object.values(dataImovel.imgLarge).filter(item => item !== null && item !== '').length - 1
            // let lastPosition = `img${lastIndex}`
            // setLastImg(lastPosition)
            var { categoriaPreco, tipoImovel } = dataImovel
            var responseSimilar = await apiWithCache.get(`/similar?categoriaPreco=${categoriaPreco}&tipoImovel=${tipoImovel}`)
            var dataSimilar = responseSimilar.data.data

            dataSimilar = dataSimilar.filter(item => item.id !== idImovel)

            setSimilares(dataSimilar)

            if (dataSimilar.length < 6) {

                var arrowLeftSimilar = document.querySelector('#arrow-left-similar')
                var arrowRightsimilar = document.querySelector('#arrow-right-similar')
                var divSimilar = document.querySelector('#similar')

                arrowLeftSimilar.style.visibility = "hidden"
                arrowRightsimilar.style.visibility = "hidden"
                divSimilar.style.margin = '15px -31px'
            }
        }

        getItems()

        // reseta o scroll para inicia a pagina no topo aparecendo a imagem completa do imovel
        // setTimeout(function() { window.scrollTo(0, 0) }, 1)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })

        // outro comando que sobe a pagina pro topo --- no mobile não funcionava
        document.body.scrollTop = 0

        // reseta o scroll das imagens do imovel no começo da pagina
        setTimeout(function () { document.getElementById('imovel-slide-items').scrollTo(0, 0) }, 1)

        // outro comando para mandar o slider para o inicio
        setTimeout(function () { document.getElementById('imovel-slide-items').scrollLeft = 0 }, 1)

        // reseta o scroll dos imoveis similares
        setTimeout(function () { document.getElementById('similar').scrollTo(0, 0) }, 1)

        // outro comando para mandar o similares para o inicio
        setTimeout(function () { document.getElementById('similar').scrollLeft = 0 }, 1)

        // verifica a posição do scroll --- se for mobile ele já tira as setas
        verifySliderScroll()

        // as imagens do carousel mobile tinham um bug que não funcionava o touch, então, inicia a posição no 99 e quando carrega volta pro 0
        setSelectedFImgMobile(1)

    }, [location])


    function verifySliderScroll() {
        // var slider = document.getElementById('imovel-slide-items')
        // var arrowLeft = document.querySelector('span#arrow-left')
        // var arrowRight = document.querySelector('span#arrow-right')
        // if (window.innerWidth < 600) {
        //     arrowLeft.style.visibility = "hidden"
        //     arrowRight.style.visibility = "hidden"
        //     return
        // }
        // if (slider.scrollLeft === 0) {
        //     arrowLeft.style.visibility = "hidden"
        // } else {
        //     arrowLeft.style.visibility = "unset"
        // }
        // // não executa se ainda não executou o get e guardou a ultima imagem
        // if (lastImg === null) return
        // // let lastImgObject = document.getElementById(lastImg)
        // // console.log(lastImgObject)
        // // console.log(checkVisible(lastImgObject))
        // return 
        // // if (checkVisible(lastImgObject)) {
        // //     arrowRight.style.visibility = "hidden"
        // // }
    }

    function scrollSlide(direction, element) {

        element = document.getElementById(element)

        document.getElementById('arrow-left').classList.remove('disabled')

        if (direction === 'left') {
            element.scrollBy(-window.innerWidth, 0)
        }

        if (direction === 'right') {
            element.scrollBy(window.innerWidth, 0)
        }
    }

    // adicionar o observer no componente que fizer scroll onWheel={e => handleWheel(e)}
    /*
    function handleWheel (e) {

        if (window.innerWidth < 600) return

        if (e.deltaY > 0) {
            scrollSlide('right', 'similar')
        } else {
            scrollSlide('left', 'similar')
        }

        // deixa o scroll no final da pagina pra evitar que o scroll de correr os imoveis similares volte também o scroll do window
        window.scrollTo(0, 9000)
    }
    */
    const [showFullscreen, setShowFullscreen] = useState(false)
    // inicia com 99 para evitar bugs - quando da o loading ele volta pro 0
    const [selectedImgFullscreen, setSelectedFullscreen] = useState(99)
    const [selectedImgMobile, setSelectedFImgMobile] = useState(99)

    document.onkeydown = function (e) {

        e = e || window.event

        var isEscape = false

        if ("key" in e) {

            isEscape = (e.key === "Escape" || e.key === "Esc")

        }

        if (isEscape) {
            setShowFullscreen(false)
            setDialogOpen(false)
        }

        if (e.key === 'ArrowRight') {
            if (showFullscreen) {
                document.querySelector('.control-arrow.control-next').click()
            } else {
                document.querySelector('#arrow-right').click()
            }
        }

        if (e.key === 'ArrowLeft') {
            if (showFullscreen) {
                document.querySelector('.control-arrow.control-prev').click()
            } else {
                document.querySelector('#arrow-left').click()
            }
        }

    }

    const [contato, setContato] = useState({
        nome: localStorage.getItem('contato-nome') ?? '',
        email: localStorage.getItem('contato-email') ?? '',
        telefone: localStorage.getItem('contato-telefone') ?? '',
        mensagem: 'Oi. Vi esse imóvel no site e gostaria de receber mais informações',
        codImovel: idImovel,
        prefersWhatsApp: JSON.parse(localStorage.getItem('contato-prefersWhatsApp')) ?? false
    })

    const [sucessSent, setSucessSent] = useState(false)


    const handleSubmit = async (event) => {
        event.preventDefault()

        setLoadingButton(true)

        try {

            // convert to formData
            const formData = new FormData()
            Object.keys(contato).forEach(key => formData.append(key, contato[key]))

            await fetch('https://formspree.io/f/mayvlvak', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })

            setSucessSent(true)
        }
        catch (err) {
            alert('Erro ao enviar os dados')
            console.error(err)
        }

        setLoadingButton(false)
    }

    window.addEventListener("scroll", () => {

        var boxFixed = document.getElementById('valoresImovel')
        var similares = document.getElementById('similar')

        if (!boxFixed || window.innerWidth < 1300)
            return

        if (window.scrollY > 250 && !checkVisible(similares)) {
            boxFixed.classList.add('fixed-container')
        } else {
            boxFixed.classList.remove('fixed-container')
        }
    })

    // const isMobile = window.innerWidth < 1300
    function openOutros(text) {

        text = text.split('-')

        text = text[0].replace('jardim', '').replace('residencial', '')

        history.push(`/encontrar-imovel-sorocaba/${text}`)
    }

    return (
        <>

            {
                pageTitle !== null ?
                    <Helmet>‍
                        <title>{pageTitle}</title>‍
                        <meta property="og:title" content={pageTitle} />
                        <meta property="og:description" content={pageTitle} />
                    </Helmet>
                    :
                    <></>
            }

            <Container>

                <div id="slide-fullscreen" className={showFullscreen ? '' : 'disabled'}>

                    <div id="close-fullscreen" onClick={() => setShowFullscreen(false)}>
                        <Icon>cancel</Icon>
                    </div>

                    <Carousel showThumbs={true} infiniteLoop showIndicators={false} selectedItem={selectedImgFullscreen} emulateTouch>
                        {
                            // o ID na div e o onError servem para não mostrar 
                            // a imagem se ela der erro. Pelo menos uma imagem vai dar erro
                            // porque no Object.values pega o ID também
                            Object.values(imovel.imgLarge).filter(item => item !== null && item !== '' && !item.includes('.xml')).map((item, index) => <img
                                src={Config.AWS_S3_BUCKET_DOMAIN + item}
                                alt={imovel.title}
                                key={index}
                                className={'img-full' + index}
                                onError={() => document.querySelector(`[aria-label="slide item ${index + 1}"]`).style.display = 'none'} />
                            )}
                    </Carousel>

                </div>

                <div id="imovel-slide-wrapper" className='hideMobile'>

                    {/* INICIA A SETA DESABILITA PARA EVITAR QUE APARECE NO MOBILE E QUE A POSIÇÃO INICIAL TAMBÉM NÃO APAREÇA A SETA ESQUERDA*/}
                    <Icon id="arrow-left" className="disabled" onClick={() => scrollSlide('left', 'imovel-slide-items')}>chevron_left</Icon>
                    <Icon id="arrow-right" onClick={() => scrollSlide('right', 'imovel-slide-items')}>chevron_right</Icon>

                    <div id="imovel-slide-items" onScroll={() => verifySliderScroll()}>

                        {
                            // o ID na div e o onError servem para não mostrar 
                            // a imagem se ela der erro. Pelo menos uma imagem vai dar erro
                            // porque no Object.values pega o ID também
                            Object.values(imovel.imgLarge).filter(item => item !== null && item !== '').map((item, index) => <div
                                key={Math.random()}
                                id={'img' + index}
                                // class card-link é onde está o efeito de opacidade do lazy loading
                                className={GlobalState.loading ? 'disabled ' : 'slide-item card-link'}>

                                <img
                                    src={Config.AWS_S3_BUCKET_DOMAIN + item}
                                    alt={imovel.title}
                                    onError={() => document.getElementById('img' + index).style.display = 'none'}
                                    onClick={() => {
                                        setSelectedFullscreen(index)
                                        setShowFullscreen(true)
                                    }} />

                            </div>
                            )}

                        <div className={GlobalState.loading ? 'slide-item' : 'disabled'} style={{ marginRight: 1 }}>
                            <Skeleton animation="wave" variant="rect" width="100%" height="307px" id="check-loading" />
                        </div>
                        <div className={GlobalState.loading ? 'slide-item' : 'disabled'} style={{ marginRight: 1 }}>
                            <Skeleton animation="wave" variant="rect" width="100%" height="307px" />
                        </div>
                        <div className={GlobalState.loading ? 'slide-item' : 'disabled'}>
                            <Skeleton animation="wave" variant="rect" width="100%" height="307px" />
                        </div>

                    </div>

                </div>

                <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    className='hideDesktop'
                    selectedItem={selectedImgMobile}
                    emulateTouch
                    onClickItem={() => {
                        setShowFullscreen(true)
                        setSelectedFullscreen(0)
                    }}
                >
                    {
                        // o ID na div e o onError servem para não mostrar 
                        // a imagem se ela der erro. Pelo menos uma imagem vai dar erro
                        // porque no Object.values pega o ID também
                        Object.values(imovel.imgLarge).filter(item => item !== null && item !== '' && !item.includes('.xml')).map((item, index) => <img
                            src={Config.AWS_S3_BUCKET_DOMAIN + item}
                            alt={imovel.title}
                            key={Math.random()}
                            className={`img-mobile${index} loading-gif-background`}
                            onError={() => document.querySelectorAll('.img-mobile' + index)[0].parentNode.style.display = 'none'} />
                        )}
                </Carousel>

                <div id="header-imovel">

                    <a onClick={() => history.goBack()}>
                        <Icon id="icon-back" style={{ color: '#fff' }}>arrow_back</Icon>
                    </a>

                    <div>
                        <a
                            href="#"
                            id="fullscreen"
                            style={{ marginRight: 30 }}
                            onClick={() => {
                                setShowFullscreen(true)
                                setSelectedFullscreen(0)
                            }}
                        >
                            <Icon
                                style={{ color: '#fff' }}
                                className="sumOne">
                                open_in_full
                            </Icon>
                            <span>Tela cheia</span>
                        </a>
                        <a
                            id="ver-proprietario"
                            style={{ marginRight: 30 }}
                            className={(localStorage.getItem('captador') === imovel.captadorId && JSON.parse(localStorage.getItem('enableSearchAdmin#0000'))) || Boolean(localStorage.getItem('token')) ? '' : 'disabled'}
                            onClick={() => getOwnerData()}
                        >
                            <Icon
                                style={{ color: '#fff' }}
                                className="sumOne">
                                other_houses
                            </Icon>
                            <span>Dados do Imóvel</span>
                        </a>
                        <a id="bypass" onClick={generateByPassDisabledImovel} style={{ marginRight: 30 }} className={Boolean(localStorage.getItem('token')) && !hash ? '' : 'disabled'}>
                            <Icon style={{ color: '#fff' }}><span class="material-icons">lock_open</span></Icon>
                            <span>Gerar Link Temporário</span>
                        </a>
                        <a id="editar" href={`/desktop/dash/cadastro-imovel/${idImovel}`} style={{ marginRight: 30 }} className={Boolean(localStorage.getItem('token')) ? '' : 'disabled'}>
                            <Icon style={{ color: '#fff' }}>edit</Icon>
                            <span>Editar Imóvel</span>
                        </a>
                        <a id="proximo" href={`/i/${parseInt(idImovel) + 1}`} style={{ marginRight: 30 }} className={Boolean(localStorage.getItem('token')) ? '' : 'disabled'}>
                            <Icon style={{ color: '#fff' }}>double_arrow</Icon>
                            <span>Próximo Imóvel</span>
                        </a>
                        <Link id="buscar" to="/encontrar-imovel-sorocaba">
                            <Icon style={{ color: '#fff' }}>search</Icon>
                            <span>Buscar outros</span>
                        </Link>
                    </div>

                </div>

                <div id="margin-laterais">

                    {GlobalState.loading ?

                        <>
                            <Skeleton animation="wave" variant="rect" width="100%" height="30px" />
                            <br />

                            <Skeleton animation="wave" variant="rect" width="100%" height="20px" />

                        </>

                        :

                        <>
                            <div className="hideDesktop">
                                <Button
                                    onClick={() => document.getElementById('valoresImovel').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: 30 }}
                                    startIcon={<Icon>phone_in_talk</Icon>}
                                    size="large"
                                    fullWidth
                                >
                                    Solicitar contato
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginTop: -20, marginBottom: 20, color: '#fff' }}
                                    startIcon={<Icon>other_houses</Icon>}
                                    size="large"
                                    className={Boolean(localStorage.getItem('token')) || JSON.parse(localStorage.getItem('enableSearchAdmin#0000')) ? '' : 'disabled'}
                                    onClick={() => getOwnerData()}
                                    fullWidth
                                >
                                    Dados do imóvel
                                </Button>
                            </div>

                            <span id="outros-no-condominio" onClick={() => openOutros(imovel.bairro)}>
                                Outros em {imovel.bairro}
                            </span>
                        </>}

                    {GlobalState.loading ?

                        <>

                            <Skeleton animation="wave" variant="rect" width="100%" height="15px" />
                            <br />
                            <Skeleton animation="wave" variant="text" width="100%" height="25px" />
                            <Skeleton animation="wave" variant="text" width="100%" height="25px" />
                            <Skeleton animation="wave" variant="text" width="100%" height="25px" />
                            <Skeleton animation="wave" variant="text" width="100%" height="25px" />
                            <Skeleton animation="wave" variant="text" width="100%" height="25px" />
                            <Skeleton animation="wave" variant="text" width="100%" height="25px" />
                            <Skeleton animation="wave" variant="text" width="100%" height="25px" />

                        </>

                        :

                        <>

                            <hr />

                            <h1>
                                {imovel.tipoImovel === 'Terreno' || imovel.tipoImovel === 'Lote Condomínio' || imovel.tipoImovel === 'Sala Comercial' ?

                                    `${imovel.tipoImovel?.replace('Condomínio', '')} ${imovel.bairro?.replace('Jardim', '')} com ${imovel.tipoImovel === 'Sala Comercial' || imovel.tipoImovel === 'Galpão' ? imovel.metragemConstrucao : imovel.metragemTerreno}m² - ${imovel.cidade}`

                                    :

                                    `${imovel.tipoImovel} ${imovel.bairro?.replace('Jardim', '')} com ${imovel.qtdQuartos} quartos, ${imovel.metragemConstrucao}m² - ${imovel.cidade}`}
                            </h1>

                            <div id="localizacao-metragem-quartos" className='hideMobile'>
                                <span id="localizacao-imovel">
                                    {`${imovel.bairro}, ${imovel.cidade} / ${imovel.estado}`}
                                </span>

                                <span id="metragem-construcao-quarto" className={imovel.tipoImovel !== 'Terreno' || imovel.tipoImovel !== 'Lote Condomínio' ? 'disabled' : ''}>
                                    {imovel.metragemConstrucao}m² - {imovel.qtdQuartos} quartos
                                </span>
                            </div>

                            <div id="icones-imovel">

                                <div className={imovel.tipoImovel !== 'Terreno' && imovel.tipoImovel !== 'Lote Condomínio' && imovel.tipoImovel !== 'Sala Comercial' && imovel.tipoImovel !== 'Galpão' ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>bed</Icon>
                                    <div>
                                        <p>{imovel.qtdQuartos} {imovel.qtdQuartos >= 1 ? 'quartos' : 'quarto'}</p>
                                        <p>({imovel.qtdSuites} {imovel.qtdSuites >= 1 ? 'suítes' : 'suíte'})</p>
                                    </div>
                                </div>

                                <div className={imovel.tipoImovel === 'Terreno' || imovel.tipoImovel === 'Lote Condomínio' ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>straighten</Icon>
                                    <div>
                                        <p>{imovel.metragemTerreno} m²</p>
                                        <p>({new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((imovel.precoTotalCompra / imovel.metragemTerreno).toFixed(0))}/m²)</p>
                                    </div>
                                </div>

                                <div className={imovel.tipoImovel !== 'Terreno' && imovel.tipoImovel !== 'Lote Condomínio' ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>straighten</Icon>
                                    <div>
                                        <p>{imovel.metragemConstrucao} m²</p>
                                        <p>({new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((imovel.precoTotalCompra / imovel.metragemConstrucao).toFixed(0))}/m²)</p>
                                    </div>
                                </div>

                                <div className={imovel.tipoImovel !== 'Terreno' && imovel.tipoImovel !== 'Lote Condomínio' ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>directions_car_filled</Icon>
                                    <div>
                                        <p className='margin-unico-item'>{imovel.qtdVagas} vagas</p>
                                    </div>
                                </div>

                                <div className={imovel.tipoImovel !== 'Terreno' && imovel.tipoImovel !== 'Lote Condomínio' ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>shower</Icon>
                                    <div>
                                        <p className='margin-unico-item'>{imovel.qtdBanheiros} {imovel.qtdBanheiros >= 1 ? 'banheiros' : 'banheiro'}</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('aceitaFinanciamento') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>account_balance</Icon>
                                    <div>
                                        <p>Aceita Financiamento</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('aceitaPermuta') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>compare_arrows</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Aceita Permuta</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('aceitaPet') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>pets</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Aceita PET</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('mobiliado') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>chair</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Mobiliado</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('arcondicionado') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>ac_unit</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Ar-Condicionado</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('academia') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>fitness_center</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Academia</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('piscina') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>pool</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Piscina</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('playground') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>escalator_warning</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Playground</p>
                                    </div>
                                </div>

                                <div className={imovel.incluso?.includes('salaofestas') ? 'icone-imovel-item' : 'disabled'}>
                                    <Icon className='icon'>celebration</Icon>
                                    <div>
                                        <p className='margin-unico-item'>Salão de Festa</p>
                                    </div>
                                </div>

                            </div>

                            <div id="valoresImovel">

                                <div id="orientar">
                                    <Icon>thumb_up</Icon>
                                    <h2>Vamos te orientar nas melhores condições e auxiliar sua compra</h2>
                                </div>

                                <div className={imovel.tipoNegocio === 'compra' ? 'disabled' : 'valorUnitario'}>
                                    <span>Aluguel
                                        {/* <span className="tempoPagamento">(Mensal)</span> */}
                                    </span>
                                    <span>R${imovel.precoAluguel}</span>
                                </div>

                                <div className={imovel.tipoNegocio === 'aluguel' ? 'disabled' : 'valorUnitario'}>
                                    <span>Valor de Venda</span>
                                    <span>{imovel.precoCompra?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                </div>

                                {/* <div className={imovel.tipoImovel !== 'Casa Condominio' ? 'disabled' : 'valorUnitario'}>
                        <span>Condominio
                            <span className="tempoPagamento">(Mensal)</span>
                        </span>
                        <span>{imovel?.condominio?.precoCondominio.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                    </div> */}
                                <div className={imovel?.precoCondominio === 0 ? 'disabled' : 'valorUnitario'}>
                                    <span>Condominio
                                        <span className="tempoPagamento">(Mensal)</span>
                                    </span>
                                    <span>{imovel?.precoCondominio?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                </div>

                                <div className="valorUnitario">
                                    <span>IPTU
                                        {/* <span className="tempoPagamento">(Mensal)</span> */}
                                    </span>
                                    <span>{imovel.precoIPTU !== 0 ? `R$${imovel?.precoIPTU}` : 'Consulte'}</span>
                                </div>

                                <div className={imovel.tipoNegocio !== 'aluguel' ? 'disabled' : 'valorUnitario'}>
                                    <span>Seguro Incêndio
                                        <span className="tempoPagamento">(Mensal)</span>
                                        <Icon className="icon-info">help_outline</Icon>
                                    </span>
                                    <span>{imovel?.precoSeguroIncendio?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                </div>

                                <div className={imovel.tipoNegocio !== 'aluguel' ? 'disabled' : 'valorUnitario'}>
                                    <span>Taxa de serviço
                                        <span className="tempoPagamento">(Única)</span>
                                        <Icon className="icon-info">help_outline</Icon>
                                    </span>
                                    <span>{imovel?.precoTaxaServico?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                </div>

                                <hr />

                                <div className="valorUnitario valorTotal">
                                    <span>Total</span>
                                    <span>
                                        {imovel.tipoNegocio !== 'compra'
                                            ? imovel.precoTotalAluguel?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                            : imovel.precoTotalCompra?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </span>
                                </div>

                                <br />

                                <div
                                    className={!sucessSent ? 'disabled' : ''}
                                >

                                    <Icon style={{ fontSize: 32 }}>fact_check</Icon>
                                    <h2>Enviado com sucesso. Em breve um de nossos corretores entrará em contato</h2>

                                </div>

                                <form
                                    action="https://formspree.io/f/mayvlvak"
                                    method="POST"
                                    onSubmit={handleSubmit}
                                    className={sucessSent ? 'disabled' : ''}
                                >

                                    <TextField
                                        label="E-mail"
                                        name="E-mail"
                                        type="text"
                                        variant="outlined"
                                        color='primary'
                                        size='small'
                                        fullWidth
                                        value={contato.email}
                                        required
                                        onChange={e => {
                                            setContato({ ...contato, email: e.target.value })
                                            localStorage.setItem('contato-email', e.target.value)
                                        }} />

                                    <TextField
                                        label="Nome"
                                        name="Nome"
                                        type="text"
                                        variant="outlined"
                                        color='primary'
                                        size='small'
                                        style={{ marginTop: 10, width: '49%', marginRight: '1%' }}
                                        value={contato.nome}
                                        required
                                        onChange={e => {
                                            setContato({ ...contato, nome: e.target.value })
                                            localStorage.setItem('contato-nome', e.target.value)
                                        }} />

                                    <TextField
                                        label="Telefone"
                                        name="Telefone"
                                        type="text"
                                        variant="outlined"
                                        color='primary'
                                        size='small'
                                        style={{ marginTop: 10, width: '50%' }}
                                        value={contato.telefone}
                                        required
                                        onChange={e => {
                                            setContato({ ...contato, telefone: e.target.value })
                                            localStorage.setItem('contato-telefone', e.target.value)
                                        }} />

                                    <TextField
                                        label="Mensagem"
                                        name="Mensagem"
                                        type="text"
                                        variant="outlined"
                                        color='primary'
                                        size='small'
                                        multiline
                                        minRows={3}
                                        fullWidth
                                        style={{ marginTop: 15, marginBottom: 10 }}
                                        value={contato.mensagem}
                                        required
                                        onChange={e => {
                                            setContato({ ...contato, mensagem: e.target.value })
                                            localStorage.setItem('contato-mensagem', e.target.value)
                                        }} />

                                    <FormControlLabel
                                        control={<Checkbox
                                            name="Prefiro contato por WhatsApp"
                                            checked={contato.prefersWhatsApp}
                                            onChange={() => {
                                                // primeiro o localStorage para não salvar o errado
                                                localStorage.setItem('contato-prefersWhatsApp', !contato.prefersWhatsApp)
                                                setContato({ ...contato, prefersWhatsApp: !contato.prefersWhatsApp })
                                            }} />}
                                        label="Prefiro contato por WhatsApp" />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 10 }}
                                        startIcon={<Icon>phone_in_talk</Icon>}
                                        size="large"
                                        fullWidth
                                        type="submit"
                                    >
                                        {loadingButton ? <CircularProgress size={20} />
                                            : "enviar dados"}
                                    </Button>

                                </form>

                                <a
                                    href={'https://api.whatsapp.com/send?phone='
                                        + Config.whatsapp
                                        + '&text=Oi, tudo bem? Estou interessado nesse imóvel: '
                                        + window.location.pathname}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginTop: 10 }}
                                        startIcon={<Icon>whatsapp</Icon>}
                                        fullWidth
                                    >
                                        Falar no WhatsApp agora
                                    </Button>
                                </a>

                            </div>

                        </>}

                    <div id="descricaoImovel">

                        <Icon>subject</Icon>

                        <div>
                            <h4>Descrição do proprietário</h4>
                            <p>{imovel.descImovel}</p>
                        </div>

                    </div>


                    {/* <div id="condominioInfos" className={imovel.tipoImovel === 'Casa Condominio' ? '' : 'disabled'}>
            <Icon id="condominio-icon">apartment</Icon>

            <h4>{imovel?.condominio?.nomeCondominio}</h4>
            <p>{imovel?.condominio?.lazerCondomonio}</p>
            <p id="gostou-condominio">Gostou do condomínio?
                <span> Conheça melhor e veja outros imoveis disponíveis por lá</span>
            </p>
            <Button variant="outlined" color="primary">Conhecer Condominio</Button>
        </div> */}

                    <div id="proximidadesImovel" className={imovel.descProximidades === '' ? 'disabled' : ''}>

                        <Icon>location_on</Icon>

                        <div>
                            <h4>Proximidades</h4>
                            <p>{imovel.descProximidades}</p>
                        </div>

                    </div>

                    <div id="mapaRegiao">

                        <Icon>location_on</Icon>

                        <div>
                            <h4>Mapa da Região</h4>
                        </div>

                    </div>

                    {
                        !imovel.endereco ? <></> : <Map endereco={`${imovel.endereco}, ${imovel.numero} - ${imovel.cidade} / ${imovel.estado}`} />
                    }

                    <h4 className="hideDesktop">Gostou desse imóvel?<br />Ele pode ser seu. Vamos te orientar</h4>

                    <div className="hideDesktop">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Icon>phone_in_talk</Icon>}
                            size="large"
                            fullWidth
                            onClick={() => document.getElementById('valoresImovel').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}
                        >
                            Solicitar contato
                        </Button>
                    </div>

                    <br />
                    <br />

                    <div id="inclusosImovel" className={imovel.incluso.length === 0 ? 'disabled' : ''}>

                        <Icon>turned_in</Icon>

                        <div>

                            <h4>Características Gerais do Imóvel</h4>

                            <div id="todos-itens-inclusos">

                                <div className={imovel.incluso.includes('arcondicionado') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="arcondicionado">Ar-condicionado</p>
                                </div>
                                <div className={imovel.incluso.includes('areaservico') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="areaservico">Área Serviço</p>
                                </div>
                                <div className={imovel.incluso.includes('areaprivativa') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="areaprivativa">Área Privativa</p>
                                </div>
                                <div className={imovel.incluso.includes('armariobanheiro') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="armariobanheiro">Armário no Banheiro</p>
                                </div>
                                <div className={imovel.incluso.includes('armariocozinha') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="armariocozinha">Armário na Cozinha</p>
                                </div>
                                <div className={imovel.incluso.includes('armarioquarto') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="armarioquarto">Armário no Quarto</p>
                                </div>
                                <div className={imovel.incluso.includes('box') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="box">Box</p>
                                </div>
                                <div className={imovel.incluso.includes('closet') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="closet">Closet</p>
                                </div>
                                <div className={imovel.incluso.includes('dce') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="dce">DCE</p>
                                </div>
                                <div className={imovel.incluso.includes('despensa') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="despensa">Despensa</p>
                                </div>
                                <div className={imovel.incluso.includes('escritorio') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="escritorio">Escritório</p>
                                </div>
                                <div className={imovel.incluso.includes('lavabo') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="lavabo">Lavabo</p>
                                </div>
                                <div className={imovel.incluso.includes('mobiliado') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="mobiliado">Mobiliado</p>
                                </div>
                                <div className={imovel.incluso.includes('rouparia') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="rouparia">Rouparia</p>
                                </div>
                                <div className={imovel.incluso.includes('aguaindividual') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="aguaindividual">Água Individual</p>
                                </div>
                                <div className={imovel.incluso.includes('alarme') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="alarme">Alarme</p>
                                </div>
                                <div className={imovel.incluso.includes('aquecedorgas') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="aquecedorgas">Aquecedor a gás</p>
                                </div>
                                <div className={imovel.incluso.includes('aquecedoreletrico') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="aquecedoreletrico">Aquecedor Elétrico</p>
                                </div>
                                <div className={imovel.incluso.includes('aquecedorsolar') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="aquecedorsolar">Aquecedor Solar</p>
                                </div>
                                <div className={imovel.incluso.includes('boxdespejo') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="boxdespejo">Box Despejo</p>
                                </div>
                                <div className={imovel.incluso.includes('cercaeletrica') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="cercaeletrica">Cerca Elétrica</p>
                                </div>
                                <div className={imovel.incluso.includes('circuitotv') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="circuitotv">Circuito de TV</p>
                                </div>
                                <div className={imovel.incluso.includes('gascanalizado') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="gascanalizado">Gás Canalizado</p>
                                </div>
                                <div className={imovel.incluso.includes('interfone') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="interfone">Interfone</p>
                                </div>
                                <div className={imovel.incluso.includes('jardim') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="jardim">Jardim</p>
                                </div>
                                <div className={imovel.incluso.includes('lavanderia') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="lavanderia">Lavanderia</p>
                                </div>
                                <div className={imovel.incluso.includes('portaoeletronico') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="portaoeletronico">Portão Eletrônico</p>
                                </div>
                                <div className={imovel.incluso.includes('portaria24horas') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="portaria24horas">Portaria 24 horas</p>
                                </div>
                                <div className={imovel.incluso.includes('seguranca24horas') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="seguranca24horas">Segurança 24 horas</p>
                                </div>
                                <div className={imovel.incluso.includes('quintal') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="quintal">Quintal</p>
                                </div>
                                <div className={imovel.incluso.includes('gramado') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="gramado">Gramado</p>
                                </div>
                                <div className={imovel.incluso.includes('academia') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="academia">Academia</p>
                                </div>
                                <div className={imovel.incluso.includes('churrasqueira') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="churrasqueira">Churrasqueira</p>
                                </div>
                                <div className={imovel.incluso.includes('hidromassagem') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="hidromassagem">Hidromassagem</p>
                                </div>
                                <div className={imovel.incluso.includes('homecinema') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="homecinema">Homecinema</p>
                                </div>
                                <div className={imovel.incluso.includes('piscina') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="piscina">Piscina</p>
                                </div>
                                <div className={imovel.incluso.includes('playground') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="playground">Playground</p>
                                </div>
                                <div className={imovel.incluso.includes('quadraesportiva') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="quadraesportiva">Quadra Esportiva</p>
                                </div>
                                <div className={imovel.incluso.includes('quadratenis') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="quadratenis">Quadra de Tênis</p>
                                </div>
                                <div className={imovel.incluso.includes('salamassagem') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="salamassagem">Sala de Massagem</p>
                                </div>
                                <div className={imovel.incluso.includes('salaofestas') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="salaofestas">Salão de Festas</p>
                                </div>
                                <div className={imovel.incluso.includes('salaojogos') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="salaojogos">Salão de Jogos</p>
                                </div>
                                <div className={imovel.incluso.includes('sauna') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="sauna">Sauna</p>
                                </div>
                                <div className={imovel.incluso.includes('wifi') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="wifi">Wi-fi</p>
                                </div>
                                <div className={imovel.incluso.includes('espacogourmet') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="espacogourmet">Espaço Gourmet</p>
                                </div>
                                <div className={imovel.incluso.includes('garageband') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="garageband">Garageband</p>
                                </div>
                                <div className={imovel.incluso.includes('quadrasquash') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="quadrasquash">Quadra Squash</p>
                                </div>
                                <div className={imovel.incluso.includes('solmanha') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="solmanha">Sol da manhã</p>
                                </div>
                                <div className={imovel.incluso.includes('vistamar') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="vistamar">Vista do Mar</p>
                                </div>
                                <div className={imovel.incluso.includes('varandagourmet') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="varandagourmet">Varanda Gourmet</p>
                                </div>
                                <div className={imovel.incluso.includes('lareira') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="lareira">Lareira</p>
                                </div>
                                <div className={imovel.incluso.includes('cabeamentoestruturado') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="cabeamentoestruturado">Cabeamento Estruturado</p>
                                </div>
                                <div className={imovel.incluso.includes('tvacabo') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="tvacabo">TV a Cabo</p>
                                </div>
                                <div className={imovel.incluso.includes('conexaointernet') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="conexaointernet">Conexao com internet</p>
                                </div>
                                <div className={imovel.incluso.includes('vistamontanha') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="vistamontanha">Vista da Montanha</p>
                                </div>
                                <div className={imovel.incluso.includes('vistalago') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="vistalago">Vista do Lago</p>
                                </div>
                                <div className={imovel.incluso.includes('aceitaPet') ? 'switch-text' : 'disabled'}>
                                    <Icon>check_circle</Icon>
                                    <p id="aceitaPet">Aceita PET</p>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="hideDesktop">
                        <Button
                            className={imovel.incluso.length === 0 ? 'disabled' : 'hideDesktop'}
                            variant="contained"
                            color="primary"
                            startIcon={<Icon>phone_in_talk</Icon>}
                            size="large"
                            fullWidth
                            onClick={() => document.getElementById('valoresImovel').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}
                        >
                            Solicitar contato
                        </Button>
                    </div>


                    <div id="codigoImovel">
                        <br />
                        <b>Código do Imóvel Alpha Select: {imovel.id}</b>

                        <br />
                        <br />
                        <hr />
                    </div>

                    <div id="imoveisSimilares" className={similares?.length === 0 ? 'disabled' : ''}>
                        <h4>Imoveis Similares</h4>
                        <div id="similar-container">

                            <div id="arrow-right-similar" className="similar hideMobile" onClick={() => scrollSlide('left', 'similar')}></div>

                            <div className='feed-category' id="similar">

                                {similares?.map((item, index) =>
                                    <Link target={Config.OPEN_IMOVEL_NEW_TAB ? '_blank' : '_self'} to={`/imovel/${item?.cidade}/${item?.tipoImovel}/${item?.bairro}/${item?.id}`.trim().replaceAll(' ', '-')} key={item?.id} id={'img-similares' + index} className="card-link">
                                        <div className="card" key={item?.id}>

                                            <div className="card-img">
                                                <img
                                                    src={Config.AWS_S3_BUCKET_DOMAIN + item?.img}
                                                    alt={item?.title}
                                                    onError={() => document.getElementById('img' + index).style.display = 'none'}
                                                    loading="lazy"
                                                    onLoad={() => document.getElementById('img-similares' + index).classList.add('loaded')} />
                                                <Chip
                                                    className="chip"
                                                    label={item.incluso?.includes('lancamento') ? 'Lançamento' : item.aceitaPermuta ? 'Aceita Permuta' : item.incluso?.includes('construcao') ? 'Em Construção' : 'A Venda'}
                                                    size="small"
                                                    variant="outlined" />
                                            </div>

                                            <div className="card-infos">

                                                <span className="tipoImovel">{item?.tipoImovel} - Cod.{item?.id}</span>

                                                <h2 className="tituloImovel">{item?.title}</h2>

                                                <span className="localizacao">{item?.tipoImovel === 'Casa Condominio' ? item?.condominio.nomeCondominio : item?.bairro}</span>

                                                <p className="metragem-quartos">
                                                    {item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                        `${item.metragemTerreno}m²`

                                                        :

                                                        `${item.metragemConstrucao}m² - ${item.qtdQuartos} quartos`}
                                                </p>

                                                {/* <p className="valor">{item.tipoNegocio === 'aluguel'
            ? "Aluguel " + item.precoAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : "Condo. + IPTU " + (item.precoCondominio + item.precoIPTU).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p> */}
                                                <p className="valorTotal">Total
                                                    {item.tipoNegocio === 'aluguel'
                                                        ? item.precoTotalAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                        : item.precoTotalCompra.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                            </div>

                                        </div>
                                    </Link>
                                )}

                            </div>

                            <div id="arrow-left-similar" className="similar hideMobile" onClick={() => scrollSlide('right', 'similar')}></div>
                        </div>
                    </div>

                </div>

                <br />
                <br />

                <Dialog disableBackdropClick disableEscapeKeyDown open={dialogOpen} onClose={() => setDialogOpen(false)}>

                    <DialogTitle>Dados do Imóvel</DialogTitle>

                    <DialogContent>

                        <TextField
                            label="Nome do Proprietário"
                            type="text"
                            variant="outlined"
                            style={{ width: 340, marginRight: 10 }}
                            value={owner.nome}
                            disabled />

                        <TextField
                            label="Captador"
                            type="text"
                            variant="outlined"
                            style={{ width: 150 }}
                            value={imovel.captadorId}
                            disabled />

                        <br />
                        <br />

                        <TextField
                            label="Telefone proprietário"
                            type="text"
                            variant="outlined"
                            style={{ width: 245, marginRight: 10 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        <IconButton onClick={() => window.open(`https://api.whatsapp.com/send?phone=55${owner.tel}`)}>
                                            <Icon>whatsapp</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            fullWidth
                            value={owner.tel}
                            disabled />

                        <TextField
                            label="E-mail proprietário"
                            type="text"
                            variant="outlined"
                            style={{ width: 245 }}
                            fullWidth
                            value={owner.email}
                            disabled />

                        <br />
                        <br />

                        <hr syle={{ border: '1px solid #00c5c6' }} />

                        <br />
                        <br />

                        <TextField
                            label="Endereço"
                            type="text"
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={3}
                            value={`${imovel.endereco}, ${imovel.numero}, ${imovel.complemento} - ${imovel.bairro}, ${imovel.cidade} / ${imovel.estado}`}
                            disabled />

                        <br />
                        <br />

                        <TextField
                            label="Cadastrado em"
                            type="text"
                            variant="outlined"
                            value={new Date(imovel?.createdAt).toLocaleDateString('pt-BR')}
                            style={{ width: '49%', marginRight: '1%' }}
                            disabled />

                        <TextField
                            label="Última atualização"
                            type="text"
                            variant="outlined"
                            value={new Date(imovel?.publishedAt).toLocaleDateString('pt-BR')}
                            style={{ width: '49%', marginRight: '1%' }}
                            disabled />

                        <br />
                        <br />

                        <div className={localStorage.getItem('token') ? '' : 'disabled'}>

                            <TextField
                                label="Cadastrado às"
                                type="text"
                                variant="outlined"
                                fullWidth
                                value={new Date(imovel?.createdAt).toLocaleString('pt-BR')}
                                disabled />

                            <br />
                            <br />

                            <TextField
                                label="Anotações Internas"
                                type="text"
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={3}
                                value={imovel?.anotacaoInterna}
                                disabled />

                            <br />
                            <br />

                            <Checkbox checked={imovel.destaque} disabled />
                            <span className="checkbox-text">{imovel.destaque ? 'Destaque Habilitado' : 'Sem Destaque'}</span>

                        </div>

                    </DialogContent>

                    <DialogActions>

                        <Button onClick={() => setDialogOpen(false)} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>

                </Dialog>

                <Footer />
            </Container>
        </>
    )
}

export default Imovel
