import React, { useState, useEffect } from 'react'

import AutoCompleteLocalizacao from '../../Components/autoCompleteLocalizacao'

import { Container, WhatsAppFloating } from './style'
import { useLocation } from 'react-router-dom'

import Config from '../../Config'

import { Link } from 'react-router-dom'
import Icon from '@material-ui/core/Icon'

import LinearProgress from '@material-ui/core/LinearProgress'

import { globalStateContext } from '../../GlobalState'

const HeaderTopMobile = () => {

    const [globalState, setGlobalState] = globalStateContext.use()

    const location = useLocation()

    const [canDisableMenu, setCanDisableMenu] = useState(false)

    useEffect(() => {

        setCanDisableMenu(new RegExp(Config.ROUTE_MENU_TOP_DISABLED).test(location.pathname))

        if (!location.pathname.includes('encontrar-imovel')) {
            setGlobalState({ ...globalState, searchText: '' })
            document.getElementById('autocomplete-highlight-search').value = ''
        }

    // eslint-disable-next-line
    }, [location])

    
    const isMobile = window.innerWidth < 1300

    return (
        <>
            <WhatsAppFloating style={canDisableMenu ? { display: 'none' } : {}}>
                <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=5515997550065&text=Ola, tudo bem? Estou no site da Imobiliaria Alpha Select. Poderia me ajudar?">
                    <img src="/whatsapp-logo.png" alt="Chamar no Whatsapp" />
                </a>
            </WhatsAppFloating>

            <Container style={canDisableMenu ? { display: 'none' } : {}}>

                <div id="header-items">
                    <div id="menu-e-logo">
                        <Link to="/menu">
                            <Icon
                                fontSize="large"
                                style={{ color: Config.primary_color }}
                                id="menuIcon">
                                menu
                            </Icon>
                        </Link>
                        <Link to="/">
                            <div id="logo-alpha-select">
                                <img
                                    src="/logo-site-alpha-select.png"
                                    alt="Logo Alpha Select"
                                    className={location.pathname.includes('/encontrar-imovel-sorocaba') && isMobile ? 'disabled' : ''}
                                />
                            </div>
                        </Link>

                    </div>
                    <div>
                        <AutoCompleteLocalizacao gotoOnSelect={true} isTopBar={true} size="small" />
                    </div>

                    <a
                        target="_blank" rel="noreferrer"
                        href={
                            'https://api.whatsapp.com/send?phone='
                            + Config.whatsapp
                            + '&text=Oi, tudo bem? Estou no site da Alpha Select poderia me ajudar?'
                            + ' Estou na pagina: '
                            + window.location.href
                        }>
                        <div id="telefone" className='hideMobile'>
                            <img src='/whatsapp-logo.png' id="logo-whatsapp" alt="Logo WhatsApp" />
                            <div>
                                <span>(15) 99755-0065 / 3222-2212</span>
                            </div>
                        </div>
                    </a>
                </div>

                <LinearProgress className={globalState.loading ? '' : 'disabled'} />

            </Container>
        </>
    )
}


export default HeaderTopMobile
