import React, { memo } from 'react'
import { Switch } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
import { PrivateRoute, AlreadyAuth, LoggedRoute, AdminRoute, DevRoute, PublicRoute } from './CustonsRoutes'

import Feed from './Pages/Feed'
import Login from './Pages/Login'
import Dash from './Pages/Dash'
import Imovel from './Pages/Imovel'
import Menu from './Pages/Menu'
import Buscar from './Pages/Buscar'
import CadastroImovel from './Pages/CadastroImovel'
import CadastroCondominio from './Pages/CadastroCondominio'
import EncontrarImovel from './Pages/EncontrarImovel'
import DevCompressImgSmall from './Pages/DevCompressImgSmall'

const Routes = () => {
    return (
        <Switch>
            <PublicRoute path="/" exact component={Feed} />
            <PublicRoute path="/i/:idImovel/" exact component={Imovel} />
            <PublicRoute path="/imovel/:queryCidade/:queryTipoImovel/:bairro/:idImovel/" exact component={Imovel} />
            <PublicRoute path="/i/:idImovel/:hash" exact component={Imovel} />
            <AlreadyAuth path="/login/" exact component={Login} />
            <PublicRoute path="/menu" exact component={Menu} />
            <PublicRoute path="/habilitar-busca-administrativa-190190" exact component={Menu} />
            <PublicRoute path="/anunciar-imovel-sorocaba" exact component={Menu} />
            <PublicRoute path="/encontrar-imovel-sorocaba" exact component={Buscar} />
            <PublicRoute path="/encontrar-imovel-sorocaba/:searchTextURLParam" exact component={Buscar} />
            <PublicRoute path="/busca-administrativa" exact component={Buscar} />
            <PublicRoute path="/busca-administrativa/:searchTextURLParam" exact component={Buscar} />
            <PublicRoute path="/todos-imoveis" exact component={Buscar} />
            <PublicRoute path="/imobiliaria-em-sorocaba" exact component={Menu} />
            <PublicRoute path="/contato-imobiliaria-sorocaba" exact component={Menu} />
            <LoggedRoute path="/desktop/dash" exact component={Dash} />
            <LoggedRoute path="/desktop/dash/cadastro-imovel" exact component={CadastroImovel} />
            <LoggedRoute path="/desktop/dash/cadastro-imovel/:idJB" exact component={CadastroImovel} />
            <LoggedRoute path="/desktop/dash/importImoview/:idImoview" exact component={CadastroImovel} />
            <LoggedRoute path="/desktop/dash/cadastro-condominio" exact component={CadastroCondominio} />
            <LoggedRoute path="/desktop/dash/encontrar-imovel" exact component={EncontrarImovel} />
            <LoggedRoute path="/desktop/dash/dev/compress-img-small/:idImovel" exact component={DevCompressImgSmall} />
        </Switch>
    )
}

export default memo(Routes)
