import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import * as Sentry from '@sentry/react'

var systemVersion = '1.0'

Sentry.init({
    dsn: "https://b39b646caee1436f95294a29fdc6c09a@o328079.ingest.sentry.io/4504433221566464",
    release: "imovelerp-frontendp@v" + systemVersion
})


// capture all console.error
const consoleError = console.error
console.error = function (firstParam) {
    const response = consoleError.apply(console, arguments)
    Sentry.captureException(firstParam, { level: 'error' })
    return response;
}


ReactDOM.render(<App />, document.getElementById('root'))
