import { createGlobalStyle } from 'styled-components'

import Config from './Config'

import IconArrow from './assets/icon-arrow.svg'

export default createGlobalStyle`

    /* sem problema de por width 100% e o padding passar dos 100% */
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        overflow-x: hidden
    }

    @media screen and (max-width: 800px) {
        body, html {
            margin: 0;
            overflow-x: hidden;
        }
    }

    h1, h2, h3, p {
        font-family: Roboto;
        font-weight: 400;
        color: #354044;
    }

    h1, h3, p {
        font-size: 18px;
    }

    h1 {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
    }

    h2 { font-size: 14px; }

    a, a:visited {
        color: inherit;
        text-decoration: none;
    }

    hr {
        border: 1px solid #f5ca93;
    }

    .swal2-html-container { font-family: 'Roboto' }

    /* Altera a cor que separa as linhas */
    tr { border-color: #d89a5d !important; border-bottom: none !important }

    tfoot { border-top: solid 3px #d89a5d}

    /* remove a scrollbar horizontal da div paginação */
    .MuiTablePagination-toolbar { width: 324px !important; padding-left: 0 !important}
    .MuiTableCell-footer { padding: 0 !important }

    /* mudança no padding pra caber três botoões na mesma linha em resolução pequena */
    .MuiButton-containedSizeSmall { padding: 4px 8px !important}

    .disabled, .desactive { display: none !important }
    
    .ButtonShadow, .MuiButton-contained.Mui-disabled {
        box-shadow: 
        0px 3px 1px -2px rgba(0,0,0,0.2),
        0px 2px 2px 0px rgba(0,0,0,0.14),
        0px 1px 5px 0px rgba(0,0,0,0.12)
        !important
    }

    .blue { color: #008676 }

    @media screen and (max-width: 800px) {
        .hideMobile {
            display: none !important
        }
    }

    @media screen and (min-width: 800px) {
        .hideDesktop {
            display: none
        }
    }

    .material-icons {
        font-family: 'Material Icons' !important;
        vertical-align: middle
    }

    .feed-category {
        width: 99vw;
        height: 400px;
        overflow-y: hidden;
        overflow-x: scroll;
        display: flex;
        flex-direction: row;
        scroll-snap-type: x mandatory;
        scroll-snap-stop: always;
        -webkit-overflow-scrolling: touch;

        @media screen and (max-width: 800px) {
            #arrow-left, #arrow-right { visibility: none }
        }

        @media screen and (min-width: 800px) {

            /* Remove a barra de scroll somente no desktop, 
            no mobile o efeito de rolar para de funcionar*/
            overflow-x: hidden !important;

            /* Efeito smooth para rolar para o lado */
            scroll-behavior: smooth;

            #arrow-left, #arrow-right {
                height: 400px;
                width: 12px;
                background-position: 50%;
                background-repeat: no-repeat;
                cursor: pointer;
                position: absolute;
            }

            #arrow-left {
                background-image: url(${IconArrow});
                transform: rotate(180deg);
                margin-left: -45px
            }

            #arrow-right {
                background-image: url(${IconArrow});
                margin-left: 1237px;
            }

            a::first-child { margin-left: 20px }
        }

        .loaded img { opacity: 1 !important }

        .card-link {
            img {
                /* Animation onload */
                -moz-transition: opacity .5s !important;
                -webkit-transition: opacity .5s !important;
                -o-transition: opacity .5s !important;
                transition: opacity .5s !important;

                /* Efeito aplicado antes de colocar a classe loaded */
                opacity: 0;
            }

            img:hover {
                opacity: .8 !important;
            }

            .card {
                width: 227px;
                height: 383px;
                margin-right: 20px;
                padding-right: 30px;
                display: flex;
                flex-direction: column;
                scroll-snap-align: start;

                .card-img {
                    width: 234px;
                    height: 216px;

                    .chip {
                        font-weight: 300;
                        text-transform: capitalize;
                        margin: -413px 0 0 10px;
                        background-color: ${Config.primary_color};
                        color: #fff;
                    }

                    img {
                        width: 234px;
                        height: 216px;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .card-infos {
                    border: 1px solid #C4C4C4;
                    box-sizing: border-box;
                    border-radius: 0px 0px 9px 9px;
                }
            }
        }
    }

    .card-infos {
        width: 235px;
        height: 169px;
        padding: 12px 20px;
        position: relative;

        .tipoImovel {
            font-size: 14px;
        }

        .tituloImovel {
            font-weight: bold;
            font-size: 18px;
            margin: 12px 0 0 0;
            /* Limitar os caracteres */
            max-width: 20ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .localizacao {
            font-size: 14px;
            margin: 0;
            line-height: 0;
        }

        .metragem-quartos {
            font-size: 14px !important;
            color: #969393;
            margin: 5px 0;
        }

        .valor, .valorTotal {
            line-height: 4px !important
        }

        .valor {
            font-size: 11px !important;
            color: ${Config.primary_color};
        }

        .valorTotal {
            font-size: 14px !important;
            color: ${Config.primary_color};
        }

        .update {
            font-size: 12px !important;
            color: #969393;
            position: absolute;
            bottom: 0px;
        }
    }

    .feed-vertical-mobile {

        @media screen and (max-width: 1300px) {
        
            display: block;
            height: 100%;
            
            .card {
                margin-bottom: 20px;
                width: 100% !important;
                height: 418px !important;
                
                .card-img {
                    width: 100% !important;
                    height: 286px !important;
                    
                    img {
                        width: 100% !important;
                        height: 286px !important;
                        object-fit: cover !important;
                    }
                    
                    .chip {
                        margin: -558px 0 0 10px !important;
                    }
                }
                
                .card-infos {
                    width: 100%;
                    
                    h2 {
                        font-weight: 700 !important;
                    }
                }
            }
        }
    }

    .checkbox-text {
        font-size: 16px !important;
        font-family: 'Roboto' !important;
    }

`