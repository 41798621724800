/* eslint-disable no-useless-escape */

import Config from './Config'

export function onlyNumber(val) {

    if (!val) return ''

    // remove tudo exceto número de 0 a 9
    val = val.toString().replace(/[^0-9]/g, '')

    // convert to INT
    val = Number(val)

    return val
}

/**
 * Funções abaixo referente a função remove acentos de strings
*/

var map = { "â": "a", "Â": "A", "à": "a", "À": "A", "á": "a", "Á": "A", "ã": "a", "Ã": "A", "ê": "e", "Ê": "E", "è": "e", "È": "E", "é": "e", "É": "E", "î": "i", "Î": "I", "ì": "i", "Ì": "I", "í": "i", "Í": "I", "õ": "o", "Õ": "O", "ô": "o", "Ô": "O", "ò": "o", "Ò": "O", "ó": "o", "Ó": "O", "ü": "u", "Ü": "U", "û": "u", "Û": "U", "ú": "u", "Ú": "U", "ù": "u", "Ù": "U", "ç": "c", "Ç": "C" }

export function removerAcentos(s) { return s.replace(/[\W\[\] ]/g, function (a) { return map[a] || a }) }

/**
 * Funções para Detectar Mobile
*/

export function detectMob() {
    return ((window.innerWidth <= 600) || (window.innerHeight <= 600))
}


/* Randomize array */

export function shuffleArray(array) {
    return array
        .map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value)
}

export function loggout() {
    if (localStorage.getItem('debug')) {

        alert('forced forced disconnect')

    } else {

        localStorage.removeItem('userId')
        localStorage.removeItem('token')
        window.location.href = Config.URL_login
    }
}


export function captalize(texto) {

    let prepos = ["da", "do", "das", "dos", "a", "e", "de", "em", "no", "na"]
    return texto.split(' ') // quebra o texto em palavras
        .map((palavra) => { // para cada palavra
            if (abreviacao(palavra) || numeralRomano(palavra)) {
                return palavra
            }

            palavra = palavra.toLowerCase()
            if (prepos.includes(palavra)) {
                return palavra
            }
            return palavra.charAt(0).toUpperCase() + palavra.slice(1)
        })
        .join(' ') // junta as palavras novamente

    function abreviacao(s) {
        return /^([A-Z]\.)+$/.test(s)
    }

    function numeralRomano(s) {
        return /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.test(s)
    }
}


export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function totalSizeByteOfString (s) {
    return encodeURI(s).split(/%..|./).length - 1
}

 /**
 * Check if element is visible on screen
 */

export function checkVisible (elm) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}
