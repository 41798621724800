import styled from 'styled-components'

export const Container = styled.div`

    font-family: Roboto;
    font-size: 20px;
    padding: 20px 0 20px 20px;
    width: 80vw;
    height: 1200px;
    background-color: #F2F2F3;
    padding-top: 80px;

    h1 { margin-top: 70px }

    .card-wrapper {
        margin-top: 30px;
        display: flex;

        .card:hover, .card-large:hover:not(.disable-animation) {
            filter: drop-shadow(4px 5px 10px rgba(0, 0, 0, 0.10));

            /* Efeito de transição - subir */
            transform: translateY(-10%);

            /* Animação suave */
            transition-timing-function: ease-in;
            transition: 0.2s;

        }

        .card, .card-large {
            /* Efeito de transição - voltar ao local original após subir */
            transform: translateY(0);

            /* Animação suave */
            transition-timing-function: ease-out;
            transition: 0.55s;

            background-color: #fff;
            border-radius: 20px;
            padding: 35px 0;
            margin-right: 30px;

            .gradiente-azulEscuro { background: linear-gradient(180deg, #9F9FFF 0%, #5353FE 100%) }

            .gradiente-azulClaro { background: linear-gradient(180deg, #B5D7FF 0%, #6B9EDA 100%) }

            .gradiente-rosaEscuro { background: linear-gradient(180deg, #F68FCD 0%, #DC3C9C 100%) }

            .gradiente-rosaClaro { background: linear-gradient(180deg, #FFC3C3 0%, #F57777 100%) }

            .gradiente-laranja { background: linear-gradient(180deg, #F9C1A9 0%, #F48F63 100%) }

            .gradiente-roxo { background: linear-gradient(180deg, #B887F5 0%, #7D49BE 100%) }

            .bg-disabled { background-color: lightgray }
            .color-disabled { color: lightgray }


            .bgIcon {
                width: 78px;
                height: 71px;
                border-radius: 20px;
                text-align: center;
                margin: 0 55px;

                span {
                    color: #fff;
                    font-size: 50px;
                    margin-top: 10px;
                }
            }
        }

        .card {
            width: 187px;
            height: 177px;

            p { text-align: center }
        }

        .card-large {
            width: 295px;
            height: 177px;

            p {
                text-align: left;
                margin-left: 55px;
            }
        }
    }
`