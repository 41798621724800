/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { api } from '../../api'
import Config from '../../Config'

import { Container } from './style'

const DevCompressImgSmall = () => {

    const [imgSmall, setImgSmall] = useState({})
    const [imgSmallCompressed, setImgSmallCompressed] = useState({})

    const [imgSmallSize, setImgSmallSize] = useState(0)
    const [imgSmallCompressedSize, setImgSmallCompressedSize] = useState(0)

    const location = useLocation()

    const { idImovel } = useParams()

    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    function resizedataURL(datas, wantedWidth, wantedHeight) {
        return new Promise(async function (resolve, reject) {

            // We create an image to receive the Data URI
            var img = document.createElement('img')

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                var canvas = document.createElement('canvas')
                var ctx = canvas.getContext('2d')

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth
                canvas.height = wantedHeight

                // We resize the image with the canvas method drawImage()
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

                var dataURI = canvas.toDataURL()

                // This is the return of the Promise
                resolve(dataURI)
            }

            // We put the Data URI in the image's src attribute
            img.src = datas

        })
    }// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50)

    function imgSize (img) {
        return new TextEncoder().encode(img).length / 1024
    }

    useEffect(() => {
        
        async function getImgSmall () {

            let where = {
                "id": idImovel.toString()
            }

            let responseApi = await api.get('/imgsmalls?where=' + JSON.stringify(where))

            let data = responseApi.data.data[0][0]

            let OriginalImgFromS3 = Config.AWS_S3_BUCKET_DOMAIN + data.img

            setImgSmall({ ...imgSmall, img: OriginalImgFromS3 })


            // baixa a imagem através do link e convert em blob (base64)
            var request = new XMLHttpRequest()
            request.open('GET', Config.NO_CORS_WORKER + OriginalImgFromS3, true)
            request.responseType = 'blob'
            request.onload = function () {
                var reader = new FileReader()
                reader.readAsDataURL(request.response)
                reader.onload = async function (e) {

                    setImgSmallSize(imgSize(e.target.result))

                    // compressão da imagem
                    var compressedImg = await resizedataURL(e.target.result, 150, 150)

                    setImgSmallCompressedSize(imgSize(compressedImg))

                    setImgSmallCompressed({ id: data.id, img: compressedImg })
                }
            }
            request.send()
        }

        getImgSmall()

    }, [location])

    return (
        <Container>

            <h1>Compress Img Small</h1>

            <br/>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: 500 }}>
                <div>
                    <img
                        src={imgSmall?.img}
                        alt="imgSmall"
                        width={227}
                        height={216}
                        id="imgSmall"
                    />

                    <p>{imgSmallSize.toFixed(2)} KB</p>
                </div>

                <div>
                    <img
                        src={imgSmallCompressed?.img}
                        alt="imgSmall"
                        width={227}
                        height={216}
                        id="imgSmallCompressed"
                    />

                    <p>{imgSmallCompressedSize.toFixed(2)} KB --- Redução {100 - ((imgSmallCompressedSize / imgSmallSize) * 100).toFixed(2)}%</p>
                </div>
            </div>

        </Container>
    )
}

export default DevCompressImgSmall
