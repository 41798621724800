import React from 'react'
import Config from './Config'

import { Redirect, Route } from 'react-router-dom'

export const LoggedRoute = props => {
        const isLogged = !!localStorage.getItem('userId')
        return isLogged ? <Route {...props} /> : <Redirect to={Config.URL_login + '?returnTo=' + window.location.href} />
}

export const AlreadyAuth = props => {
        const isLogged = !!localStorage.getItem('userId')
        return isLogged ? window.location.href = Config.URL_home : <Route {...props} />
}

export const PrivateRoute = props => {
        const canAcess = localStorage.getItem('userLevel') > 1
        const isLogged = !!localStorage.getItem('userId')
        return canAcess && isLogged ? <Route {...props} /> : <Redirect to={Config.URL_home} />
}

export const AdminRoute = props => {
        const canAcess = localStorage.getItem('userLevel') > 2
        const isLogged = !!localStorage.getItem('userId')
        return canAcess && isLogged ? <Route {...props} /> : <Redirect to={Config.URL_home} />
}

export const DevRoute = props => {
        const canAcess = localStorage.getItem('userLevel') > 3
        const isLogged = !!localStorage.getItem('userId')
        return canAcess && isLogged ? <Route {...props} /> : <Redirect to={Config.URL_home} />
}

export const PublicRoute = props => {
        return <Route {...props} />
}
