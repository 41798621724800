import React from 'react'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'

import GlobalStyle from './GlobalStyle'

import { PublicContainer, DashContainer } from './AppStyle'
// import DashMenuLeft from './Layout/DashMenuLeft'
import HeaderTopMobile from './Layout/HeaderTopMobile'
import HeaderTopDash from './Layout/HeaderTopDash'

// Toast notification
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// mudar palleta de cor padrão do material-ui
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

import "react-responsive-carousel/lib/styles/carousel.min.css"

import Config from './Config'

const theme = createTheme({
    palette: {
        primary: {
            main: Config.primary_color,
        },
        secondary: {
            main: Config.secondary_color,
        },
        type: 'light'
    }
})

const App = () => {

    return (
        <Router>
            <MuiThemeProvider theme={theme}>
            
                <GlobalStyle />
                <ToastContainer />

                { window.location.pathname.includes('dash')

                ?

                    <DashContainer>
                    <HeaderTopDash />
                        <Routes />
                        {/* <DashMenuLeft /> */}
                    </DashContainer>

                :

                    <>
                        <HeaderTopMobile />
                        <PublicContainer>
                            <Routes />
                        </PublicContainer>
                    </>

                }          

            </MuiThemeProvider>
        </Router>
    )
}

export default App