/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback } from 'react'

import { Container } from './style'
import Footer from '../../Layout/Footer'

import { apiWithCache } from '../../api'
import { globalStateContext } from '../../GlobalState'

import { useLocation, useParams, useHistory, Link } from 'react-router-dom'

// habilitar thema dark
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

import Config from '../../Config'
import _ from 'lodash'

import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'

import Chip from '@material-ui/core/Chip'

import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import { removerAcentos } from '../../Functions'

import { Helmet } from 'react-helmet'

const theme = createTheme({
    palette: {
        primary: {
            main: Config.primary_color,
        },
        secondary: {
            main: Config.secondary_color,
        },
        // type: 'dark'
    }
})

const Menu = () => {

    function useQuery() {
        const { search } = useLocation()

        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    var query = useQuery()

    const location = useLocation()
    const history = useHistory()

    const [pageTitle, setPageTitle] = useState(null)

    var { searchTextURLParam } = useParams()

    const [GlobalState, setGlobalState] = globalStateContext.use()

    const [searchConfigs, setSearchConfigs] = useState({
        loadingFinishedNoImoveis: false
    })

    const [items, setItems] = useState([])

    const [filtros, setFiltros] = useState({
        qtdQuartos: query.get('qtdQuartos') ?? 'selecione',
        categoriaPreco: query.get('categoriaPreco') ?? 'selecione',
        tipoImovel: query.get('tipoImovel') ?? 'selecione',
        aceitaPermuta: false,
        lancamento: false,
        construcao: false,
        showOnlyEnabled: false
    })

    useEffect(() => {

        setGlobalState({ ...GlobalState, searchText: searchTextURLParam })

        // verifica se tem pelo menos um dos campos
        var noQuerys = (filtros.qtdQuartos === '' && filtros.categoriaPreco === '' && filtros.tipoImovel === '')

        if (GlobalState.searchText === '' && noQuerys && !window.location.pathname.includes('/encontrar-imovel-sorocaba')) return

        getSearchResults(searchTextURLParam)

        // reseta o scroll para inicia a pagina no topo aparecendo a imagem completa do imovel
        // setTimeout(function() { window.scrollTo(0, 0) }, 1)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })

        // outro comando que sobe a pagina pro topo --- no mobile não funcionava
        document.body.scrollTop = 0

    }, [location])


    const debounce = useCallback(
        _.debounce((_searchVal) => {
            getSearchResults(_searchVal)
        }, 500),
        []
    )


    async function getSearchResults(searchText = '') {

        console.log(searchText)

        setPageTitle(`Busca ${searchText} - Imóveis à venda em Sorocaba`)

        if (searchText !== '') {

            searchText = searchText.split('-')[0]
            searchText = searchText.replace('jardim', '').replace('residencial', '').replace('parque')
            searchText = removerAcentos(searchText)
            searchText = searchText.trim().toLowerCase()

        }

        setSearchConfigs({ ...searchConfigs, loadingFinishedNoImoveis: false })

        if (location.pathname.includes('busca-administrativa') && searchText === '') return

        try {
            var response

            if ((JSON.parse(localStorage.getItem('enableSearchAdmin#0000')) || localStorage.getItem('userId')) && location.pathname.includes('busca-administrativa')) {

                response = await apiWithCache.get('search_admin?q=' + searchText + "&tipoNegocio=" + localStorage.getItem('tipoNegociacao'))
            }

            else {

                if (location.pathname.includes('todos-imoveis')) {

                    response = await apiWithCache.get('search_admin?q=' + searchText + "&tipoNegocio=" + localStorage.getItem('tipoNegociacao'))
                    response.data.data = response.data.data.filter(item => !item.disabled && item.published)

                } else {

                    response = await apiWithCache.get('search?q=' + searchText + "&tipoNegocio=" + localStorage.getItem('tipoNegociacao'))

                }
            }

            var responseItems = response.data.data

            if (filtros.qtdQuartos && filtros.qtdQuartos !== 'selecione') {

                responseItems = responseItems.filter(item => item.qtdQuartos >= filtros.qtdQuartos)

            }

            if (filtros.categoriaPreco && filtros.categoriaPreco !== 'selecione') {
                responseItems = responseItems.filter(item => item.categoriaPreco == filtros.categoriaPreco)
            }

            if (filtros.tipoImovel && filtros.tipoImovel !== 'selecione') {
                responseItems = responseItems.filter(item => item.tipoImovel == filtros.tipoImovel)
            }

            if (filtros.aceitaPermuta) {
                responseItems = responseItems.filter(item => item.aceitaPermuta)
            }

            if (filtros.lancamento) {
                responseItems = responseItems.filter(item => item.incluso?.includes('lancamento'))
            }

            if (filtros.construcao) {
                responseItems = responseItems.filter(item => item.incluso?.includes('emConstrucao'))
            }

            if (filtros.showOnlyEnabled) {
                responseItems = responseItems.filter(item => !item.disabled && item.published)
            }

            if (!responseItems) { return }

            // coloca os destaques no começo
            responseItems = responseItems.sort(({ destaque: stateA = false }, { destaque: stateB = false }) =>
                Number(stateB) - Number(stateA)
            )

            setItems(responseItems)

            if (responseItems.length === 0) {
                setSearchConfigs({ ...searchConfigs, loadingFinishedNoImoveis: true })
            }

        } catch (err) {

            return console.error(err)

        }

        // setGlobalState({ ...GlobalState, loading: false })
    }

    function handleChangeSearchText(text) {

        setGlobalState({ ...GlobalState, loading: true, searchText: text })

        // let filtroQuarto = ''
        // let filtroPreco = ''
        // let filtroTipo = ''

        // if (filtros.qtdQuartos) {
        //     filtroQuarto = `&qtdQuartos=${filtros.qtdQuartos}`
        // }

        // if (filtros.categoriaPreco) {
        //     filtroPreco = `&categoriaPreco=${filtros.categoriaPreco}`
        // }

        // if (filtros.tipoImovel) {
        //     filtroTipo = `&tipoImovel=${filtros.tipoImovel}`
        // }

        // if ((JSON.parse(localStorage.getItem('enableSearchAdmin#0000')) || localStorage.getItem('userId')) && location.pathname.includes('busca-administrativa')) {

        //     if (text === '') return window.history.replaceState("", "", '/busca-administrativa' + filtroPreco + filtroQuarto + filtroTipo)
        //     // altera a URL pra conseguir voltar a busca no mesmo estado
        //     window.history.replaceState("", "", '/busca-administrativa/' + text + filtroPreco + filtroQuarto + filtroTipo)

        // } else {

        //     if (text === '') return window.history.replaceState("", "", '/encontrar-imovel-sorocaba' + filtroPreco + filtroQuarto + filtroTipo)
        //     // altera a URL pra conseguir voltar a busca no mesmo estado
        //     window.history.replaceState("", "", '/encontrar-imovel-sorocaba/' + text + filtroPreco + filtroQuarto + filtroTipo)

        // }


        debounce(text)

    }

    return (
        <>

            {
                pageTitle !== null ?
                    <Helmet>‍
                        <title>{pageTitle}</title>‍
                        <meta property="og:title" content={pageTitle} />
                        <meta property="og:description" content={pageTitle} />
                    </Helmet>
                    :
                    <></>
            }

            <Container>
                <MuiThemeProvider theme={theme}>

                    <div id="header" className={(JSON.parse(localStorage.getItem('enableSearchAdmin#0000')) || localStorage.getItem('userId')) && location.pathname.includes('busca-administrativa') ? '' : 'disabled'}>

                        <a onClick={() => history.goBack()}>
                            <Icon id="icon-back" style={{ color: Config.primary_color }}>arrow_back</Icon>
                        </a>

                        <TextField
                            fullWidth
                            id="searchInput"
                            label=""
                            name="inputTeste"
                            onChange={e => handleChangeSearchText(e.target.value)}
                            value={searchConfigs.searchText}
                            type="text"
                            variant="outlined"
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <Icon>search</Icon>
                                    </InputAdornment>,
                                endAdornment:
                                    <InputAdornment position="end">
                                        {GlobalState.loading ? <CircularProgress size={20} /> : ''}
                                    </InputAdornment>,
                            }}
                        />

                        <hr />

                    </div>

                    <div id="filtros">
                        <h1>Filtros</h1>

                        <div className={GlobalState.searchText === '' || !GlobalState.searchText ? 'disabled' : ''}>

                            <Chip
                                className="chip"
                                label={GlobalState.searchText?.toUpperCase()}
                                color="primary"
                                onDelete={() => history.push('/encontrar-imovel-sorocaba/')}
                                size="large"
                                variant="default"
                            />

                            <br />
                            <br />
                        </div>

                        <Select
                            variant="outlined"
                            value={filtros.tipoImovel}
                            fullWidth
                            onChange={e => {
                                setFiltros({ ...filtros, tipoImovel: e.target.value })
                                localStorage.setItem('tipoImovel', e.target.value)
                            }}
                        >
                            <MenuItem value="selecione">Tipo de imóvel</MenuItem>
                            <MenuItem value='Apartamento'>Apartamento</MenuItem>
                            <MenuItem value='Casa'>Casa</MenuItem>
                            <MenuItem value='Casa Condomínio'>Casa Condomínio</MenuItem>
                            <MenuItem value='Terreno'>Terreno</MenuItem>
                            <MenuItem value='Lote Condomínio'>Lote Condomínio</MenuItem>
                            {/* <MenuItem value='Galpão'>Galpão</MenuItem> */}
                            <MenuItem value='Sala Comercial'>Sala Comercial</MenuItem>
                            <MenuItem value='Chácara'>Chácara</MenuItem>
                        </Select>

                        <br />
                        <br />

                        <Select
                            variant="outlined"
                            value={filtros.qtdQuartos}
                            fullWidth
                            onChange={e => {
                                setFiltros({ ...filtros, qtdQuartos: e.target.value })
                                localStorage.setItem('qtdQuartos', e.target.value)
                            }}
                        >
                            <MenuItem value="selecione">Quartos</MenuItem>
                            <MenuItem value="0">Qualquer</MenuItem>
                            <MenuItem value="1">1+</MenuItem>
                            <MenuItem value="2">2+</MenuItem>
                            <MenuItem value="3">3+</MenuItem>
                            <MenuItem value="4">4+</MenuItem>
                            <MenuItem value="5">5+</MenuItem>
                        </Select>

                        <br />
                        <br />

                        <Select
                            variant="outlined"
                            value={filtros.categoriaPreco}
                            fullWidth
                            onChange={e => {
                                setFiltros({ ...filtros, categoriaPreco: e.target.value })
                                localStorage.setItem('categoriaPreco', e.target.value)
                            }}
                        >
                            <MenuItem value="selecione">Faixa de Valor</MenuItem>
                            <MenuItem value="1">Até R$220 mil</MenuItem>
                            <MenuItem value="2">De R$200 mil a R$ 500 mil</MenuItem>
                            <MenuItem value="3">De R$500 mil a R$ R$800 mil</MenuItem>
                            <MenuItem value="4">De R$800 mil a R$ 1,2 milhões</MenuItem>
                            <MenuItem value="5">Acima de R$ 1,2 milhões</MenuItem>
                        </Select>

                        <br />
                        <br />

                        <Checkbox checked={filtros.aceitaPermuta} onChange={() => setFiltros({ ...filtros, aceitaPermuta: !filtros.aceitaPermuta })} />
                        <span className="checkbox-text" onClick={() => setFiltros({ ...filtros, aceitaPermuta: !filtros.aceitaPermuta })}>Aceita Permuta</span>

                        <br />

                        <Checkbox checked={filtros.lancamento} onChange={() => setFiltros({ ...filtros, lancamento: !filtros.lancamento })} />
                        <span className="checkbox-text" onClick={() => setFiltros({ ...filtros, lancamento: !filtros.lancamento })}>Lançamento</span>

                        <br />

                        <Checkbox checked={filtros.construcao} onChange={() => setFiltros({ ...filtros, construcao: !filtros.construcao })} />
                        <span className="checkbox-text" onClick={() => setFiltros({ ...filtros, construcao: !filtros.construcao })}>Em Construção</span>

                        <div className={location.pathname.includes('busca-administrativa') ? '' : 'disabled'}>
                            <Checkbox checked={filtros.showOnlyEnabled} onChange={() => setFiltros({ ...filtros, showOnlyEnabled: !filtros.showOnlyEnabled })} />
                            <span className="checkbox-text" onClick={() => setFiltros({ ...filtros, showOnlyEnabled: !filtros.showOnlyEnabled })}>Mostrar somente ativos</span>
                        </div>

                        <br />

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            // style={{ width: '50%', marginTop: 10 }}
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={() => getSearchResults(GlobalState.searchText)}
                            startIcon={<Icon>search</Icon>}
                        >
                            BUSCAR
                        </Button>
                    </div>

                    <div id="padding">

                        <div className={searchConfigs.loadingFinishedNoImoveis ? 'mensagem-busca' : 'disabled'}>
                            <Icon>query_stats</Icon>
                            <h2>Infelizmente não encontramos nenhum imóvel baseado nos filtros que você utilizou. Nossos imóveis são selecionados e garantimos a qualidade ao publica-los.</h2>
                        </div>

                        <div className={globalStateContext.loading ? 'mensagem-busca' : 'disabled'}>
                            <Icon>travel_explore</Icon>
                            <h2>Estamos procurando seu imóvel</h2>
                        </div>

                        <div className={items.length === 0 || globalStateContext.loading ? 'disabled' : 'feed-category feed-vertical-mobile'}>

                            {
                                items.map((item, index) =>

                                    <Link target={Config.OPEN_IMOVEL_NEW_TAB ? '_blank' : '_self'} to={`/imovel/${item.cidade}/${item.tipoImovel}/${item.bairro}/${item.id}`.trim().replaceAll(' ','-')} key={item.id} id={'items-img' + index} className="card-link">
                                        <div className="card" key={item.id}>

                                            <div className="card-img">
                                                <img
                                                    effect="opacity"
                                                    src={Config.AWS_S3_BUCKET_DOMAIN + item?.img}
                                                    alt={item.title}
                                                    onError={() => {
                                                        if (!document.getElementById('img' + index)) return
                                                        document.getElementById('img' + index).style.display = 'none'
                                                    }
                                                    }
                                                    loading="lazy"
                                                    onLoad={() => document.getElementById('items-img' + index)?.classList.add('loaded')}
                                                />
                                                <Chip
                                                    className="chip"
                                                    label={item.disabled || !item.published ? 'Inativo no site' : item.incluso?.includes('lancamento') ? 'Lançamento' : item.aceitaPermuta ? 'Aceita Permuta' : item.incluso?.includes('construcao') ? 'Em Construção' : 'A Venda'}
                                                    size="small"
                                                    style={item.disabled || !item.published ? { backgroundColor: 'red' } : {}}
                                                    variant="outlined" />
                                            </div>

                                            <div className="card-infos">

                                                <span className="tipoImovel hideMobile">{item.tipoImovel} - </span>

                                                <h2 className='hideDesktop'>
                                                    {
                                                        item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                            `${item.tipoImovel?.replace('Condomínio', '')} ${item.bairro?.replace('Jardim', '')} com ${item.metragemTerreno}m² - ${item.cidade}`

                                                            :

                                                            `${item.tipoImovel} ${item.bairro?.replace('Jardim', '')} com ${item.qtdQuartos} quartos, ${item.metragemConstrucao}m² - ${item.cidade}`

                                                    }
                                                </h2>

                                                <span className="localizacao hideMobile">{item.tipoImovel === 'Casa Condominio' ? item.condominio.nomeCondominio : item.bairro}</span>

                                                <p className="metragem-quartos">
                                                    {
                                                        item.tipoImovel === 'Terreno' || item.tipoImovel === 'Lote Condomínio' ?

                                                            `${item.metragemTerreno}m²`

                                                            :

                                                            `${item.metragemConstrucao}m² - ${item.qtdQuartos} quartos`

                                                    }
                                                </p>

                                                {/* <p className="valor">{item.tipoNegocio === 'aluguel' ? "Aluguel " + item.precoAluguel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "Condo. + IPTU " + (item.precoCondominio + item.precoIPTU).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p> */}
                                                <p className="valorTotal">Total {item.tipoNegocio === 'aluguel' ? item.precoTotalAluguel?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : item.precoTotalCompra?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                {/* <p className={(JSON.parse(localStorage.getItem('enableSearchAdmin#0000')) || localStorage.getItem('userId')) ? 'update' : 'disabled'}>Atualizado em: {new Intl.DateTimeFormat().format(new Date(item.publishedAt))}</p> */}
                                                <p className={(JSON.parse(localStorage.getItem('enableSearchAdmin#0000')) || localStorage.getItem('userId')) ? 'update' : 'disabled'}>Atualizado há {Math.round((new Date() - new Date(item.publishedAt)) / (1000 * 60 * 60 * 24))} dia(s)</p>
                                            </div>

                                        </div>
                                    </Link>
                                )
                            }

                        </div>
                    </div>

                </MuiThemeProvider>
                <Footer />
            </Container>
        </>
    )
}

export default Menu
