import styled from 'styled-components'

export const Container = styled.div`
    .MuiFormControl-root {
        border: 1px solid #02A4FF;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #000 !important;
        background-color: #EEEEEE;
        border-radius: 0;

        .MuiSelect-icon {
            color: #02A4FF !important;
        }
    }
`