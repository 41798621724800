import styled from 'styled-components'

export const Container = styled.div`
    width: 100vw;
    display: flex;
    padding: 40px 90px 40px 60px;
    justify-content: space-between;

    p {
        font-size: 15px;
        margin: 0;
    }

    @media screen and (max-width: 1300px) {
        display: block;
        padding: 40px;
        
        span {
            margin-bottom: 20px;
        }
    }
`