/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'

import { Container } from './style'

import { api } from '../../api'

import { useLocation, Link, useHistory } from 'react-router-dom'

import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'

// habilitar thema dark
import { createTheme , MuiThemeProvider } from '@material-ui/core/styles'

import Config from '../../Config'

const theme = createTheme ({
    palette: {
        primary: {
            main: Config.primary_color,
        },
        secondary: {
            main: Config.secondary_color,
        },
        // type: 'dark'
    }
})

const Login = () => {

    const location = useLocation()
    const history = useHistory()

    const [ userConfigs, setUserConfigs ] = useState({
        "email": "",
        "tel": "",
        "favorites": [],
        "nome": "",
        "sobrenome": "",
        "id": '',
        "role": ''
    })

    useEffect(() => {

        async function getUserConfigs () {
            const { data } = await api.get('users')

            localStorage.setItem('userId', data.userId)
            localStorage.setItem('email', data.emal)
            localStorage.setItem('tel', data.tel)
            localStorage.setItem('nome', data.nome)
            localStorage.setItem('sobrenome', data.nome)
            localStorage.setItem('role', data.role)
        }

        setUserConfigs({
            ...userConfigs,
            "email": localStorage.getItem('email') ?? '',
            "tel": localStorage.getItem('tel') ?? '',
            "favorites": localStorage.getItem('favorites') ?? '',
            "nome": localStorage.getItem('nome') ?? '',
            "sobrenome": localStorage.getItem('sobrenome') ?? '',
            "id": localStorage.getItem('userId') ?? '',
            "role": localStorage.getItem('role') ?? ''
        })

        if (localStorage.getItem('userId') && !!localStorage.getItem('nome')) { getUserConfigs() }

    }, [location])

    function buscarCodigoImovel () {
        let idImovelDigitado = prompt("Digite o código do Imóvel", "")

        if (idImovelDigitado === null || idImovelDigitado === "") {
            return
        } else {
            history.push(`/i/${idImovelDigitado}`)
        }
    }

    document.title = 'Menu - Alpha Select'

    return (
        <Container>
        <MuiThemeProvider theme={theme}>

            <div id="header">
                <Link to="/">
                    <img src="/Logo-Alpha-Select.png" id="logo" alt="Logo J&B Imobiliaria" />
                </Link>

                <a href="javascript:history.back()">
                    <Icon id="icon-close" style={{ color: Config.primary_color, fontSize: '3rem' }}>cancel</Icon>
                </a>
            </div>

            <div id="entrar-conta">
                
                {/* { userConfigs.nome !== '' && userConfigs.nome !== 'undefined' ?
                
                    <h2>Oi, {userConfigs.nome}</h2>

                :
                
                    <Link to="/login">
                        <Button variant="contained" color="primary" fullWidth>Entrar na sua conta</Button>
                    </Link>

                } */}

                <hr style={{ borderColor: Config.primary_color }} />

            </div>

            <Link to="/"><h2>Início</h2></Link>

            <Link to="/encontrar-imovel-sorocaba"><h2>Buscar imoveis</h2></Link>

            {/* <Link to="/imobiliaria-em-sorocaba"><h2>Sobre nós</h2></Link> */}

            {/* <Link to="/contato-imobiliaria-sorocaba"><h2>Fale conosco</h2></Link> */}

            {/* <Link to="/politica-privacidade"><h2>Politica de Privacidade</h2></Link> */}
            
            {/* <Link to="/termos-uso"><h2>Termos de Uso</h2></Link> */}
            
            <Link to="/busca-administrativa" className={JSON.parse(localStorage.getItem('enableSearchAdmin#0000')) || localStorage.getItem('userId') ? '' : 'disabled'}>
                <h2>Busca Administrativa</h2>
            </Link>
            
            <a href="#" onClick={() => buscarCodigoImovel()}>
                <h2>Buscar por código do imóvel</h2>
            </a>

            <a href="/desktop/dash/cadastro-imovel" className={localStorage.getItem('userId') ? 'hideMobile' : 'disabled'}><h2>Cadastro Administrativo</h2></a>
            
            <br />
            <br />

            <h2 style={{ color: '#00bfc0' }}>Nossos contatos</h2>
            <h2>PABX: (15) 3222-2212</h2>
            <h2>WhatsApp: (15) 99755-0065</h2>
            <h2>E-mail: adm@alphaselect.com.br</h2>

            <a target="_blank" className="hideDesktop" rel="noreferrer" href="https://api.whatsapp.com/send?phone=5515997550065&text=Ola, tudo bem? Estou no site da Imobiliaria Alpha Select. Poderia me ajudar?">
                <img id="whatsapp-imobiliaria" src="/whatsapp-chamar.png" alt="Chamar WhatsApp Imobiliária" />
            </a>

            <Button onClick={() => localStorage.setItem('enableSearchAdmin#0000', true)} className={window.location.pathname.includes('habilitar-busca-administrativa-190190') ? '' : 'disabled'}>
                Habilitar busca adm
            </Button>


        </MuiThemeProvider>
        </Container>
    )
}

export default Login
