/* eslint-disable eqeqeq */
var URL_home_desktop = '/desktop/dash/cadastro-imovel/'
var URL_login_mobile = '/login'

// var AWS_S3_BUCKET_DOMAIN_CDN = 'https://d7cme8knhpgoh.cloudfront.net/'
var AWS_S3_BUCKET_DOMAIN_CDN = 'https://letsgreen.s3.us-east-1.amazonaws.com/'
var AWS_S3_BUCKET_DOMAIN_NO_CDN = 'https://letsgreen.s3.us-east-1.amazonaws.com/'

const Config = {

    URL_home: URL_home_desktop,
    // URL_login: detectMob() ? URL_login_mobile : URL_login_desktop,
    // quando estiver com a pagina de login no desktop também voltar com a verificação
    URL_login: URL_login_mobile,

    // 1 Day = 24 Hrs = 24*60*60 = 86400
    cookie_maxAge: 86400,

    // Rotas que não aparece o componente HeaderTopMobile
    ROUTE_MENU_TOP_DISABLED: /dash|login|menu|admin|app/,

    whatsapp: 5515997550065,

    primary_color: '#098485',
    secondary_color: '#00c5c6',

    NO_CORS_WORKER: 'https://letsgreen-no-cors.naotho.workers.dev/?',

    // se tiver com token atualiza mais rapido para ver as informações atualiadas mais rapidamente
    AWS_S3_BUCKET_DOMAIN: Boolean(localStorage.getItem('token')) ? AWS_S3_BUCKET_DOMAIN_NO_CDN : AWS_S3_BUCKET_DOMAIN_CDN,

    MAPS_API_KEY: 'AIzaSyCsVIyuWec4-IO0WQhHekg3T4h88q_K3Dg',

    OPEN_IMOVEL_NEW_TAB: true
    
}

export default Config
