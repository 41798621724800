/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { Container } from './style'

import Icon from '@material-ui/core/Icon'
import { Link } from 'react-router-dom'

const Dash = () => {

    return (
        <Container>

            <h1>Cadastro</h1>

            <div className="card-wrapper">
                <div className="card-large disable-animation">
                    {/* <div className="bgIcon gradiente-rosaClaro"> */}
                    <div className="bgIcon bg-disabled">
                        <Icon>person_add</Icon>
                    </div>
                    <p className="color-disabled">Cadastro de Proprietário</p>
                </div>

                <Link to="/desktop/dash/cadastro-imovel">
                    <div className="card-large">
                        <div className="bgIcon gradiente-rosaEscuro">
                            <Icon>add_location_alt</Icon>
                        </div>
                        <p>Cadastro de Imóvel</p>
                    </div>
                </Link>

                <Link to="/desktop/dash/cadastro-condominio">
                    <div className="card-large">
                        <div className="bgIcon gradiente-azulEscuro">
                            <Icon>business</Icon>
                        </div>
                        <p>Cadastro de Condomínio</p>
                    </div>
                </Link>

            </div>

            <h1>Consulta de Imóveis</h1>

            <div className="card-wrapper">

                <div className="card">
                    <div className="bgIcon gradiente-azulEscuro">
                        <Icon>business</Icon>
                    </div>
                    <p>Condomínios</p>
                </div>

                <div className="card">
                    <div className="bgIcon gradiente-rosaEscuro">
                        <Icon>place</Icon>
                    </div>
                    <p>Bairros</p>
                </div>

                <div className="card">
                    <div className="bgIcon gradiente-laranja">
                        <Icon>map</Icon>
                    </div>
                    <p>Zonas</p>
                </div>

                <div className="card-large">
                    <div className="bgIcon gradiente-rosaClaro">
                        <Icon>local_fire_department</Icon>
                    </div>
                    <p>Recém Cadastrados</p>
                </div>               

            </div>

            <div className="card-wrapper">

                <div className="card">
                    <div className="bgIcon gradiente-azulClaro">
                        <Icon>person_outline</Icon>
                    </div>
                    <p>Proprietários</p>
                </div>

                <div className="card-large">
                    <div className="bgIcon gradiente-roxo">
                        <Icon>filter_alt</Icon>
                    </div>
                    <p>Filtros Avançados</p>
                </div>

            </div>

            <h1>Administração</h1>

        </Container>
    )
}

export default Dash