/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { Container } from './style'

const Footer = () => {

    return (
        <>

            <hr style={{ borderColor: 'rgb(0, 137, 138)' }} />

            <Container>

                <p>Alpha Select Imobiliária Ltda - 31.691.902/0001-45 / CRECI: 35291-J</p>
                <p className='hideMobile'>Fale com a gente (15) 3222-2212 ---- Assuntos ADM no E-mail: adm@alphaselect.com.br</p>

                <p className='hideDesktop' style={{ marginTop: 20 }}>PABX: (15) 3222-2212</p>
                <p className='hideDesktop'>WhatsApp: (15) 99755-0065</p>
                <p className='hideDesktop'>E-mail: adm@alphaselect.com.br</p>
                
                <a href="/todos-imoveis"><p>Imobiliária em Sorocaba</p></a>

            </Container>
        </>
    )
}

export default Footer