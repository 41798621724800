import styled from 'styled-components'

export const Container = styled.div`

    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding: 20px;
    /* background-color: #000; */
    height: 100vh;
    padding: 25px 20px;

    #header {

        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        
        #logo {
            width: 240px;
            margin-bottom: 45px;
        }

        #icon-close {
            margin-top: 15px;
        }
    }

    #entrar-conta {
        @media screen and (max-width: 800px) {
            display: none
        }
    }

    hr { margin-bottom: 40px }

    h2 {
        font-weight: bold;
        font-size: 22px;
        /* color: #fff; */
    }

    img#whatsapp-imobiliaria { margin-top: 20px }
`