import axios from "axios"

import { loggout } from "./Functions"

import Config from "./Config"

// var DEV_MODE

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     DEV_MODE = true
// } else {
//     DEV_MODE = false
// }

export const api = axios.create({
  // baseURL: DEV_MODE ? 'http://imovelerp.localhost:3333/api' : 'https://s3.alphaselect.com.br/api',
  baseURL: "https://server.alphaselect.com.br/api",
  // baseURL: 'https://imoveiserp-backend-i1svc6jyo-naotho.vercel.app/api',
  timeout: 20000,
  headers: {
    user: localStorage.getItem("userId"),
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Access-Control-Allow-Origin": "*",
  },
  parse: true,
  withCredentials: true,
})

// removido Bearer token e withCredentials para funcionar o cache
export const apiWithCache = axios.create({
  // baseURL: DEV_MODE ? 'http://imovelerp.localhost:3333/api' : 'https://host.alphaselect.com.br/api',
  // baseURL: DEV_MODE ? 'https://s3.alphaselect.com.br/api' : 'https://host.alphaselect.com.br/api',
  baseURL: "https://server.alphaselect.com.br/api",
  timeout: 20000,
  headers: {
    user: localStorage.getItem("userId"),
    "Access-Control-Allow-Origin": "*",
  },
  parse: true,
})

export const apiImoviewImovel = axios.create({
  // baseURL: Config.NO_CORS_WORKER + 'https://api.imoview.com.br/Imovel/RetornarDetalhesImovelDisponivel',
  baseURL:
    Config.NO_CORS_WORKER +
    "https://api.imoview.com.br/Imovel/App_RetornarDetalhesImovel",
  withCredentials: false,
  crossOrigin: true,
  timeout: 10000,
  parse: true,
  headers: {
    chave: "BcCY4bWTsXHhCSeGqmfA09ZisoFS+YON7r0va+iOSnk=",
    codigoacesso: "BD892486160E8DB316D3364C7C14A7B9",
    "Access-Control-Allow-Origin": "*",
  },
})

export const apiCep = axios.create({
  parse: true,
  baseURL: "https://viacep.com.br/ws/",
})

api.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if (
    err.response?.status === 401 ||
    err.response?.status === 403 ||
    err.response?.err === 500 ||
    err.response?.data?.message === "401 Unauthorized"
  ) {
    localStorage.setItem("returnTo", window.location.href)

    // se não incluir é porque venceu o token do usuário, volta pro login
    if (!window.location.href.includes("importImoview")) {
      loggout()
    }
  }
  return Promise.reject(err)
})
