/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { Container } from './style'

import { api } from '../../api'
import { globalStateContext } from '../../GlobalState'
import { loggout } from '../../Functions'

import Icon from '@material-ui/core/Icon'

import Button from '@material-ui/core/Button'

const HeaderTopDash = () => {

    const location = useLocation()

    const [GlobalState, setGlobalState] = globalStateContext.use()

    async function testAccess() {
        try {
            // esconde todo o conteúdo da pagina e aguarda um retorno da API
            // se a API retornar com "não autorizado" o conteúdo 
            // nem é mostrado e volta para a tela de login
            document.querySelector('body').style.display = 'none'

            var responseUser = await api.get('/user/' + localStorage.getItem('userId'))
            var dataUser = responseUser.data.data[0]

            setGlobalState({ ...GlobalState, userData: dataUser, verifiedToken: true })

            console.log(dataUser)

            document.querySelector('body').style.display = 'unset'

        } catch (err) {
            console.error(err)
            loggout()
        }
    }

    useEffect(() => {

        // se o testAccess já foi executado alguma vez nessa mesma sessão do usuário
        // ele não irá executar novamente
        if (!GlobalState.verifiedToken) { testAccess() }

    }, [location])

    return (
        <Container>

            <a href="/busca-administrativa" target="_blank">
                <div id="searchBox">
                    <Icon>search</Icon>
                    <span id="searchText">Busca administrativa de imóveis</span>
                </div>
            </a>

            <div>
                <a href="/desktop/dash/cadastro-imovel" style={{ marginRight: 10 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>add</Icon>}
                    >
                        Cadastrar novo imóvel
                    </Button>
                </a>

                <a href={`/i/${window.location.pathname.split('/')[4]}`}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>arrow_back</Icon>}
                    >
                        Voltar para site
                    </Button>
                </a>
            </div>

        </Container>
    )
}

export default HeaderTopDash