/* eslint-disable no-extend-native */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import { Container } from './style'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { api, apiImoviewImovel, apiCep } from '../../api'
import { onlyNumber } from '../../Functions'

import Config from '../../Config'

import { useLocation, useHistory } from 'react-router-dom'

import Resizer from 'react-image-file-resizer'

import InputAdornment from '@material-ui/core/InputAdornment'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'

import Swal from 'sweetalert2/dist/sweetalert2.js'

import Switch from '@material-ui/core/Switch'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'


const CadastroCondominio = () => {

    const location = useLocation()
    const history = useHistory()

    const [produto, setProduto] = useState({
        "nomeCondominio": "",
        "sobreCondominio": "",
        "id": '',
        "imgCondominioSmallId": "",
        "imgCondominioLargeId": "",
        "createdAt": new Date(),
        "publishedAt": new Date(),
        "published": true,
        "userId": Number(localStorage.getItem('userId')),
        "precoCondominio": 0,
        "descProximidades": "",
        "anotacaoInterna": "",
        "incluso": [],
        "coordenadas": { lat: '', lng: '' },
        "cep": "",
        "endereco": "",
        "numero": "",
        "bairro": "",
        "complemento": "",
        "cidade": "Sorocaba",
        "estado": "SP",
        "importadoIdImoview": ""
    })

    const [imgLarges,] = useState([])

    const [imgSmalls,] = useState([])

    const [imgNames,] = useState([])
    const [errorInput, setErrorInput] = useState([])
    const [imgImported, setImgImported] = useState([])

    const [inclusoSelected, setInclusoSelected] = useState('')
    const [inclusos, setInclusos] = useState([])

    async function getNextIdImovel() {

        let { data } = await api.get('/publicProducts')

        let nextIdGenerated = (data[data.length - 1]?.id || 0) + 1

        return nextIdGenerated
    }


    useEffect(() => {

        async function cleanStateToInitial() {

            let defaultValues = {
                "nomeCondominio": "",
                "sobreCondominio": "",
                "id": '',
                "imgCondominioSmallId": "",
                "imgCondominioLargeId": "",
                "createdAt": new Date(),
                "publishedAt": new Date(),
                "published": true,
                "userId": Number(localStorage.getItem('userId')),
                "precoCondominio": 0,
                "descProximidades": "",
                "anotacaoInterna": "",
                "incluso": [],
                "coordenadas": { lat: '', lng: '' },
                "cep": "",
                "endereco": "",
                "numero": "",
                "bairro": "",
                "complemento": "",
                "cidade": "Sorocaba",
                "estado": "SP",
                "importadoIdImoview": ""
            }

            // os defaults values são necessários pra evitar que volte na tela e esteja preenchido
            setProduto({ ...produto, ...defaultValues })
        }

        function generateInclusosAutocomplete() {

            let getInclusos = []

            // pega a lista de switchs
            let switchs = Array.from(document.querySelectorAll('.switch-text'))

            // pega os textos nos switchs e coloca dentro do inclusos para usar no Autocomplete
            switchs.map(item => getInclusos.push({ id: item.id, text: item.firstChild.nodeValue }))

            getInclusos.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            })

            setInclusos(getInclusos)
        }

        cleanStateToInitial()
        generateInclusosAutocomplete()

        // reseta o scroll para inicia a pagina no topo aparecendo
        // a imagem completa do imovel
        window.scrollTo(0, 0)

    }, [location])

    const cidades = [
        "Araçoiaba da Serra",
        "Barueri",
        "Guarujá",
        "Iperó",
        "Itu",
        "Piedade",
        "Poços de Caldas",
        "Porto Feliz",
        "Praia Grande",
        "São Paulo",
        "Sorocaba",
        "Tietê",
        "Votorantim"
    ]

    const estados = ["SP"]

    async function handleChangeCep(cep) {

        if (!cep) return ''

        // quando entra no loading fica faltando um digito no cep
        setProduto({ ...produto, cep })

        if (cep.toString().length === 8) {

            setLoadingCep(true)
            let { data } = await apiCep.get(`${cep}/json`)
            setLoadingCep(false)

            console.log(data)

            if (data.erro === true) {

                setProduto({ ...produto, cep: cep })

                // coloca o input cep nos erros
                setErrorInput([...errorInput, 'cep'])
                return
            }

            // tira o campo cep dos erros
            let removeCepFromErrors = errorInput.filter(item => item !== 'cep')
            setErrorInput(removeCepFromErrors)

            let { logradouro: endereco, bairro, localidade: cidade, uf: estado } = data

            setProduto({ ...produto, cep, endereco, bairro, cidade, estado })

            document.getElementById('numero').focus()

        } else {

            setProduto({ ...produto, cep: cep })

        }
    }

    async function consultarImoview() {

        let imovelIdToGet = document.getElementById('importadoIdImoview').value

        setLoading(true)

        try {
            var response = await apiImoviewImovel.get(`?codigoImovel=${imovelIdToGet}`)
        }

        catch (err) {

            if (err.message.includes(403)) {

                Swal.fire({
                    title: 'Erro ao importar',
                    text: 'O robô não conseguiu encontrar o código digitado. Ele não existe no Imoview',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })

            } else {

                console.error(err)

                Swal.fire({
                    title: 'Erro ao cadastrar',
                    text: 'Erro: ' + err,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })

            }

            setProduto({ ...produto, importadoIdImoview: '' })

            return setLoading(false)
        }

        let { data } = response

        setLoading(false)


        let imgs = []

        data.imovel.fotos.map(item => imgs.push(item.url))

        setImgImported([...imgs])
    }


    const [loading, setLoading] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)

    async function handleSubmit() {

        try {

            setLoading(true)

            let nextId = await getNextIdImovel()

            produto.id = nextId
            produto.imgCondominioLargeId = nextId
            produto.imgCondominioSmallId = nextId


            let larges = { id: nextId }

            
            const promisse = imgLarges.map(async (item, index) => {
                // encontra o próximo para gerar o img1, img2
                // o array inicia na posição 0 e temos o ID, então não precisa fazer -1
                let nextImg = ('img' + Number(index + 1))

                larges[nextImg] = item
            })

            await Promise.all(promisse)

            let smalls = { id: nextId, img: imgSmalls[0] }

            console.log(larges)

            await api.post('/imgCondominioSmalls', smalls)
            await api.post('/imgCondominioLarges', larges)
            await api.post('/condominios', produto)


            Swal.fire({
                title: 'Sucesso',
                text: 'Condomínio cadastrado com sucesso',
                icon: 'success',
                confirmButtonText: 'OK'
            })

                .then(async () => {
                    window.location.pathname = location.pathname
                })
        }

        catch (err) {

            setLoading(false)

            console.error(err)

            Swal.fire({
                title: 'Erro ao cadastrar',
                text: 'Erro: ' + err,
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

        setLoading(false)

    }

    function compressFilesFromDiscToUpload(values) {
        
        let files = [...values]

        files.forEach((item) => {

            // pega o tipo do arquivo
            let extension = item.type

            // verifica se é imagem, se não for, pula e vai pro próximo
            if (!extension.includes('image')) { return }


            // TODO
            // usar esse array para verificar se o item já foi adicionado pelo nome do arquivo
            imgNames.push(item.name)

            Resizer.imageFileResizer(
                item, // the file from input
                1000, // width
                1000, // height
                "JPEG", // compress format WEBP, JPEG, PNG
                50, // quality
                0, // rotation
                (uri) => {

                    imgLarges.push(uri)

                    // atualiza as ministuras
                    setProduto({ ...produto, sobreCondominio: document.getElementById('sobreCondominio').value })

                },
                "base64" // blob or base64 default base64
            )

            Resizer.imageFileResizer(
                item, // the file from input
                500, // width
                500, // height
                "JPEG", // compress format WEBP, JPEG, PNG
                25, // quality
                0, // rotation
                (uri) => {
                    
                    imgSmalls.push(uri)

                    // atualiza as ministuras
                    setProduto({ ...produto, sobreCondominio: document.getElementById('sobreCondominio').value })
                },
                "base64" // blob or base64 default base64
            )
        })
    }

    function changeSwitchInclusos(item) {

        let target = document.getElementById(item)

        if (produto.incluso.includes(item)) {

            let incluso = produto.incluso.filter(i => i !== item)

            setProduto({ ...produto, incluso: incluso })

            let add = [...inclusos, { id: target.id, text: target.firstChild.nodeValue }]

            add.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            })

            setInclusos(add)

        } else {

            let incluso = [...produto.incluso]

            incluso.push(item)

            setProduto({ ...produto, incluso })

            let rest = inclusos.filter(item => item.id !== target.id)

            rest.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            })

            setInclusos(rest)

        }

    }

    function handleRemoveIncluso() {
        let rest = inclusos.filter(item => item.id !== inclusoSelected.id)

        setInclusos(rest)
        setInclusoSelected('')
    }

    function handleAddIncluso() {

        let incluso = [...produto.incluso, inclusoSelected.id]

        setProduto({ ...produto, incluso: incluso })

        // remove o item que foi adicionado do autocomplete
        handleRemoveIncluso()
    }

    function handleEnterKeyAutocompleteIncluso(e) {

        if (e.key === 'Enter') {

            if (inclusoSelected.id) {

                handleAddIncluso()

            } else {

                // se precionar o enter e não tiver o id salvo, apaga o campo do input
                // para impedir que não entre um valor inválido
                // obrigando o usuário a selecionar um dos itens
                setInclusoSelected({ id: '', text: '' })
            }
        }
    }

    function openEditorImg() {

        var windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=800,height=1000"
        var editorUrl = 'https://www.nvidia.com/research/inpainting/index.html'

        window.open(editorUrl, "NVDIA_EDITOR_WINDOW", windowFeatures)

    }

    async function handleClickImgImported(url) {

        // copy URL to clipboard
        await navigator.clipboard.writeText(url)

        openEditorImg()
    }

    Array.prototype.moveImgLarges = (from,to) => {
        imgLarges.splice(to, 0, imgLarges.splice(from, 1)[0])
        return this;
    }

    Array.prototype.moveImgSmalls = (from,to) => {
        imgSmalls.splice(to, 0, imgSmalls.splice(from, 1)[0])
        return this;
    }

    function onDragEnd (result) {

        // se jogou fora da caixa, exclui
        if (result.destination === null) {
            imgLarges.splice(result.source.index, 1)
            imgSmalls.splice(result.source.index, 1)
            return
        }

        // move(from, to)
        imgLarges.moveImgLarges(result.source.index, result.destination.index)
        imgSmalls.moveImgSmalls(result.source.index, result.destination.index)

        // atualiza a imagem pequena
        setProduto({ ...produto, sobreCondominio: document.getElementById('sobreCondominio').value })
    }

    // style drag and drop img upload

    const grid = 4

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 ${grid}px 0 0`,

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : '#f2f2f3',

        // styles we need to apply on draggables
        ...draggableStyle,
    })

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '#f2f2f3',
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    })

    function handleDropZone (event = '') {

        // event.preventDefault()

        let fileToUploadFromDisc = event?.target?.files || []

        // verifica se o arquivo veio do disco rigido do usuário
        // então encaminha para a compressão
        if (fileToUploadFromDisc.length > 0) {

            let files = [...event.target.files]
            return compressFilesFromDiscToUpload(files)

        }


        let fileURL = ''

        // verifica se o que chegou foi uma imagem para ser importada
        // foi arrastada da caixa de import do imoview
        if (event?.dataTransfer?.getData("text").includes('img-imported')) {

            var data = event.dataTransfer.getData("text")

            var target = event.target.appendChild(document.getElementById(data))
    
            fileURL = event.target.appendChild(document.getElementById(data)).src

            target.remove()

            let toRemove = imgImported.filter(item => item !== data)
            setImgImported(toRemove)

        }

        // verifica se o que chegou foi uma URL vinda de fora do navegador
        // se for, coloca a URL para ser baixada
        if (event?.dataTransfer?.getData("URL").includes('http')) {

            console.log(event.dataTransfer.getData("URL"))

            fileURL = event.dataTransfer.getData("URL")

        }

        if (typeof event === 'string') {

            if (!event.includes('http')) return

            fileURL = event

            // encontra o componente e exclui ele
            document.querySelector(`[src="${fileURL}"]`)?.remove()

            let importContainer = document.querySelector('.box-img .imgsSelected')

            if (importContainer.childElementCount === 0) {
                document.querySelector('.box-imported').style.display = 'none'
            }

        }

        // se chegou até aqui sem URL o arquivo é invalido para upload
        if (fileURL === '') return


        // baixa a imagem através do link e convert em blob (base64)
        var request = new XMLHttpRequest()
        request.open('GET', Config.NO_CORS_WORKER + fileURL, true)
        request.responseType = 'blob'
        request.onload = function () {
            var reader = new FileReader()
            reader.readAsDataURL(request.response)
            reader.onload = function (e) {

                imgLarges.push(e.target.result)
                imgSmalls.push(e.target.result)

                // atualiza as ministuras
                setProduto({ ...produto, sobreCondominio: document.getElementById('sobreCondominio').value })
                
            }
        }
        request.send()
    }

    return (
        <Container>

            <div id="title-top">
                <a onClick={() => history.push(Config.URL_home)}>
                    <Icon id="icon-back" style={{ color: '#8E5916' }}>arrow_back</Icon>
                </a>
                <h1>Cadastro de Condomínio</h1>
            </div>

            <br />

            <TextField
                id="nomeCondominio"
                label="Nome do Condomínio"
                type="text"
                variant="outlined"
                value={produto.nomeCondominio}
                onChange={e => setProduto({ ...produto, nomeCondominio: e.target.value })}
                style={{ width: 500 }}
            />

            <TextField
                id="precoCondominio"
                label="Valor do Condomínio"
                type="text"
                variant="outlined"
                value={produto.precoCondominio}
                onChange={e => setProduto({ ...produto, precoCondominio: onlyNumber(e.target.value) })}
                style={{ width: 180 }}
                InputProps={{
                    startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                }}
            />

            <TextField
                id="importadoIdImoview"
                label="Importar com Código Imóvel"
                type="text"
                variant="outlined"
                style={{ width: 260 }}
                value={produto.importadoIdImoview}
                onChange={e => setProduto({ ...produto, importadoIdImoview: onlyNumber(e.target.value) })}
                error={errorInput.includes('importadoIdImoview')}
                onKeyPress={e => e.key === 'Enter' ? consultarImoview() : ''}
            />

            <Button
                variant="contained"
                color={loading ? 'secondary' : 'primary'}
                onClick={() => consultarImoview()}
                disabled={loading || produto.importadoIdImoview === '' || produto.importadoIdImoview === null}
                startIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : ''}
                style={{ marginTop: 9 }}
            >
                {loading ? '...' : 'OK'}
            </Button>

            <br />
            <br />

            <div className="box-upload">

                <span>Imagens que serão adicionadas ao imóvel</span>

                <div className="box-img" onDrop={e => handleDropZone(e)} onDragOver={e => e.preventDefault()}>

                    <div className="align-input-button">

                        <div style={{ marginTop: 30 }}>
                            <input type="file" id="imgupload" style={{ display: 'none' }} onChange={e => handleDropZone(e)} multiple/>
                            <label for='imgupload' style={{ cursor: 'pointer' }}>
                                <Icon style={{ fontSize: 32, marginRight: 10 }}>cloud_upload</Icon><span>Clique ou solte as imagens para carrega-las</span>
                            </label>
                        </div>

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => openEditorImg()}
                            startIcon={(
                                <Icon
                                    style={{ color: '#8E5916', fontSize: 25 }}>brush
                                </Icon>
                            )}>Remover marca d'gua
                        </Button>
                    </div>

                    <div className={imgLarges.length > 0 ? 'imgsSelected' : 'disabled'}>

                        <br />
                        <h2>Total de imagens adicionadas: {imgLarges.length}</h2>
                        <br />

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {
                                            imgLarges.map((item, index) =>
                                                <Draggable key={index} draggableId={"img" + Number(index + 1)} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            // style={{ width: 90, height: 90 }}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <img
                                                                alt="Miniaturas adicionadas"
                                                                src={item}
                                                                style={{ objectFit: 'cover', width: 120, height: 120 }}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                </div>

            </div>

            <div className={imgImported.length > 0 ? 'box-imported' : 'disabled'}>

                <br />
                <br />

                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span style={{ paddingTop: 15 }}>Imagens prontas para serem importadas</span>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => imgImported.map(item => handleDropZone(item))}
                        startIcon={(
                            <Icon
                                style={{ color: '#8E5916', fontSize: 25 }}>file_upload
                            </Icon>
                        )}>Importar todas
                    </Button>
                </div>

                <div className="box-img">

                    <div className="imgsSelected">
                        {
                            imgImported.map((item, index) =>
                                <img
                                    key={index}
                                    id={'img-imported-' + index}
                                    className="hide-if-dragged"
                                    alt="Miniaturas importadas"
                                    src={item}
                                    onClick={e => handleClickImgImported(e.target.src)}
                                    onError={e => e.target.style.display = 'none'}
                                    width={90}
                                    draggable={true}
                                    onDragStart={e => e.dataTransfer.setData("text", e.target.id)}
                                />
                            )
                        }
                    </div>

                </div>

            </div>

            <br />

            <TextField
                id="cep"
                label="CEP"
                type="text"
                variant="outlined"
                style={{ width: '15%' }}
                value={produto.cep}
                disabled={loadingCep}
                onChange={e => handleChangeCep(onlyNumber(e.target.value.toString()))}
                error={errorInput.includes('cep')}
            />

            <TextField
                id="endereco"
                label="Endereço"
                type="text"
                variant="outlined"
                style={{ width: '65%' }}
                value={loadingCep ? 'carregando...' : produto.endereco}
                disabled={loadingCep}
                onChange={e => setProduto({ ...produto, endereco: e.target.value })}
            />

            <TextField
                id="numero"
                label="Número"
                type="text"
                variant="outlined"
                style={{ width: '20%' }}
                value={produto.numero}
                onChange={e => setProduto({ ...produto, numero: onlyNumber(e.target.value) })}
            />

            <br />
            <br />

            <TextField
                id="bairro"
                label="Bairro"
                type="text"
                variant="outlined"
                style={{ width: "35%" }}
                value={loadingCep ? 'carregando...' : produto.bairro}
                disabled={loadingCep}
                onChange={e => setProduto({ ...produto, bairro: e.target.value })}
            />

            <TextField
                id="complemento"
                label="Complemento"
                type="text"
                variant="outlined"
                style={{ width: '25%' }}
                value={loadingCep ? 'carregando...' : produto.complemento}
                disabled={loadingCep}
                onChange={e => setProduto({ ...produto, complemento: e.target.value })}
            />

            <Select
                id="cidades"
                variant="outlined"
                value={loadingCep ? 'carregando...' : produto.cidade}
                disabled={loadingCep}
                style={{ width: '25%' }}
                onChange={e => setProduto({ ...produto, cidade: e.target.value })}
            >
                <MenuItem value={0} disabled>Selecione a cidade</MenuItem>

                {cidades.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}

            </Select>

            <Select
                id="estado"
                variant="outlined"
                value={loadingCep ? 'carregando...' : produto.estado}
                disabled={loadingCep}
                onChange={e => setProduto({ ...produto, estado: e.target.value })}
            >
                <MenuItem value={0} disabled>Selecione o estado</MenuItem>

                {estados.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}

            </Select>

            <br />
            <br />

            <TextField
                fullWidth
                id="sobreCondominio"
                label="Sobre o condomínio"
                type="text"
                variant="outlined"
                value={produto.sobreCondominio}
                multiline
                rowsMax={8}
                rows={8}
                onChange={e => setProduto({ ...produto, sobreCondominio: e.target.value })}
            />

            <br />
            <br />

            <TextField
                fullWidth
                id="descProximidades"
                label="Descrição da localização e proximidades do imóvel"
                type="text"
                variant="outlined"
                multiline
                value={produto.descProximidades}
                rowsMax={6}
                rows={6}
                onChange={e => setProduto({ ...produto, descProximidades: e.target.value })}
            />

            <br />
            <br />

            <TextField
                fullWidth
                id="anotacaoInterna"
                label="Anotações internas"
                type="text"
                variant="outlined"
                multiline
                value={produto.anotacaoInterna}
                rowsMax={3}
                rows={3}
                onChange={e => setProduto({ ...produto, anotacaoInterna: e.target.value })}
            />

            <br />
            <br />

            <div id="incluso-autocomplete">

                <Autocomplete
                    value={inclusoSelected}
                    style={{ marginRight: 15, width: 300 }}
                    onChange={(event, newValue) => {
                        if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setInclusoSelected({
                                text: newValue.inputValue,
                            })
                        } else {
                            setInclusoSelected(newValue)
                        }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-incluso"
                    options={inclusos}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue
                        }
                        // Regular option
                        return option.text
                    }}
                    renderOption={(option) => option.text}
                    // remova a seta para baixo
                    freeSolo
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Adicionar Itens Inclusos"
                            variant="outlined"
                            onKeyPress={e => handleEnterKeyAutocompleteIncluso(e)}
                        />
                    )}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddIncluso()}
                    disabled={inclusoSelected === '' || inclusoSelected === null}
                    id="button-ok-inclusos"
                >OK
                </Button>

            </div>

            <br />

            <div id="incluso">

                <Switch
                    checked={(produto.incluso.includes('arcondicionado'))}
                    onChange={e => changeSwitchInclusos('arcondicionado', e.target)} />
                <span className="switch-text" id="arcondicionado">Ar-condicionado</span>

                <Switch
                    checked={(produto.incluso.includes('areaservico'))}
                    onChange={() => changeSwitchInclusos('areaservico')} />
                <span className="switch-text" id="areaservico">Área Serviço</span>

                <Switch
                    checked={(produto.incluso.includes('areaprivativa'))}
                    onChange={() => changeSwitchInclusos('areaprivativa')} />
                <span className="switch-text" id="areaprivativa">Área Privativa</span>

                <Switch
                    checked={(produto.incluso.includes('armariobanheiro'))}
                    onChange={() => changeSwitchInclusos('armariobanheiro')} />
                <span className="switch-text" id="armariobanheiro">Armário no Banheiro</span>

                <Switch
                    checked={(produto.incluso.includes('armariocozinha'))}
                    onChange={() => changeSwitchInclusos('armariocozinha')} />
                <span className="switch-text" id="armariocozinha">Armário na Cozinha</span>

                <Switch
                    checked={(produto.incluso.includes('armarioquarto'))}
                    onChange={() => changeSwitchInclusos('armarioquarto')} />
                <span className="switch-text" id="armarioquarto">Armário no Quarto</span>

                <Switch
                    checked={(produto.incluso.includes('box'))}
                    onChange={() => changeSwitchInclusos('box')} />
                <span className="switch-text" id="box">Box</span>

                <Switch
                    checked={(produto.incluso.includes('closet'))}
                    onChange={() => changeSwitchInclusos('closet')} />
                <span className="switch-text" id="closet">Closet</span>

                <Switch
                    checked={(produto.incluso.includes('dce'))}
                    onChange={() => changeSwitchInclusos('dce')} />
                <span className="switch-text" id="dce">DCE</span>

                <Switch
                    checked={(produto.incluso.includes('despensa'))}
                    onChange={() => changeSwitchInclusos('despensa')} />
                <span className="switch-text" id="despensa">Despensa</span>

                <Switch
                    checked={(produto.incluso.includes('escritorio'))}
                    onChange={() => changeSwitchInclusos('escritorio')} />
                <span className="switch-text" id="escritorio">Escritório</span>

                <Switch
                    checked={(produto.incluso.includes('lavabo'))}
                    onChange={() => changeSwitchInclusos('lavabo')} />
                <span className="switch-text" id="lavabo">Lavabo</span>

                <Switch
                    checked={(produto.incluso.includes('mobiliado'))}
                    onChange={() => changeSwitchInclusos('mobiliado')} />
                <span className="switch-text" id="mobiliado">Mobiliado</span>

                <Switch
                    checked={(produto.incluso.includes('rouparia'))}
                    onChange={() => changeSwitchInclusos('rouparia')} />
                <span className="switch-text" id="rouparia">Rouparia</span>

                <Switch
                    checked={(produto.incluso.includes('aguaindividual'))}
                    onChange={() => changeSwitchInclusos('aguaindividual')} />
                <span className="switch-text" id="aguaindividual">Água Individual</span>

                <Switch
                    checked={(produto.incluso.includes('alarme'))}
                    onChange={() => changeSwitchInclusos('alarme')} />
                <span className="switch-text" id="alarme">Alarme</span>

                <Switch
                    checked={(produto.incluso.includes('aquecedorgas'))}
                    onChange={() => changeSwitchInclusos('aquecedorgas')} />
                <span className="switch-text" id="aquecedorgas">Aquecedor a gás</span>

                <Switch
                    checked={(produto.incluso.includes('aquecedoreletrico'))}
                    onChange={() => changeSwitchInclusos('aquecedoreletrico')} />
                <span className="switch-text" id="aquecedoreletrico">Aquecedor Elétrico</span>

                <Switch
                    checked={(produto.incluso.includes('aquecedorsolar'))}
                    onChange={() => changeSwitchInclusos('aquecedorsolar')} />
                <span className="switch-text" id="aquecedorsolar">Aquecedor Solar</span>

                <Switch
                    checked={(produto.incluso.includes('boxdespejo'))}
                    onChange={() => changeSwitchInclusos('boxdespejo')} />
                <span className="switch-text" id="boxdespejo">Box Despejo</span>

                <Switch
                    checked={(produto.incluso.includes('cercaeletrica'))}
                    onChange={() => changeSwitchInclusos('cercaeletrica')} />
                <span className="switch-text" id="cercaeletrica">Cerca Elétrica</span>

                <Switch
                    checked={(produto.incluso.includes('circuitotv'))}
                    onChange={() => changeSwitchInclusos('circuitotv')} />
                <span className="switch-text" id="circuitotv">Circuito de TV</span>

                <Switch
                    checked={(produto.incluso.includes('gascanalizado'))}
                    onChange={() => changeSwitchInclusos('gascanalizado')} />
                <span className="switch-text" id="gascanalizado">Gás Canalizado</span>

                <Switch
                    checked={(produto.incluso.includes('interfone'))}
                    onChange={() => changeSwitchInclusos('interfone')} />
                <span className="switch-text" id="interfone">Interfone</span>

                <Switch
                    checked={(produto.incluso.includes('jardim'))}
                    onChange={() => changeSwitchInclusos('jardim')} />
                <span className="switch-text" id="jardim">Jardim</span>

                <Switch
                    checked={(produto.incluso.includes('lavanderia'))}
                    onChange={() => changeSwitchInclusos('lavanderia')} />
                <span className="switch-text" id="lavanderia">Lavanderia</span>

                <Switch
                    checked={(produto.incluso.includes('portaoeletronico'))}
                    onChange={() => changeSwitchInclusos('portaoeletronico')} />
                <span className="switch-text" id="portaoeletronico">Portão Eletrônico</span>

                <Switch
                    checked={(produto.incluso.includes('portaria24horas'))}
                    onChange={() => changeSwitchInclusos('portaria24horas')} />
                <span className="switch-text" id="portaria24horas">Portaria 24 horas</span>

                <Switch
                    checked={(produto.incluso.includes('seguranca24horas'))}
                    onChange={() => changeSwitchInclusos('seguranca24horas')} />
                <span className="switch-text" id="seguranca24horas">Segurança 24 horas</span>

                <Switch
                    checked={(produto.incluso.includes('quintal'))}
                    onChange={() => changeSwitchInclusos('quintal')} />
                <span className="switch-text" id="quintal">Quintal</span>

                <Switch
                    checked={(produto.incluso.includes('gramado'))}
                    onChange={() => changeSwitchInclusos('gramado')} />
                <span className="switch-text" id="gramado">Gramado</span>

                <Switch
                    checked={(produto.incluso.includes('academia'))}
                    onChange={() => changeSwitchInclusos('academia')} />
                <span className="switch-text" id="academia">Academia</span>

                <Switch
                    checked={(produto.incluso.includes('churrasqueira'))}
                    onChange={() => changeSwitchInclusos('churrasqueira')} />
                <span className="switch-text" id="churrasqueira">Churrasqueira</span>

                <Switch
                    checked={(produto.incluso.includes('hidromassagem'))}
                    onChange={() => changeSwitchInclusos('hidromassagem')} />
                <span className="switch-text" id="hidromassagem">Hidromassagem</span>

                <Switch
                    checked={(produto.incluso.includes('homecinema'))}
                    onChange={() => changeSwitchInclusos('homecinema')} />
                <span className="switch-text" id="homecinema">Homecinema</span>

                <Switch
                    checked={(produto.incluso.includes('piscina'))}
                    onChange={() => changeSwitchInclusos('piscina')} />
                <span className="switch-text" id="piscina">Piscina</span>

                <Switch
                    checked={(produto.incluso.includes('playground'))}
                    onChange={() => changeSwitchInclusos('playground')} />
                <span className="switch-text" id="playground">Playground</span>

                <Switch
                    checked={(produto.incluso.includes('quadraesportiva'))}
                    onChange={() => changeSwitchInclusos('quadraesportiva')} />
                <span className="switch-text" id="quadraesportiva">Quadra Esportiva</span>

                <Switch
                    checked={(produto.incluso.includes('quadratenis'))}
                    onChange={() => changeSwitchInclusos('quadratenis')} />
                <span className="switch-text" id="quadratenis">Quadra de Tênis</span>

                <Switch
                    checked={(produto.incluso.includes('salamassagem'))}
                    onChange={() => changeSwitchInclusos('salamassagem')} />
                <span className="switch-text" id="salamassagem">Sala de Massagem</span>

                <Switch
                    checked={(produto.incluso.includes('salaofestas'))}
                    onChange={() => changeSwitchInclusos('salaofestas')} />
                <span className="switch-text" id="salaofestas">Salão de Festas</span>

                <Switch
                    checked={(produto.incluso.includes('salaojogos'))}
                    onChange={() => changeSwitchInclusos('salaojogos')} />
                <span className="switch-text" id="salaojogos">Salão de Jogos</span>

                <Switch
                    checked={(produto.incluso.includes('sauna'))}
                    onChange={() => changeSwitchInclusos('sauna')} />
                <span className="switch-text" id="sauna">Sauna</span>

                <Switch
                    checked={(produto.incluso.includes('wifi'))}
                    onChange={() => changeSwitchInclusos('wifi')} />
                <span className="switch-text" id="wifi">Wi-fi</span>

                <Switch
                    checked={(produto.incluso.includes('espacogourmet'))}
                    onChange={() => changeSwitchInclusos('espacogourmet')} />
                <span className="switch-text" id="espacogourmet">Espaço Gourmet</span>

                <Switch
                    checked={(produto.incluso.includes('garageband'))}
                    onChange={() => changeSwitchInclusos('garageband')} />
                <span className="switch-text" id="garageband">Garageband</span>

                <Switch
                    checked={(produto.incluso.includes('quadrasquash'))}
                    onChange={() => changeSwitchInclusos('quadrasquash')} />
                <span className="switch-text" id="quadrasquash">Quadra Squash</span>

                <Switch
                    checked={(produto.incluso.includes('solmanha'))}
                    onChange={() => changeSwitchInclusos('solmanha')} />
                <span className="switch-text" id="solmanha">Sol da manhã</span>

                <Switch
                    checked={(produto.incluso.includes('vistamar'))}
                    onChange={() => changeSwitchInclusos('vistamar')} />
                <span className="switch-text" id="vistamar">Vista do Mar</span>

                <Switch
                    checked={(produto.incluso.includes('varandagourmet'))}
                    onChange={() => changeSwitchInclusos('varandagourmet')} />
                <span className="switch-text" id="varandagourmet">Varanda Gourmet</span>

                <Switch
                    checked={(produto.incluso.includes('lareira'))}
                    onChange={() => changeSwitchInclusos('lareira')} />
                <span className="switch-text" id="lareira">Lareira</span>

                <Switch
                    checked={(produto.incluso.includes('cabeamentoestruturado'))}
                    onChange={() => changeSwitchInclusos('cabeamentoestruturado')} />
                <span className="switch-text" id="cabeamentoestruturado">Cabeamento Estruturado</span>

                <Switch
                    checked={(produto.incluso.includes('tvacabo'))}
                    onChange={() => changeSwitchInclusos('tvacabo')} />
                <span className="switch-text" id="tvacabo">TV a Cabo</span>

                <Switch
                    checked={(produto.incluso.includes('conexaointernet'))}
                    onChange={() => changeSwitchInclusos('conexaointernet')} />
                <span className="switch-text" id="conexaointernet">Conexao com internet</span>

                <Switch
                    checked={(produto.incluso.includes('vistamontanha'))}
                    onChange={() => changeSwitchInclusos('vistamontanha')} />
                <span className="switch-text" id="vistamontanha">Vista da Montanha</span>

                <Switch
                    checked={(produto.incluso.includes('vistalago'))}
                    onChange={() => changeSwitchInclusos('vistalago')} />
                <span className="switch-text" id="vistalago">Vista do Lago</span>

            </div>


            <br />
            <br />
            <br />

            <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSubmit()}
                startIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : ''}
                disabled={
                    loading ||
                    imgNames.imgLarges === 0 ||
                    produto.cep === ''  ||
                    produto.endereco === ''  ||
                    produto.numero === '' ||
                    produto.nomeCondominio === '' ||
                    produto.sobreCondominio === '' ||
                    produto.descProximidades === '' ||
                    produto.precoCondominio === 0
                }
            >
                {loading ? 'Cadastrando...' : 'Cadastrar Condomínio'}
            </Button>

            <br />
            <br />

        </Container>
    )
}

export default CadastroCondominio