import React from 'react'

import { Container } from './stye'

// autocomplete imports
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { useLocation, useHistory } from 'react-router-dom'
import { globalStateContext } from '../../GlobalState'

const autocompleteSearchText = ['Villagio Milano - Wanel Ville', 'Vila Trujillo', 'Vila Rica', 'Vila Lucy', 'Vila Fiori', 'Vancouver Homes - Parque Campolim', 'UP Residencial', 'Terras de São Francisco', 'Terra Nova - Wanel Ville', 'Sunlake - Bairro Morros', 'Sunlake  - Bairro Morros', 'Residencial Santinon', 'Residencial Horto Villagio', 'Residencial Estrelas', 'Quintais do Imperador', 'Parque Residencial Villa dos Inglezes', 'Parque Reserva Fazenda Imperial', 'Parque Ibiti Reserva', 'Parque Campolim', 'Parque Bela Vista', 'Loteamento Dinorá Rosa', 'Le France - Alto da Boa Vista', 'Lago da Boa Vista', 'La vista Guadalajara', 'JK Alameda Garden', 'Jardim Wanel Ville IV', 'Jardim Sandra', 'Jardim Residencial Giverny', 'Jardim Residencial Chácara Ondina', 'Jardim Portal da Colina', 'Jardim Pagliato', 'Jardim Ibiti do Paço', 'Jardim Guadalajara', 'Jardim Gonçalves', 'Jardim Dacha Sorocaba', 'Jardim Celeste', 'Jardim Brasilândia', 'Jardim Astro', 'Jacu', 'Ibiti Royal Park - Iporanga', 'Ibiti Royal Park', 'Horto Villagio', 'Horto Florestal', 'Cyrela Landscape Esplanada', 'Condomínio Vila Flora', 'Condomínio Torremolinos', 'Condominio Terra Nova', 'Condomínio Residencial Villaggio Ipanema 1 - Caguassu', 'Condomínio Residencial Pampulha', 'Condomínio Reserva Europa', 'Condomínio Parque Esplanada', 'Condomínio Ilha da Madeira', 'Condomínio Green Garden', 'Central Parque', 'Capoavinha', 'Bairro da Vossoroca', 'Araçoiaba da Serra', 'Alphaville Nova Esplanada 3', 'Alphaville Nova Esplanada', 'Alpha Versailles - Centro', 'Alpha Dijon - Centro']

const isMobile = window.innerWidth < 1300


const AutoCompleteLocalizacao = ({ gotoOnSelect, isTopBar, size }) => {

    const [globalState, setGlobalState] = globalStateContext.use()

    const location = useLocation()
    const history = useHistory()

    return (

        <Container style={ !isTopBar ? { marginTop: -18 } : {} }>

            <Autocomplete
                id="autocomplete-highlight-search"
                style={isTopBar ? isMobile ? { width: 280, display: 'inline-flex' } : { width: 550, display: 'inline-flex' } : {}}
                options={autocompleteSearchText.sort()}
                getOptionLabel={option => option}
                value={globalState.searchText}
                autoComplete={false}
                autoSelect={false}
                clearOnBlur={false}
                className={!location.pathname.includes('encontrar-imovel-sorocaba') && isMobile && isTopBar ? 'disabled' : ''}
                onChange={(event, newValue) => {

                    if (newValue === '' || newValue === null) return setGlobalState({ ...globalState, searchText: '' })

                    setGlobalState({ ...globalState, searchText: newValue })

                    if (gotoOnSelect) {
                        history.push(`/encontrar-imovel-sorocaba/${newValue}`)
                    }


                }}
                renderInput={params => (
                    <TextField {...params}
                        label="Buscar por condomínio ou bairro"
                        size={size}
                        variant="outlined"
                        color='primary'
                        // InputLabelProps={{ shrink: true }}
                        style={{ marginTop: 18, border: 'solid 1px #098485' }}
                    />
                )}
                disableOpenOnFocus
                noOptionsText="Nenhum resultado para retornar"
                renderOption={(option, { inputValue }) => {
                    const matches = match(`${option}`, inputValue)
                    const parts = parse(`${option}`, matches)
                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontSize: '0.775rem', fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    )
                }}
            />
        </Container>
    )
}

export default AutoCompleteLocalizacao