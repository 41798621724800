import styled from 'styled-components'

import Config from '../../Config'

export const Container = styled.div`

    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    height: 100%;

    #header {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 25px 20px;
        position: fixed;
        width: 100vw;
        border-bottom: 1px solid ${Config.primary_color};

        #icon-back {
            margin-top: 15px;
            margin-right: 22px;
        }
    }
    
    .loaded { opacity: 1 !important }

    .card-link-effect {
        /* Animation onload */
        -moz-transition: opacity 1.5s !important;
        -webkit-transition: opacity 1.5s !important;
        -o-transition: opacity 1.5s !important;
        transition: opacity 1.5s !important;

        /* Efeito aplicado antes de colocar a classe loaded */
        opacity: 0;
    }

    #filtros {
        position: fixed;
        top: 122px;
        left: 10px;
        height: 600px;
        width: 300px;
        padding-left: 35px;
        border-radius: 6px;
        /* border: 1px #098485 solid; */

        @media screen and (max-width: 1300px) {
            display: none;
        }

        .checkbox-text {
            font-size: 16px;
            font-family: 'Roboto';
            cursor: pointer;
        }
    }


    #padding {
        padding: 125px 0 30px 17px;
        min-height: 684px !important;

        .mensagem-busca {
            display: flex;
            padding-left: 30px;
            padding-top: 55px;

            span {
                font-size: 58px;
                margin-right: 20px;
                margin-top: 5px;
            }

            h2 {
                font-size: 17px;
                max-width: 650px;
            }
        }

        @media screen and (min-width: 1300px) {
            padding-left: 340px;
            
            .feed-category {
                flex-wrap: wrap;
                height: 100%;
                width: 100% !important;

                a {
                    margin-bottom: 50px;
                }
            }
        }
    }
`