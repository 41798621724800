/* eslint-disable no-extend-native */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer } from 'react'

import { Container } from './style'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { api, apiImoviewImovel, apiCep } from '../../api'
import { globalStateContext } from '../../GlobalState'
import { onlyNumber, captalize, sleep } from '../../Functions'

import Config from '../../Config'

import { useLocation, useHistory, useParams } from 'react-router-dom'

import Resizer from 'react-image-file-resizer'

import InputAdornment from '@material-ui/core/InputAdornment'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'

import Swal from 'sweetalert2/dist/sweetalert2.js'

import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

// pop-up dialog
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { toast } from 'react-toastify'

import ArchiveIcon from '@material-ui/icons/Archive'

// backedrop loading
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

// dialog && backdrop style
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const CadastroImovel = () => {

    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()

    const [, forceUpdate] = useReducer(x => x + 1, 0)

    const { idImoview, idJB } = useParams()

    const [GlobalState,] = globalStateContext.use()


    async function uploadS3() {

        // openImageBase64(imgSmalls[0])

        // transforma o objeto em um array para enviar pro S3 -- aqui remove o index e mantem só a imagem
        var imgLargesToUpload = []

        imgLarges.map(item => imgLargesToUpload.push(item.img))

        // aguarda a compressão antes de enviar pro S3
        let compressedImg = await resizedataURL(imgLarges[0].img, 250, 250)

        var idImovelToSentDB = await getNextIdImovel()

        let response = await api.post('/uploadS3', { imovelId: idImovelToSentDB, imgUpload: [compressedImg, ...imgLargesToUpload] })

        return response.data.data
    }

    // function openImageBase64(img) {
    //     var win = window.open();
    //     win.document.write('<iframe src="' + img + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    // }

    const initialData = {
        "ownerId": "",
        "captadorId": '0',
        "id": '',
        "imgLargeId": '',
        "imgSmallId": '',
        // removido o campo abaixo, ele só pode aparecer caso esteja preenchido
        // "condominioId": "",
        // "imgCondominioId": "",
        "createdAt": new Date(),
        "publishedAt": new Date(),
        "published": false,
        "destaque": false,
        "purchased": false,
        "disabled": false,
        "vivareal": false,
        "olx": false,
        "userId": localStorage.getItem('userId'),
        "tipoNegocio": "none",
        "precoCondominio": 0,
        "nomeCondominio": "",
        "tipoImovel": '0',
        "metragemTerreno": 0,
        "metragemConstrucao": 0,
        "qtdQuartos": '',
        "qtdVagas": 0,
        "qtdSuites": 0,
        "qtdBanheiros": '',
        "precoAluguel": null,
        "precoCompra": null,
        "precoIPTU": "",
        "precoSeguroIncendio": "",
        "precoTaxaServico": 300,
        "precoTotalAluguel": 0,
        "precoTotalCompra": 0,
        "title": "",
        "descImovel": "",
        "descProximidades": "",
        "anotacaoInterna": "",
        "incluso": [],
        "coordenadasLat": '',
        "coordenadasLng": '',
        "aceitaFinanciamento": false,
        "aceitaPermuta": false,
        "cep": "",
        "endereco": "",
        "numero": "",
        "bairro": "",
        "complemento": "",
        "cidade": "Sorocaba",
        "estado": "SP",
        "importadoIdImoview": ""
    }

    var persistData = JSON.parse(localStorage.getItem('persistData'))

    const [imovel, setImovel] = useState(idJB || !localStorage.getItem('persistData') ? initialData : persistData)

    var contadorImgImportadas = 0
    var imgLargesInOrder = []

    const [imgLarges, setImgLarges] = useState([])
    const [imgSmalls,] = useState([])

    const [imgNames,] = useState([])
    const [errorInput, setErrorInput] = useState([])
    const [imgImported, setImgImported] = useState([])

    const [ownerSelected, setOwnerSelected] = useState(null)
    const [ownerToBeCreated, setOwnerToBeCreated] = useState({
        nome: '',
        tel: '',
        email: '',
        createdAt: new Date(),
    })
    const [owners, setOwners] = useState([])

    function cleanCreateOwnersForm() {
        setOwnerToBeCreated({
            ...ownerToBeCreated,
            nome: '',
            tel: '',
            email: '',
            createdAt: new Date(),
        })
    }

    const [condominioSelected,] = useState({ incluso: [] })
    const [condominios, setCondominios] = useState([])

    const [inclusoSelected, setInclusoSelected] = useState('')
    const [inclusos, setInclusos] = useState([])


    async function getNextIdImovel() {

        if (idJB) { return idJB }

        if (idImoview) { return idImoview }

        if (!idJB && !imovel.importadoIdImoview) {

            let { data } = await api.get('/publicproducts')

            let dadosImovel = data.data[0]

            dadosImovel = dadosImovel.sort((a, b) => a.id - b.id)

            let nextIdGenerated = Number(dadosImovel[dadosImovel.length - 1].id) + 1

            return nextIdGenerated.toString()
        }
    }

    async function openLastImovel() {

        if (idJB) {

            history.push(`/desktop/dash/cadastro-imovel/${parseInt(idJB) - 1}`)

        } else {

            history.push(`/desktop/dash/cadastro-imovel/${await getNextIdImovel() - 1}`)
        }

    }

    async function openNextImovel() {

        if (idJB) {

            history.push(`/desktop/dash/importImoview/${parseInt(idJB) + 1}`)
            window.location.reload()

        } else {

            history.push(`/desktop/dash/importImoview/${parseInt(await getNextIdImovel()) + 1}`)
            window.location.reload()
        }

    }

    useEffect(() => {

        async function cleanStateToInitial() {

            let defaultValues = {
                "ownerId": "",
                "captadorId": '0',
                "id": '',
                "imgLargeId": '',
                "imgSmallId": '',
                // removido o campo abaixo, ele só pode aparecer caso esteja preenchido
                // "condominioId": "",
                // "imgCondominioId": "",
                "createdAt": new Date(),
                "publishedAt": new Date(),
                "published": false,
                "destaque": false,
                "purchased": false,
                "disabled": false,
                "vivareal": false,
                "olx": false,
                "userId": localStorage.getItem('userId'),
                "tipoNegocio": "none",
                "precoCondominio": 0,
                "nomeCondominio": "",
                "tipoImovel": '0',
                "metragemTerreno": 0,
                "metragemConstrucao": 0,
                "qtdQuartos": 0,
                "qtdVagas": 0,
                "qtdSuites": 0,
                "qtdBanheiros": 0,
                "precoAluguel": 0,
                "precoCompra": 0,
                "precoIPTU": "",
                "precoSeguroIncendio": "",
                "precoTaxaServico": 300,
                "precoTotalAluguel": 0,
                "precoTotalCompra": 0,
                "title": "",
                "descImovel": "",
                "descProximidades": "",
                "anotacaoInterna": "",
                "incluso": [],
                "coordenadasLat": '',
                "coordenadasLng": '',
                "aceitaFinanciamento": false,
                "aceitaPermuta": false,
                "cep": "",
                "endereco": "",
                "numero": "",
                "bairro": "",
                "complemento": "",
                "cidade": "Sorocaba",
                "estado": "SP",
                "importadoIdImoview": ""
            }

            // os defaults values são necessários pra evitar que volte na tela e esteja preenchido
            setImovel({ ...imovel, ...defaultValues })
        }

        async function getOwners() {
            let responseOwners = await api.get('/owners')

            let dataOwners = responseOwners.data.data[0]

            setOwners(dataOwners)
        }

        async function getCondominios() {
            let responseCondominio = await api.get('/condominios')

            let dataCondominio = responseCondominio.data.data[0]

            setCondominios(dataCondominio)
        }

        function generateInclusosAutocomplete() {

            let getInclusos = []

            // pega a lista de switchs
            let switchs = Array.from(document.querySelectorAll('.switch-text'))

            // pega os textos nos switchs e coloca dentro do inclusos para usar no Autocomplete
            switchs.map(item => getInclusos.push({ id: item.id, text: item.firstChild.nodeValue }))

            getInclusos.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            })

            setInclusos(getInclusos)
        }

        async function getImovel() {

            setAlreadyImported(true)
            setAvoidChangeDataOnLoading(true)

            setBackdropLoading(true)

            let includeImovel = {
                "imgLarge": true,
                "imgSmall": true,
                "owners": true
            }

            var responseImovel = await api.get('/publicproducts/' + idJB.toString() + '?include=' + JSON.stringify(includeImovel))
            var dataImovel = responseImovel.data.data[0]

            setImovel(dataImovel)

            if (dataImovel.tipoNegocio === 'ambos') {
                setTipoNegocio({ ...tipoNegocio, compra: true, aluguel: true })
            } else {
                setTipoNegocio({ ...tipoNegocio, [dataImovel.tipoNegocio]: true })
            }

            setAvoidChangeDataOnLoading(false)

            setOwnerSelected(dataImovel.owners)
            setOwnerToBeCreated(dataImovel.owners)

            // import imgs
            let imgs = [Config.AWS_S3_BUCKET_DOMAIN + dataImovel.imgSmall.img]

            // remove o id da imgLarge para evitar erros
            delete dataImovel.imgLarge.id

            // transforma o object com img1, img2 e img3 em um array
            let arrayImgLarge = Object.values(dataImovel.imgLarge).filter(item => item !== null && item !== '')

            // coloca a URL completa do S3 para conseguir transformar ela em blob
            arrayImgLarge.map(item => imgs.push(Config.AWS_S3_BUCKET_DOMAIN + item))

            setImgImported([...imgs])

            // passa somente a miniatura pra guardar somente ela e comprimir
            handleDropZone(imgs[0], 0, true)

            // envia todas as imagens pro dropzone transformar em blob
            imgs.map((item, index) => handleDropZone(item, imgs.length, false, index))
        }

        // se vier o id na url começa a busca
        function iniciliazeBotImoview() { if (idImoview) { consultarImoview() } }

        function getPersistData() {
            let persistData = JSON.parse(localStorage.getItem('persistData'))

            setImovel({ ...imovel, persistData })
        }

        cleanStateToInitial()
        getOwners()
        getCondominios()
        generateInclusosAutocomplete()
        iniciliazeBotImoview()
        getPersistData()

        if (idJB) { getImovel() }

        // reseta o scroll para iniciar a pagina no topo aparecendo
        // a imagem completa do imovel
        window.scrollTo(0, 0)

    }, [location])

    const filter = createFilterOptions()

    function padronizarValorImoview(value) {

        if (value.includes('m2')) {

            value = value.split('m2')

            return Number(value[0])

        } else {

            if (value.includes('até')) {

                value = value.split('até')
                value = value[0].trim()

                value = value.split(',')
                value = value[0].replace('R$ ', '')
                value = value.replaceAll('.', '')

                return Number(value[0])

            } else {

                value = value.split(',')
                value = value[0].replace('R$ ', '')
                value = value.replaceAll('.', '')

                return Number(value)
            }
        }
    }

    const cidades = [
        "Araçoiaba da Serra",
        "Barueri",
        "Guarujá",
        "Iperó",
        "Itu",
        "Ibiúna",
        "Piedade",
        "Poços de Caldas",
        "Porto Feliz",
        "Praia Grande",
        "São Paulo",
        "Sorocaba",
        "Tietê",
        "Tatuí",
        "Votorantim"
    ]

    const estados = ["SP"]

    const captadores = [
        "Angela",
        "Suelen",
        "Aline",
        "Sandro",
        "Matheus",
        "Naotho",
        "Larissa Martins",
        "Marcia"
    ]

    async function handleChangeCep(cep) {

        if (!cep) return ''

        // quando entra no loading fica faltando um digito no cep
        setImovel({ ...imovel, cep })

        if (cep.toString().length === 8) {

            setLoadingCep(true)
            let { data } = await apiCep.get(`${cep}/json`)
            setLoadingCep(false)

            // console.log(data)

            if (data.erro === true) {

                setImovel({ ...imovel, cep: cep })

                // coloca o input cep nos erros
                setErrorInput([...errorInput, 'cep'])
                return
            }

            // tira o campo cep dos erros
            let removeCepFromErrors = errorInput.filter(item => item !== 'cep')
            setErrorInput(removeCepFromErrors)

            let { logradouro: endereco, bairro, localidade: cidade, uf: estado } = data

            setImovel({ ...imovel, cep, endereco, bairro, cidade, estado })

            document.getElementById('numero').focus()

        } else {

            setImovel({ ...imovel, cep: cep })

        }
    }

    const [tipoNegocio, setTipoNegocio] = useState({
        compra: false,
        aluguel: false
    })

    function changeTipoNegocio(event) {

        let targetName = event.target.name
        let checked = event.target.checked

        setTipoNegocio({ ...tipoNegocio, [targetName]: checked })
    }

    function handleChangeValor(event) {

        let targetName = event.target.name
        let value = onlyNumber(event.target.value)

        setImovel({ ...imovel, [targetName]: value })

        if (targetName === 'precoAluguel') { targetName = 'aluguel' }
        if (targetName === 'precoCompra') { targetName = 'compra' }

        if (event.target.value > 0) {
            setTipoNegocio({ ...tipoNegocio, [targetName]: true })
        }

        if (event.target.value === 0) {
            setTipoNegocio({ ...tipoNegocio, [targetName]: false })
        }
    }

    const [alreadyImported, setAlreadyImported] = useState(false)

    async function verifyIfAlreadyImported() {

        let idToVerify

        if (idImoview) {

            idToVerify = idImoview

        } else {

            idToVerify = document.getElementById('importadoIdImoview').value

        }

        // return false

        let responseImovel = await api.get(`/publicproducts?where={"importadoIdImoview":"${idToVerify}"}`)

        let data = responseImovel.data.data[0][0]

        if (data?.id) {

            history.push(`/desktop/dash/cadastro-imovel/${data.id}`)

            return true

        } else {

            // mostra o erro se não for rota de importação do imoview
            if (!idImoview) {
                Swal.fire({
                    title: 'Atenção',
                    text: `Não foi encontrado o imóvel na base de dados com o código ${idToVerify}`,
                    icon: 'info',
                    confirmButtonText: 'OK'
                })
            }

            return false

        }
    }

    async function consultarImoview() {

        if (alreadyImported) return

        if (await verifyIfAlreadyImported()) {

            if (idImoview) {
                return window.location.pathname = '/desktop/dash/importImoview/' + (Number(idImoview) + 1)
            } else {
                return
            }
        }


        let imovelIdToGet

        if (idImoview) {
            imovelIdToGet = idImoview
        } else {
            imovelIdToGet = document.getElementById('importadoIdImoview').value
        }

        setLoading(true)

        try {
            var response = await apiImoviewImovel.get(`?codigoImovel=${imovelIdToGet}&codigoUsuario=19`)
        }

        catch (err) {

            await api.post('/errors', err)
            console.error(err)

            localStorage.removeItem('persistData')
            window.location.pathname = '/desktop/dash/importImoview/' + (Number(idImoview) + 1)

            if (err.message.includes(403)) {

                Swal.fire({
                    title: 'Erro ao importar',
                    text: 'O robô não conseguiu encontrar o código digitado. Ele não existe no Imoview',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })

            } else {

                console.error(err)

                Swal.fire({
                    title: 'Erro ao cadastrar',
                    text: 'Erro: ' + err,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })

            }

            setImovel({ ...imovel, importadoIdImoview: '' })

            return setLoading(false)
        }

        let { data } = response

        setLoading(false)
        setAlreadyImported(true)

        let incluso = []

        // se tiver como true no sistema imoview joga pra entre do array incluso

        if (data.imovel.arcondicionado === true) { incluso.push('arcondicionado') }
        if (data.imovel.areaservico === true) { incluso.push('areaservico') }
        if (data.imovel.areaprivativa === true) { incluso.push('areaprivativa') }
        if (data.imovel.armariobanheiro === true) { incluso.push('armariobanheiro') }
        if (data.imovel.armariocozinha === true) { incluso.push('armariocozinha') }
        if (data.imovel.armarioquarto === true) { incluso.push('armarioquarto') }
        if (data.imovel.box === true) { incluso.push('box') }
        if (data.imovel.closet === true) { incluso.push('closet') }
        if (data.imovel.dce === true) { incluso.push('dce') }
        if (data.imovel.despensa === true) { incluso.push('despensa') }
        if (data.imovel.escritorio === true) { incluso.push('escritorio') }
        if (data.imovel.lavabo === true) { incluso.push('lavabo') }
        if (data.imovel.mobiliado === true) { incluso.push('mobiliado') }
        if (data.imovel.rouparia === true) { incluso.push('rouparia') }
        if (data.imovel.aguaindividual === true) { incluso.push('aguaindividual') }
        if (data.imovel.alarme === true) { incluso.push('alarme') }
        if (data.imovel.aquecedorgas === true) { incluso.push('aquecedorgas') }
        if (data.imovel.aquecedoreletrico === true) { incluso.push('aquecedoreletrico') }
        if (data.imovel.aquecedorsolar === true) { incluso.push('aquecedorsolar') }
        if (data.imovel.boxdespejo === true) { incluso.push('boxdespejo') }
        if (data.imovel.cercaeletrica === true) { incluso.push('cercaeletrica') }
        if (data.imovel.circuitotv === true) { incluso.push('circuitotv') }
        if (data.imovel.gascanalizado === true) { incluso.push('gascanalizado') }
        if (data.imovel.interfone === true) { incluso.push('interfone') }
        if (data.imovel.jardim === true) { incluso.push('jardim') }
        if (data.imovel.lavanderia === true) { incluso.push('lavanderia') }
        if (data.imovel.portaoeletronico === true) { incluso.push('portaoeletronico') }
        if (data.imovel.portaria24horas === true) { incluso.push('portaria24horas') }
        if (data.imovel.seguranca24horas === true) { incluso.push('seguranca24horas') }
        if (data.imovel.quintal === true) { incluso.push('quintal') }
        if (data.imovel.gramado === true) { incluso.push('gramado') }
        if (data.imovel.academia === true) { incluso.push('academia') }
        if (data.imovel.churrasqueira === true) { incluso.push('churrasqueira') }
        if (data.imovel.hidromassagem === true) { incluso.push('hidromassagem') }
        if (data.imovel.homecinema === true) { incluso.push('homecinema') }
        if (data.imovel.piscina === true) { incluso.push('piscina') }
        if (data.imovel.playground === true) { incluso.push('playground') }
        if (data.imovel.quadraesportiva === true) { incluso.push('quadraesportiva') }
        if (data.imovel.quadratenis === true) { incluso.push('quadratenis') }
        if (data.imovel.salamassagem === true) { incluso.push('salamassagem') }
        if (data.imovel.salaofestas === true) { incluso.push('salaofestas') }
        if (data.imovel.salaojogos === true) { incluso.push('salaojogos') }
        if (data.imovel.sauna === true) { incluso.push('sauna') }
        if (data.imovel.wifi === true) { incluso.push('wifi') }
        if (data.imovel.espacogourmet === true) { incluso.push('espacogourmet') }
        if (data.imovel.garageband === true) { incluso.push('garageband') }
        if (data.imovel.quadrasquash === true) { incluso.push('quadrasquash') }
        if (data.imovel.solmanha === true) { incluso.push('solmanha') }
        if (data.imovel.vistamar === true) { incluso.push('vistamar') }
        if (data.imovel.varandagourmet === true) { incluso.push('varandagourmet') }
        if (data.imovel.lareira === true) { incluso.push('lareira') }
        if (data.imovel.cabeamentoestruturado === true) { incluso.push('cabeamentoestruturado') }
        if (data.imovel.tvacabo === true) { incluso.push('tvacabo') }
        if (data.imovel.conexaointernet === true) { incluso.push('conexaointernet') }
        if (data.imovel.vistamontanha === true) { incluso.push('vistamontanha') }
        if (data.imovel.vistalago === true) { incluso.push('vistalago') }


        let tipoNegocio = data.imovel.finalidade === 'Aluguel' ? 'aluguel' : 'compra'

        let tipoImovel

        // Apartamento / Flat, Casa, Casa Condominio, Terreno, Lote Condominio, Galpão, Sala Comercial

        switch (true) {
            case (data.imovel.tipo === "Casa em condomínio"):
                tipoImovel = "Casa Condomínio"
                break
            case (data.imovel.tipo === "Apartamento"):
                tipoImovel = "Apartamento"
                break
            case (data.imovel.tipo === "Flat"):
                tipoImovel = "Apartamento"
                break
            case (data.imovel.tipo === "Kitnet"):
                tipoImovel = "Apartamento"
                break
            case (data.imovel.tipo === "Casa"):
                tipoImovel = "Casa"
                break
            case (data.imovel.tipo === "Lote em condomínio"):
                tipoImovel = "Lote Condomínio"
                break
            case (data.imovel.tipo === "Lote"):
                tipoImovel = "Terreno"
                break
            case (data.imovel.tipo === "Chácara"):
                tipoImovel = "Chácara"
                break
            case (data.imovel.tipo === "Sala"):
                tipoImovel = "Sala Comercial"
                break
            case (data.imovel.tipo === "Galpão"):
                tipoImovel = "Galpão"
                break
            default: tipoImovel = '0'
        }

        let captadorId

        switch (true) {
            case (data.imovel.captadores[0].nome.includes("Angela")):
                captadorId = "Angela"
                break
            case (data.imovel.captadores[0].nome.includes("Alexandre")):
                captadorId = "Alexandre"
                break
            case (data.imovel.captadores[0].nome.includes("Julio")):
                captadorId = "Julio"
                break
            case (data.imovel.captadores[0].nome.includes("Leonardo")):
                captadorId = "Leonardo"
                break
            case (data.imovel.captadores[0].nome.includes("Muryllo")):
                captadorId = "Muryllo"
                break
            case (data.imovel.captadores[0].nome.includes("Rafael")):
                captadorId = "Rafael"
                break
            case (data.imovel.captadores[0].nome.includes("Robinson")):
                captadorId = "Robinson"
                break
            default: captadorId = '0'
        }

        let rest = inclusos.filter(item => !(incluso.includes(item.id)))

        rest.sort(function (a, b) {
            return a.text.localeCompare(b.text)
        })

        setInclusos(rest)

        setTipoNegocio({ ...tipoNegocio, [tipoNegocio]: true })

        let nextIdGenerated = await getNextIdImovel()

        // cadastro do proprietário
        setOwnerSelected({
            ...ownerSelected,
            nome: data.imovel.proprietarios[0].nome.trim(),
            email: data.imovel.proprietarios[0].email,
            tel: onlyNumber(data.imovel.proprietarios[0].telefone)
        })

        setOwnerToBeCreated({
            ...ownerToBeCreated,
            nome: data.imovel.proprietarios[0].nome.trim(),
            email: data.imovel.proprietarios[0].email,
            tel: onlyNumber(data.imovel.proprietarios[0].telefone)
        })

        setImovel({
            ...imovel,
            id: nextIdGenerated,
            imgLargeId: nextIdGenerated,
            imgSmallId: nextIdGenerated,
            incluso: incluso,
            precoAluguel: tipoNegocio === 'aluguel' ? padronizarValorImoview(data.imovel.valor) : 0,
            precoCompra: tipoNegocio === 'compra' ? padronizarValorImoview(data.imovel.valor) : 0,
            precoCondominio: padronizarValorImoview(data.imovel.valorcondominio),
            precoIPTU: padronizarValorImoview(data.imovel.valoriptu),
            descImovel: data.imovel.descricao + '\r\n' + data.imovel.anotacoes,
            coordenadasLat: data.imovel.latitude,
            coordenadasLng: data.imovel.longitude,
            metragemConstrucao: padronizarValorImoview(data.imovel.areaprincipal),
            metragemTerreno: padronizarValorImoview(data.imovel.arealote),
            aceitaFinanciamento: data.imovel.aceitafinanciamento,
            aceitaPermuta: data.imovel.aceitapermuta,
            qtdQuartos: parseInt(data.imovel.numeroquartos),
            qtdVagas: parseInt(data.imovel.numerovagas),
            qtdSuites: parseInt(data.imovel.numerosuites),
            qtdBanheiros: parseInt(data.imovel.numerobanhos),
            captadorId: captadorId.toString(),
            cep: onlyNumber(data.imovel.cep),
            endereco: data.imovel.endereco,
            numero: data.imovel.numero,
            complemento: data.imovel.complemento,
            bairro: data.imovel.bairro,
            cidade: data.imovel.cidade,
            estado: data.imovel.estado,
            tipoImovel: tipoImovel.toString(),
            disabled: data.imovel.situacao === 'Desativado' ? true : false,
            importadoIdImoview: imovelIdToGet
        })

        // inicializa fullimport
        // if (idImoview) {
        let imgs = [data.imovel.urlfotoprincipal]

        data.imovel.fotos.map(item => imgs.push(item.url))

        setImgImported([...imgs])

        // passa somente a miniatura pra guardar somente ela e comprimir
        handleDropZone(data.imovel.urlfotoprincipal, 0, true)

        // envia todas as imagens pro dropzone transformar em blob
        imgs.map(item => handleDropZone(item, data.imovel.fotos.length))
        // }
    }

    const [avoidChangeDataOnLoading, setAvoidChangeDataOnLoading] = useState(false)

    useEffect(() => {

        if (avoidChangeDataOnLoading) return

        let seguro = parseInt(Number(imovel.precoAluguel) * 0.08)

        let aluguel = Number(imovel.precoAluguel) + Number(imovel.precoIPTU) + seguro + Number(imovel.precoTaxaServico)

        if (condominioSelected) {
            aluguel = aluguel + (condominioSelected.precoCondominio || 0)
        }

        let compra = Number(imovel.precoCompra)

        let categoriaPreco

        switch (true) {
            case (compra >= 1200000):
                categoriaPreco = 5
                break
            case (compra > 800000):
                categoriaPreco = 4
                break
            case (compra > 500000):
                categoriaPreco = 3
                break
            case (compra > 220000):
                categoriaPreco = 2
                break
            case (compra <= 220000):
                categoriaPreco = 1
                break
            default: categoriaPreco = 1
        }

        let getTipoNegocio

        switch (true) {
            case (tipoNegocio.aluguel && tipoNegocio.compra):
                getTipoNegocio = 'ambos'
                break

            case (tipoNegocio.aluguel):
                getTipoNegocio = 'aluguel'
                break

            case (tipoNegocio.compra):
                getTipoNegocio = 'compra'
                break

            default: getTipoNegocio = 'none'
        }

        // se o checkbox de tipo de negocio não estiver marcado, zera o valor
        if (getTipoNegocio === 'aluguel') { compra = 0 }
        if (getTipoNegocio === 'compra') { aluguel = 0 }

        if (getTipoNegocio === 'none') {
            compra = 0
            aluguel = 0
        }

        setImovel({
            ...imovel,
            precoTotalAluguel: parseInt(aluguel),
            precoTotalCompra: parseInt(compra),
            categoriaPreco,
            tipoNegocio: getTipoNegocio,
            // se o checkbox de tipo de negocio não estiver marcado, zera o valor
            precoAluguel: aluguel === 0 ? 0 : imovel.precoAluguel,
            precoCompra: compra === 0 ? 0 : imovel.precoCompra,
            precoSeguroIncendio: seguro
        })

    }, [imovel.precoAluguel, imovel.precoCompra, imovel.precoIPTU, imovel.precoSeguroIncendio, tipoNegocio])

    const [loading, setLoading] = useState(false)
    const [backdropLoading, setBackdropLoading] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)

    const [automaticSubmit, setAutomaticSubmit] = useState(false)

    useEffect(async () => {
        if (automaticSubmit === true) {

            await sleep(200)
            handleSubmit()
        }
    }, [automaticSubmit])

    async function handleSubmit(e) {

        if (e) { e.preventDefault() }

        setLoading(true)

        var method

        if (idJB) { method = 'update' } else { method = 'create' }

        var idImovelToSentDB

        if (method === 'create') { idImovelToSentDB = await getNextIdImovel() }
        else { idImovelToSentDB = idJB }

        var dataImovel = JSON.parse(JSON.stringify(imovel))

        dataImovel.id = idImovelToSentDB
        dataImovel.imgLargeId = idImovelToSentDB
        dataImovel.imgSmallId = idImovelToSentDB
        dataImovel.cep = dataImovel.cep.toString()
        dataImovel.numero = dataImovel.numero.toString()
        dataImovel.ownerId = Number(dataImovel.ownerId)
        dataImovel.metragemTerreno = Number(dataImovel.metragemTerreno)

        let urls = await uploadS3()

        // guarda o primeiro item e remove o primeiro item
        let small = urls.shift()

        let larges = { id: idImovelToSentDB }

        const promisseGetImages = urls.map(async item => {
            // encontra o próximo para gerar o img1, img2
            // o primeiro item do array era o imgSmall - img0 foi pro small
            let nextImg = item.img

            larges[nextImg] = item.url.toString()
        })

        await Promise.all(promisseGetImages)

        let smalls = {
            id: idImovelToSentDB,
            img: small.url.toString()
        }

        if (dataImovel.tipoImovel === 0) {
            // return alert('Selecione um tipo de imóvel')
        }

        if (ownerToBeCreated.nome !== '' && method === 'create') {
            let id = await insertProprietario(true)
            dataImovel.ownerId = id
        }

        try {

            delete dataImovel.imgSmall
            delete dataImovel.imgLarge
            delete dataImovel.owners
            delete dataImovel.persistData

            // verifica tem algum problema com o ownerId para veitar erro 500
            if (dataImovel.ownerId === '' || dataImovel.ownerId === 0) {

                return Swal.fire({
                    title: 'Verifique o prorpetário',
                    text: `Verifique o prorpetário, o código de proprietário ${dataImovel.ownerId} é inválido`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if (method === 'create') {

                await Promise.all([
                    api.post('/imglarges', larges),
                    api.post('/imgsmalls', smalls)
                ])

                await api.post('/publicproducts', dataImovel)
            }

            if (method === 'update') {

                await Promise.all([
                    api.put(`/imglarges/${idJB}`, larges),
                    api.put(`/imgsmalls/${idJB}`, smalls)
                ])

                await api.put(`/publicproducts/${idJB}`, dataImovel)
            }


            if (idImoview) {

                localStorage.removeItem('persistData')
                window.location.pathname = '/desktop/dash/importImoview/' + (Number(idImoview) + 1)

            } else {

                Swal.fire({
                    title: 'Sucesso',
                    text: `Imóvel Cod. ${idJB ? idJB : idImovelToSentDB} ${method === 'create' ? 'cadastrado' : 'atualizado'} com sucesso`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                })

                    .then(() => {
                        localStorage.removeItem('persistData')
                        window.location.pathname = `/i/${idJB}`
                    })

            }
        }

        catch (err) {

            console.error(err)

            if (idImoview) {
                localStorage.removeItem('persistData')
                window.location.pathname = '/desktop/dash/importImoview/' + (Number(idImoview) + 1)
            }

            setLoading(false)

            Swal.fire({
                title: 'Erro ao cadastrar',
                text: 'Erro: ' + err,
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

        setLoading(false)

    }

    function compressFilesFromDiscToUpload(values, totalImgs, index) {

        let files = [...values]

        files.forEach((item) => {

            // pega o tipo do arquivo
            let extension = item.type

            // verifica se é imagem, se não for, pula e vai pro próximo
            if (!extension.includes('image')) { return }


            // TODO
            // usar esse array para verificar se o item já foi adicionado pelo nome do arquivo
            imgNames.push(item.name)

            Resizer.imageFileResizer(
                item, // the file from input
                1000, // width
                1000, // height
                "JPEG", // compress format WEBP, JPEG, PNG
                50, // quality
                0, // rotation
                (uri) => {

                    imgLarges.push({ img: uri, index: '' })
                    forceUpdate()
                },
                "base64" // blob or base64 default base64
            )
        })
    }

    function changeSwitchInclusos(item) {

        if (condominioSelected.incluso.includes(item)) {
            return toast.error('Para remover esse item, desabilite no condomínio', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }

        let target = document.getElementById(item)

        if (imovel.incluso.includes(item)) {

            let incluso = imovel.incluso.filter(i => i !== item)

            setImovel({ ...imovel, incluso: incluso })

            let add = [...inclusos, { id: target.id, text: target.firstChild.nodeValue }]

            add.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            })

            setInclusos(add)

        } else {

            let incluso = [...imovel.incluso]

            incluso.push(item)

            setImovel({ ...imovel, incluso })

            let rest = inclusos.filter(item => item.id !== target.id)

            rest.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            })

            setInclusos(rest)

        }

    }

    function handleRemoveIncluso() {
        let rest = inclusos.filter(item => item.id !== inclusoSelected.id)

        setInclusos(rest)
        setInclusoSelected('')
    }

    function handleAddIncluso() {

        // se já tiver nos itens inclusos do condominio não remove
        if (condominioSelected.incluso.includes(inclusoSelected.id)) { handleRemoveIncluso(); return }

        let incluso = [...imovel.incluso, inclusoSelected.id]

        setImovel({ ...imovel, incluso: incluso })

        // remove o item que foi adicionado do autocomplete
        handleRemoveIncluso()
    }

    function handleEnterKeyAutocompleteIncluso(e) {

        if (e.key === 'Enter') {

            if (inclusoSelected.id) {

                handleAddIncluso()

            } else {

                // se precionar o enter e não tiver o id salvo, apaga o campo do input
                // para impedir que não entre um valor inválido
                // obrigando o usuário a selecionar um dos itens
                setInclusoSelected({ id: '', text: '' })
            }
        }
    }

    function openEditorImg() {

        var windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=800,height=1000"
        var editorUrl = 'https://www.nvidia.com/research/inpainting/index.html'

        window.open(editorUrl, "NVDIA_EDITOR_WINDOW", windowFeatures)

    }

    async function handleClickImgImported(url) {

        // copy URL to clipboard
        await navigator.clipboard.writeText(url)

        openEditorImg()
    }

    const [dialogOpen, setDialogOpen] = useState(false)

    async function insertProprietario(fromBotImoview) {

        try {

            ownerToBeCreated.tel = ownerToBeCreated.tel.toString()

            let { data } = await api.post('/owners', ownerToBeCreated)

            let allOwners = [...owners]

            allOwners.push(ownerToBeCreated)

            setOwners(allOwners)

            setOwnerSelected({ ...ownerSelected, ...ownerToBeCreated })

            // se não foi do bot do imoview, guarda o id
            if (!fromBotImoview) {
                setImovel({ ...imovel, ownerId: data.id })
            }

            setDialogOpen(false)

            cleanCreateOwnersForm()

            return data.id
        }

        catch (err) {
            await api.post('/errors', err)
            console.error(err)
            // alert(err)

            localStorage.removeItem('persistData')
            window.location.pathname = '/desktop/dash/importImoview/' + (Number(idImoview) + 1)
        }

    }

    Array.prototype.moveImgLarges = (from, to) => {
        imgLarges.splice(to, 0, imgLarges.splice(from, 1)[0])
        return this;
    }

    Array.prototype.moveImgSmalls = (from, to) => {
        imgSmalls.splice(to, 0, imgSmalls.splice(from, 1)[0])
        return this;
    }

    function onDragEnd(result) {

        // se jogou fora da caixa, exclui
        if (result.destination === null) {
            imgLarges.splice(result.source.index, 1)
            imgSmalls.splice(result.source.index, 1)
            return
        }

        // move(from, to)
        imgLarges.moveImgLarges(result.source.index, result.destination.index)
        imgSmalls.moveImgSmalls(result.source.index, result.destination.index)

        // atualiza a imagem pequena
        setImovel({ ...imovel, descImovel: document.getElementById('descImovel').value })
    }

    // style drag and drop img upload

    const grid = 4

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 ${grid}px 0 0`,

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : '#f2f2f3',

        // styles we need to apply on draggables
        ...draggableStyle,
    })

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '#f2f2f3',
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    })

    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    function resizedataURL(datas, wantedWidth, wantedHeight) {
        return new Promise(async function (resolve, reject) {

            // We create an image to receive the Data URI
            var img = document.createElement('img')

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                var canvas = document.createElement('canvas')
                var ctx = canvas.getContext('2d')

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth
                canvas.height = wantedHeight

                // We resize the image with the canvas method drawImage()
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

                var dataURI = canvas.toDataURL()

                // This is the return of the Promise
                resolve(dataURI)
            }

            // We put the Data URI in the image's src attribute
            img.src = datas

        })
    }// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50)

    function handleDropZone(event = '', totalImgs, isImgSmall, index) {

        let fileToUploadFromDisc = event?.target?.files || []

        // verifica se o arquivo veio do disco rigido do usuário
        // então encaminha para a compressão
        if (fileToUploadFromDisc.length > 0) {

            let files = [...event.target.files]
            return compressFilesFromDiscToUpload(files)

        }


        let fileURL = ''

        // verifica se o que chegou foi uma imagem para ser importada
        // foi arrastada da caixa de import do imoview
        if (event?.dataTransfer?.getData("text").includes('img-imported')) {

            var data = event.dataTransfer.getData("text")

            var target = event.target.appendChild(document.getElementById(data))

            fileURL = event.target.appendChild(document.getElementById(data)).src

            target.remove()

            let toRemove = imgImported.filter(item => item !== data)
            setImgImported(toRemove)

        }

        // verifica se o que chegou foi uma URL vinda de fora do navegador
        // se for, coloca a URL para ser baixada
        if (event?.dataTransfer?.getData("URL").includes('http')) {

            // console.log(event.dataTransfer.getData("URL"))

            fileURL = event.dataTransfer.getData("URL")

        }

        if (typeof event === 'string') {

            if (!event.includes('http')) return

            fileURL = event

            // encontra o componente e exclui ele
            document.querySelector(`[src="${fileURL}"]`)?.remove()

            let importContainer = document.querySelector('.box-img .imgsSelected')

            if (importContainer.childElementCount === 0) {
                document.querySelector('.box-imported').style.display = 'none'
            }

        }

        // se chegou até aqui sem URL o arquivo é invalido para upload
        if (fileURL === '') return

        // baixa a imagem através do link e convert em blob (base64)
        var request = new XMLHttpRequest()
        request.open('GET', Config.NO_CORS_WORKER + fileURL, true)
        request.responseType = 'blob'
        request.onload = function () {
            var reader = new FileReader()
            reader.readAsDataURL(request.response)
            reader.onload = async function (e) {

                if (isImgSmall) {

                    imgSmalls.push(e.target.result)
                    // se for a miniatura para aqui
                    return

                } else {
                    imgLargesInOrder.push({ img: e.target.result, index: index })
                    forceUpdate()
                }

                if (contadorImgImportadas === totalImgs - 1) {

                    // ordena as imagens pelo index
                    imgLargesInOrder = imgLargesInOrder.sort((a, b) => {
                        a = a.index
                        b = b.index

                        return a - b
                    })

                    setImgLarges(imgLargesInOrder)
                    forceUpdate()
                    
                    setBackdropLoading(false)

                    if (idImoview) { setAutomaticSubmit(true) }

                } else {
                    contadorImgImportadas++
                }
            }
        }
        request.send()
    }

    useEffect(() => {
        if (!idJB) {
            localStorage.setItem('persistData', JSON.stringify(imovel))
        }
    }, [imovel])

    //
    // ---------- MOSTRA FOTO GRANDE AO PASSAR MOUSE
    //

    var divName = 'show-img-hover'; // div that is to follow the mouse (must be position:absolute)
    var offX = -250;          // X offset from mouse position
    var offY = -580;          // Y offset from mouse position

    function mouseX(evt) { if (!evt) evt = window.event; if (evt.pageX) return evt.pageX; else if (evt.clientX) return evt.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft); else return 0; }
    function mouseY(evt) { if (!evt) evt = window.event; if (evt.pageY) return evt.pageY; else if (evt.clientY) return evt.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop); else return 0; }

    function follow(evt) {
        var obj = document.getElementById(divName).style;
        obj.left = (parseInt(mouseX(evt)) + offX) + 'px';
        obj.top = (parseInt(mouseY(evt)) + offY) + 'px';
    }

    document.onmousemove = follow;

    return (
        <Container>

            <div id="show-img-hover"></div>

            <Backdrop className={classes.backdrop} open={backdropLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div id="title-top">
                <a href="/">
                    <Icon id="icon-back" style={{ color: '#8E5916' }}>arrow_back</Icon>
                </a>
                <h1>Cadastro de Imóvel</h1>
            </div>

            <br />

            <form onSubmit={handleSubmit}>

                <div style={{ display: 'flex' }}>
                    <Autocomplete
                        value={ownerSelected}
                        style={{ marginRight: 15, width: 300 }}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.inputValue) {
                                setDialogOpen(true)
                                setOwnerToBeCreated({
                                    ...ownerToBeCreated,
                                    nome: newValue.inputValue
                                })
                            } else {

                                let ownerFinded = owners.find(item => item.id === newValue.id)

                                setOwnerSelected(newValue)
                                setImovel({ ...imovel, ownerId: ownerFinded.id })
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params)

                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    nome: `Adicionar "${params.inputValue}"`,
                                })
                            }

                            return filtered
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="free-solo-proprietario"
                        options={owners}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            // if (typeof option === 'string') {
                            // return option
                            // }
                            // Add "xxx" option created dynamically
                            // if (option.inputValue) {
                            // return option.inputValue
                            // }
                            // Regular option
                            return option.nome
                        }}
                        renderOption={(option) => option.nome}
                        // freeSolo remove a seta para baixo
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} label="Proprietário" variant="outlined" required />
                        )}
                    // disabled={idJB}
                    />

                    <Icon
                        onClick={() => setDialogOpen(true)}
                        className={ownerSelected ? 'disabled' : 'sumOne'}
                    >
                        add_circle
                    </Icon>

                    <Icon
                        onClick={() => setDialogOpen(true)}
                        className={!ownerSelected ? 'disabled' : 'sumOne'}
                    >
                        visibility
                    </Icon>

                    {/* <Autocomplete
                    value={condominioSelected}
                    style={{ marginRight: 15, width: 300 }}
                    onChange={(event, newValue) => {

                        let id

                        if (event.target.id) {
                            id = condominios[event.target.id.split('-').slice(-1)].id
                        } else {
                            id = ''
                        }

                        if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setCondominioSelected({
                                nomeCondominio: newValue.inputValue,
                            })
                            setImovel({ ...imovel, condominioId: id, imgCondominioId: id, nomeCondominio: newValue })
                        } else {
                            setCondominioSelected(newValue)
                            setImovel({ ...imovel, condominioId: id, imgCondominioId: id, nomeCondominio: newValue.nomeCondominio })
                        }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-condominio"
                    options={condominios}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        // if (typeof option === 'string') {
                        //     return option
                        // }
                        // // Add "xxx" option created dynamically
                        // if (option.inputValue) {
                        //     return option.inputValue
                        // }
                        // Regular option
                        return option.nomeCondominio
                    }}
                    renderOption={(option) => option.nomeCondominio}
                    // remova a seta para baixo
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label="Condomínio" variant="outlined" />
                    )}
                    className="disabled"
                /> */}

                    <Select
                        id="captador"
                        variant="outlined"
                        value={loadingCep ? 'carregando...' : imovel.captadorId}
                        style={{ width: 120 }}
                        onChange={e => setImovel({ ...imovel, captadorId: e.target.value })}
                        required
                        disabled={loadingCep}
                    >
                        <MenuItem value={0} disabled>Captador</MenuItem>

                        {captadores.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}

                    </Select>

                </div>

                <br />

                <Select
                    id="tipoImovel"
                    variant="outlined"
                    value={imovel.tipoImovel}
                    onChange={e => setImovel({ ...imovel, tipoImovel: e.target.value, qtdBanheiros: 0, qtdQuartos: 0 })}
                    required
                >
                    <MenuItem value={'0'} disabled>Tipo de Imóvel</MenuItem>
                    <MenuItem value='Apartamento'>Apartamento</MenuItem>
                    <MenuItem value='Casa'>Casa</MenuItem>
                    <MenuItem value='Casa Condomínio'>Casa Condomínio</MenuItem>
                    <MenuItem value='Terreno'>Terreno</MenuItem>
                    <MenuItem value='Lote Condomínio'>Lote Condomínio</MenuItem>
                    <MenuItem value='Galpão'>Galpão</MenuItem>
                    <MenuItem value='Sala Comercial'>Sala Comercial</MenuItem>
                    <MenuItem value='Chácara'>Chácara</MenuItem>
                </Select>

                <Checkbox checked={imovel.aceitaPermuta} onChange={() => setImovel({ ...imovel, aceitaPermuta: !imovel.aceitaPermuta })} />
                <span className="checkbox-text" onClick={() => setImovel({ ...imovel, aceitaPermuta: !imovel.aceitaPermuta })}>Aceita Permuta</span>

                <Checkbox checked={imovel.aceitaFinanciamento} onChange={() => setImovel({ ...imovel, aceitaFinanciamento: !imovel.aceitaFinanciamento })} />
                <span className="checkbox-text" onClick={() => setImovel({ ...imovel, aceitaFinanciamento: !imovel.aceitaFinanciamento })}>Aceita Financiamento</span>

                <TextField
                    id="importadoIdImoview"
                    label={idJB ? 'Cod. do Imóvel' : 'Encontrar pelo Cod.'}
                    type="text"
                    variant="outlined"
                    style={{ width: 150, marginLeft: 55 }}
                    value={imovel.importadoIdImoview}
                    onChange={e => setImovel({ ...imovel, importadoIdImoview: onlyNumber(e.target.value) })}
                    error={errorInput.includes('importadoIdImoview')}
                    onKeyPress={e => e.key === 'Enter' ? consultarImoview() : ''}
                    disabled={alreadyImported}
                    InputLabelProps={{ shrink: true }}
                    className={idJB ? 'disabled' : ''}
                />

                <Button
                    variant="contained"
                    color={loading ? 'secondary' : 'primary'}
                    onClick={() => consultarImoview()}
                    disabled={loading || imovel.importadoIdImoview === '' || imovel.importadoIdImoview === null || alreadyImported}
                    startIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : ''}
                    className={idJB ? 'disabled' : ''}
                >
                    {loading ? '...' : 'OK'}
                </Button>

                <div id="last-imovel-button" onClick={() => openLastImovel()}>
                    <ArchiveIcon />
                </div>

                <div id="next-imovel-button" onClick={() => openNextImovel()} className={window.location.pathname.includes('importImoview') ? '' : 'disabled'}>
                    <Icon>double_arrow</Icon>
                </div>

                <br />
                <br />

                <div className="box-upload">

                    {/* <DropZone2
                TODO: esse dropzone aceita link, mas chega tudo bagunçado
                    // getUploadParams={getUploadParams}
                    onSubmit={(files, allFiles) => { console.log(files.map(f => f.meta)); allFiles.forEach(f => f.remove()) }}
                    onChangeStatus={({ meta }, status) => {console.log(status, meta)}}
                    styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                /> */}

                    <span>Imagens que serão adicionadas ao imóvel</span>

                    <div className="box-img" onDrop={e => handleDropZone(e)} onDragOver={e => e.preventDefault()}>

                        <div className="align-input-button">

                            <div style={{ marginTop: 30 }}>
                                <input type="file" id="imgupload" style={{ display: 'none' }} accept="image/png, image/jpeg" onChange={e => handleDropZone(e)} multiple />
                                <label for='imgupload' style={{ cursor: 'pointer' }}>
                                    <Icon style={{ fontSize: 32, marginRight: 10 }}>cloud_upload</Icon><span>Clique ou solte as imagens para carrega-las</span>
                                </label>
                            </div>

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => openEditorImg()}
                                className="disabled"
                                startIcon={(
                                    <Icon
                                        style={{ color: '#8E5916', fontSize: 25 }}>brush
                                    </Icon>
                                )}>Remover marca d'gua
                            </Button>
                        </div>

                        <div className={imgLarges.length > 0 ? 'imgsSelected' : 'disabled'}>

                            <br />
                            <h2>Total de imagens adicionadas: {imgLarges.length}</h2>
                            <br />

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {
                                                imgLarges.map((item, index) =>
                                                    <Draggable key={index} draggableId={"img" + Number(index + 1)} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                // style={{ width: 90, height: 90 }}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <img
                                                                    alt="Miniaturas adicionadas"
                                                                    src={item.img}
                                                                    id={"img" + Number(index + 1)}
                                                                    onMouseOver={() => {
                                                                        document.getElementById('show-img-hover').style.display = 'block'
                                                                        document.getElementById('show-img-hover').style.backgroundImage = `url(${item.img})`
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        document.getElementById('show-img-hover').style.display = 'none'
                                                                    }}
                                                                    style={{ objectFit: 'cover', width: 120, height: 120 }}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>

                    </div>

                </div>

                <div className={imgImported.length > 0 ? 'box-imported' : 'disabled'}>

                    <br />
                    <br />

                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <span style={{ paddingTop: 15 }}>Imagens prontas para serem importadas</span>

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => imgImported.map(item => handleDropZone(item))}
                            startIcon={(
                                <Icon
                                    style={{ color: '#8E5916', fontSize: 25 }}>file_upload
                                </Icon>
                            )}>Importar todas
                        </Button>
                    </div>

                    <div className="box-img">

                        <div className="imgsSelected">
                            {
                                imgImported.map((item, index) =>
                                    <img
                                        key={index}
                                        id={'img-imported-' + index}
                                        className="hide-if-dragged"
                                        alt="Miniaturas importadas"
                                        src={item}
                                        onClick={e => handleClickImgImported(e.target.src)}
                                        onError={e => e.target.style.display = 'none'}
                                        width={90}
                                        draggable={true}
                                        onDragStart={e => e.dataTransfer.setData("text", e.target.id)}
                                    />
                                )
                            }
                        </div>

                    </div>

                </div>

                <br />

                <TextField
                    id="cep"
                    label="CEP"
                    type="text"
                    variant="outlined"
                    style={{ width: '15%' }}
                    value={imovel.cep}
                    disabled={loadingCep}
                    onChange={e => handleChangeCep(onlyNumber(e.target.value.toString()))}
                    error={errorInput.includes('cep')}
                    required
                />

                <TextField
                    id="endereco"
                    label="Endereço"
                    type="text"
                    variant="outlined"
                    style={{ width: '65%' }}
                    value={loadingCep ? 'carregando...' : imovel.endereco}
                    disabled={loadingCep}
                    onChange={e => setImovel({ ...imovel, endereco: e.target.value })}
                    required
                />

                <TextField
                    id="numero"
                    label="Número"
                    type="text"
                    variant="outlined"
                    style={{ width: '20%' }}
                    value={imovel.numero}
                    onChange={e => setImovel({ ...imovel, numero: onlyNumber(e.target.value) })}
                    required
                />

                <br />
                <br />

                <TextField
                    id="bairro"
                    label="Condomínio - Bairro"
                    type="text"
                    variant="outlined"
                    style={{ width: "35%" }}
                    value={loadingCep ? 'carregando...' : imovel.bairro}
                    disabled={loadingCep}
                    onChange={e => setImovel({ ...imovel, bairro: e.target.value })}
                    required
                />

                <TextField
                    id="complemento"
                    label="Complemento"
                    type="text"
                    variant="outlined"
                    style={{ width: '25%' }}
                    value={loadingCep ? 'carregando...' : imovel.complemento}
                    disabled={loadingCep}
                    onChange={e => setImovel({ ...imovel, complemento: e.target.value })}
                />

                <Select
                    id="cidades"
                    variant="outlined"
                    value={loadingCep ? 'carregando...' : imovel.cidade}
                    disabled={loadingCep}
                    style={{ width: '25%' }}
                    onChange={e => setImovel({ ...imovel, cidade: e.target.value })}
                    required
                >
                    <MenuItem value={0} disabled>Selecione a cidade</MenuItem>

                    {cidades.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}

                </Select>

                <Select
                    id="estado"
                    variant="outlined"
                    value={loadingCep ? 'carregando...' : imovel.estado}
                    disabled={loadingCep}
                    onChange={e => setImovel({ ...imovel, estado: e.target.value })}
                    required
                >
                    <MenuItem value={0} disabled>Selecione o estado</MenuItem>

                    {estados.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}

                </Select>

                <br />
                <br />

                <TextField
                    id="metragemTerreno"
                    label="M2 Terreno"
                    type="text"
                    variant="outlined"
                    value={imovel.metragemTerreno}
                    style={{ width: 150 }}
                    InputProps={{ endAdornment: (<InputAdornment position="end">m²</InputAdornment>) }}
                    onChange={e => setImovel({ ...imovel, metragemTerreno: onlyNumber(e.target.value) })}
                    required={imovel.metragemConstrucao === 0 && imovel.metragemTerreno === 0}
                    error={imovel.metragemConstrucao === 0 && imovel.metragemTerreno === 0}
                />

                <TextField
                    id="metragemConstrucao"
                    label="M2 Construção"
                    type="text"
                    variant="outlined"
                    value={imovel.metragemConstrucao}
                    style={{ width: 150 }}
                    InputProps={{ endAdornment: (<InputAdornment position="end">m²</InputAdornment>) }}
                    onChange={e => setImovel({ ...imovel, metragemConstrucao: onlyNumber(e.target.value) })}
                    required={imovel.metragemConstrucao === 0 && imovel.metragemTerreno === 0}
                    error={imovel.metragemConstrucao === 0 && imovel.metragemTerreno === 0}
                />

                <TextField
                    id="qtdVagas"
                    label="Vagas"
                    type="number"
                    variant="outlined"
                    value={imovel.qtdVagas}
                    style={{ width: 100 }}
                    onChange={e => setImovel({ ...imovel, qtdVagas: onlyNumber(e.target.value) })}
                />

                <Icon
                    className="sumOne"
                    onClick={() =>
                        setImovel({ ...imovel, qtdVagas: imovel.qtdVagas + 1 })}>
                    add_circle
                </Icon>

                <TextField
                    id="qtdQuartos"
                    label="Quartos"
                    type="number"
                    variant="outlined"
                    value={imovel.qtdQuartos}
                    style={{ width: 100 }}
                    onChange={e => setImovel({ ...imovel, qtdQuartos: onlyNumber(e.target.value) })}
                    required={imovel.tipoImovel !== 'Terreno'}
                    error={imovel.qtdQuartos === 0 && imovel.tipoImovel !== 'Terreno'}
                    InputLabelProps={{ shrink: true }}
                />

                <Icon
                    className="sumOne"
                    onClick={() =>
                        setImovel({ ...imovel, qtdQuartos: imovel.qtdQuartos + 1 })}>
                    add_circle
                </Icon>

                <TextField
                    id="qtdSuites"
                    label="Suítes"
                    type="number"
                    variant="outlined"
                    value={imovel.qtdSuites}
                    style={{ width: 100 }}
                    onChange={e => setImovel({ ...imovel, qtdSuites: onlyNumber(e.target.value) })}
                />

                <Icon
                    className="sumOne"
                    onClick={() =>
                        setImovel({ ...imovel, qtdSuites: imovel.qtdSuites + 1 })}>
                    add_circle
                </Icon>

                <TextField
                    id="qtdBanheiros"
                    label="Banheiros"
                    type="number"
                    variant="outlined"
                    value={imovel.qtdBanheiros}
                    style={{ width: 100 }}
                    onChange={e => setImovel({ ...imovel, qtdBanheiros: onlyNumber(e.target.value) })}
                    error={imovel.qtdBanheiros === 0 && imovel.tipoImovel !== 'Terreno'}
                    InputLabelProps={{ shrink: true }}
                    required={imovel.tipoImovel !== 'Terreno'}
                />

                <Icon
                    className="sumOne"
                    onClick={() =>
                        setImovel({ ...imovel, qtdBanheiros: imovel.qtdBanheiros + 1 })}>
                    add_circle
                </Icon>

                <br />
                <br />
                <br />

                <TextField
                    id="precoAluguel"
                    label="Valor do Aluguel"
                    type="text"
                    variant="outlined"
                    style={{ width: 300 }}
                    value={imovel.precoAluguel}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                        endAdornment: (<InputAdornment position="end">/mês</InputAdornment>)
                    }}
                    name="precoAluguel"
                    onChange={e => handleChangeValor(e)}
                    required={(imovel.precoAluguel === 0 && imovel.precoCompra === 0) || tipoNegocio.aluguel}
                    error={imovel.precoAluguel === 0 && tipoNegocio.aluguel}
                />

                <TextField
                    id="precoCompra"
                    label="Valor de Venda"
                    type="text"
                    variant="outlined"
                    style={{ width: 300 }}
                    value={imovel.precoCompra}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                    }}
                    name="precoCompra"
                    onChange={e => handleChangeValor(e)}
                    required={(imovel.precoAluguel === 0 && imovel.precoCompra === 0) || tipoNegocio.compra}
                    error={imovel.precoCompra === 0 && tipoNegocio.compra}
                />

                <Checkbox checked={tipoNegocio.aluguel} onChange={e => changeTipoNegocio(e)} name="aluguel" required={(imovel.metragemTerreno + imovel.metragemConstrucao === 0)} />
                <span className="checkbox-text">Aluguel</span>

                <Checkbox checked={tipoNegocio.compra} onChange={e => changeTipoNegocio(e)} name="compra" required={(imovel.metragemTerreno + imovel.metragemConstrucao === 0)} />
                <span className="checkbox-text">Compra</span>

                <br />
                <br />

                <TextField
                    id="precoIPTU"
                    label="IPTU"
                    type="text"
                    variant="outlined"
                    value={imovel.precoIPTU}
                    style={{ width: 200 }}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                        endAdornment: (<InputAdornment position="end">/mês</InputAdornment>)
                    }}
                    onChange={e => setImovel({ ...imovel, precoIPTU: onlyNumber(e.target.value) })}
                    required
                />

                <TextField
                    id="precoSeguroIncendio"
                    label="Seguro Incêndio"
                    type="text"
                    variant="outlined"
                    value={tipoNegocio.aluguel ? imovel.precoSeguroIncendio : '-----'}
                    style={{ width: 200 }}
                    disabled
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                        endAdornment: (<InputAdornment position="end">/mês</InputAdornment>)
                    }}
                    onChange={e => setImovel({ ...imovel, precoSeguroIncendio: onlyNumber(e.target.value) })}
                />

                {/* <TextField
                id="precoCondominio"
                label="Valor do Condomínio"
                type="text"
                variant="outlined"
                value={condominioSelected === null ? '-----' : condominioSelected.precoCondominio ?? 0}
                style={{ width: 200 }}
                disabled
                InputProps={{
                    startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                }}
            /> */}

                <TextField
                    id="precoCondominio"
                    label="Valor do Condomínio"
                    type="text"
                    variant="outlined"
                    value={imovel.precoCondominio}
                    style={{ width: 200 }}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                        endAdornment: (<InputAdornment position="end">/mês</InputAdornment>)
                    }}
                    onChange={e => setImovel({ ...imovel, precoCondominio: onlyNumber(e.target.value) })}
                    required
                />

                <TextField
                    id="precoTaxaServico"
                    label="Taxa de Serviço"
                    type="text"
                    variant="outlined"
                    value={tipoNegocio.aluguel ? imovel.precoTaxaServico : '-----'}
                    style={{ width: 200 }}
                    disabled
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                    }}
                />

                <br />
                <br />

                <TextField
                    id="precoTotalAluguel"
                    label="Cálculo do Valor Total do Aluguel"
                    disabled
                    type="text"
                    variant="filled"
                    style={{ width: '33.3%' }}
                    value={tipoNegocio.aluguel ? imovel.precoTotalAluguel : '-----'}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                    }}
                />

                <TextField
                    id="precoTotalCompra"
                    label="Cálculo do Valor total de Venda"
                    disabled
                    type="text"
                    variant="filled"
                    style={{ width: '33.3%' }}
                    value={tipoNegocio.compra ? imovel.precoTotalCompra : '-----'}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">R$ </InputAdornment>),
                    }}
                />

                <TextField
                    id="categoriaPreco"
                    label="Categoria de Preço"
                    disabled
                    type="text"
                    variant="filled"
                    style={{ width: '33.3%' }}
                    value={imovel.categoriaPreco}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">Nível: </InputAdornment>),
                    }}
                />

                <br />
                <br />

                {/* <TextField
                    id="title"
                    label="Titulo do Imóvel"
                    type="text"
                    variant="filled"
                    value={imovel.title}
                    style={{ width: '70%' }}
                    disabled
                />

                <br />
                <br /> */}

                <TextField
                    fullWidth
                    id="descImovel"
                    label="Descrição do imóvel"
                    type="text"
                    variant="outlined"
                    value={imovel.descImovel}
                    multiline
                    maxRows={20}
                    minRows={20}
                    onChange={e => setImovel({ ...imovel, descImovel: e.target.value })}
                />

                <br />
                <br />

                <TextField
                    fullWidth
                    id="descProximidades"
                    label="Descrição da localização e proximidades do imóvel"
                    type="text"
                    variant="outlined"
                    multiline
                    value={condominioSelected.descProximidades === undefined || condominioSelected.descProximidades === '' ? imovel.descProximidades : `A descrição da proximidade está sincronizada com a do condominio.\n\n${condominioSelected.descProximidades}`}
                    disabled={imovel.nomeCondominio !== '' && condominios.descProximidades !== ''}
                    maxRows={6}
                    minRows={6}
                    onChange={e => setImovel({ ...imovel, descProximidades: e.target.value })}
                />

                <br />
                <br />

                <TextField
                    fullWidth
                    id="anotacaoInterna"
                    label="Anotações internas"
                    type="text"
                    variant="outlined"
                    multiline
                    value={imovel.anotacaoInterna}
                    maxRows={3}
                    minRows={3}
                    onChange={e => setImovel({ ...imovel, anotacaoInterna: e.target.value })}
                />

                <br />
                <br />

                <div id="incluso-autocomplete">

                    <Autocomplete
                        value={inclusoSelected}
                        style={{ marginRight: 15, width: 300 }}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setInclusoSelected({
                                    text: newValue.inputValue,
                                })
                            } else {
                                setInclusoSelected(newValue)
                            }
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-incluso"
                        options={inclusos}
                        getOptionLabel={(option) => option.text}
                        renderOption={(option) => option.text}
                        // remova a seta para baixo
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Adicionar Itens Inclusos"
                                variant="outlined"
                                onKeyPress={e => handleEnterKeyAutocompleteIncluso(e)}
                            />
                        )}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddIncluso()}
                        disabled={inclusoSelected === '' || inclusoSelected === null}
                        id="button-ok-inclusos"
                    >OK
                    </Button>

                </div>

                <br />

                <div id="incluso">

                    <Switch
                        checked={(imovel.incluso.includes('arcondicionado') || condominioSelected.incluso.includes('arcondicionado'))}
                        disabled={condominioSelected.incluso.includes('arcondicionado')}
                        onChange={e => changeSwitchInclusos('arcondicionado', e.target)} />
                    <span className="switch-text" id="arcondicionado">Ar-condicionado</span>

                    <Switch
                        checked={(imovel.incluso.includes('areaservico') || condominioSelected.incluso.includes('areaservico'))}
                        disabled={condominioSelected.incluso.includes('areaservico')}
                        onChange={() => changeSwitchInclusos('areaservico')} />
                    <span className="switch-text" id="areaservico">Área Serviço</span>

                    <Switch
                        checked={(imovel.incluso.includes('areaprivativa') || condominioSelected.incluso.includes('areaprivativa'))}
                        disabled={condominioSelected.incluso.includes('areaprivativa')}
                        onChange={() => changeSwitchInclusos('areaprivativa')} />
                    <span className="switch-text" id="areaprivativa">Área Privativa</span>

                    <Switch
                        checked={(imovel.incluso.includes('armariobanheiro') || condominioSelected.incluso.includes('armariobanheiro'))}
                        disabled={condominioSelected.incluso.includes('armariobanheiro')}
                        onChange={() => changeSwitchInclusos('armariobanheiro')} />
                    <span className="switch-text" id="armariobanheiro">Armário no Banheiro</span>

                    <Switch
                        checked={(imovel.incluso.includes('armariocozinha') || condominioSelected.incluso.includes('armariocozinha'))}
                        disabled={condominioSelected.incluso.includes('armariocozinha')}
                        onChange={() => changeSwitchInclusos('armariocozinha')} />
                    <span className="switch-text" id="armariocozinha">Armário na Cozinha</span>

                    <Switch
                        checked={(imovel.incluso.includes('armarioquarto') || condominioSelected.incluso.includes('armarioquarto'))}
                        disabled={condominioSelected.incluso.includes('armarioquarto')}
                        onChange={() => changeSwitchInclusos('armarioquarto')} />
                    <span className="switch-text" id="armarioquarto">Armário no Quarto</span>

                    <Switch
                        checked={(imovel.incluso.includes('box') || condominioSelected.incluso.includes('box'))}
                        disabled={condominioSelected.incluso.includes('box')}
                        onChange={() => changeSwitchInclusos('box')} />
                    <span className="switch-text" id="box">Box</span>

                    <Switch
                        checked={(imovel.incluso.includes('closet') || condominioSelected.incluso.includes('closet'))}
                        disabled={condominioSelected.incluso.includes('closet')}
                        onChange={() => changeSwitchInclusos('closet')} />
                    <span className="switch-text" id="closet">Closet</span>

                    <Switch
                        checked={(imovel.incluso.includes('dce') || condominioSelected.incluso.includes('dce'))}
                        disabled={condominioSelected.incluso.includes('dce')}
                        onChange={() => changeSwitchInclusos('dce')} />
                    <span className="switch-text" id="dce">DCE</span>

                    <Switch
                        checked={(imovel.incluso.includes('despensa') || condominioSelected.incluso.includes('despensa'))}
                        disabled={condominioSelected.incluso.includes('despensa')}
                        onChange={() => changeSwitchInclusos('despensa')} />
                    <span className="switch-text" id="despensa">Despensa</span>

                    <Switch
                        checked={(imovel.incluso.includes('escritorio') || condominioSelected.incluso.includes('escritorio'))}
                        disabled={condominioSelected.incluso.includes('escritorio')}
                        onChange={() => changeSwitchInclusos('escritorio')} />
                    <span className="switch-text" id="escritorio">Escritório</span>

                    <Switch
                        checked={(imovel.incluso.includes('lavabo') || condominioSelected.incluso.includes('lavabo'))}
                        disabled={condominioSelected.incluso.includes('lavabo')}
                        onChange={() => changeSwitchInclusos('lavabo')} />
                    <span className="switch-text" id="lavabo">Lavabo</span>

                    <Switch
                        checked={(imovel.incluso.includes('mobiliado') || condominioSelected.incluso.includes('mobiliado'))}
                        disabled={condominioSelected.incluso.includes('mobiliado')}
                        onChange={() => changeSwitchInclusos('mobiliado')} />
                    <span className="switch-text" id="mobiliado">Mobiliado</span>

                    <Switch
                        checked={(imovel.incluso.includes('rouparia') || condominioSelected.incluso.includes('rouparia'))}
                        disabled={condominioSelected.incluso.includes('rouparia')}
                        onChange={() => changeSwitchInclusos('rouparia')} />
                    <span className="switch-text" id="rouparia">Rouparia</span>

                    <Switch
                        checked={(imovel.incluso.includes('aguaindividual') || condominioSelected.incluso.includes('aguaindividual'))}
                        disabled={condominioSelected.incluso.includes('aguaindividual')}
                        onChange={() => changeSwitchInclusos('aguaindividual')} />
                    <span className="switch-text" id="aguaindividual">Água Individual</span>

                    <Switch
                        checked={(imovel.incluso.includes('alarme') || condominioSelected.incluso.includes('alarme'))}
                        disabled={condominioSelected.incluso.includes('alarme')}
                        onChange={() => changeSwitchInclusos('alarme')} />
                    <span className="switch-text" id="alarme">Alarme</span>

                    <Switch
                        checked={(imovel.incluso.includes('aquecedorgas') || condominioSelected.incluso.includes('aquecedorgas'))}
                        disabled={condominioSelected.incluso.includes('aquecedorgas')}
                        onChange={() => changeSwitchInclusos('aquecedorgas')} />
                    <span className="switch-text" id="aquecedorgas">Aquecedor a gás</span>

                    <Switch
                        checked={(imovel.incluso.includes('aquecedoreletrico') || condominioSelected.incluso.includes('aquecedoreletrico'))}
                        disabled={condominioSelected.incluso.includes('aquecedoreletrico')}
                        onChange={() => changeSwitchInclusos('aquecedoreletrico')} />
                    <span className="switch-text" id="aquecedoreletrico">Aquecedor Elétrico</span>

                    <Switch
                        checked={(imovel.incluso.includes('aquecedorsolar') || condominioSelected.incluso.includes('aquecedorsolar'))}
                        disabled={condominioSelected.incluso.includes('aquecedorsolar')}
                        onChange={() => changeSwitchInclusos('aquecedorsolar')} />
                    <span className="switch-text" id="aquecedorsolar">Aquecedor Solar</span>

                    <Switch
                        checked={(imovel.incluso.includes('boxdespejo') || condominioSelected.incluso.includes('boxdespejo'))}
                        disabled={condominioSelected.incluso.includes('boxdespejo')}
                        onChange={() => changeSwitchInclusos('boxdespejo')} />
                    <span className="switch-text" id="boxdespejo">Box Despejo</span>

                    <Switch
                        checked={(imovel.incluso.includes('cercaeletrica') || condominioSelected.incluso.includes('cercaeletrica'))}
                        disabled={condominioSelected.incluso.includes('cercaeletrica')}
                        onChange={() => changeSwitchInclusos('cercaeletrica')} />
                    <span className="switch-text" id="cercaeletrica">Cerca Elétrica</span>

                    <Switch
                        checked={(imovel.incluso.includes('circuitotv') || condominioSelected.incluso.includes('circuitotv'))}
                        disabled={condominioSelected.incluso.includes('circuitotv')}
                        onChange={() => changeSwitchInclusos('circuitotv')} />
                    <span className="switch-text" id="circuitotv">Circuito de TV</span>

                    <Switch
                        checked={(imovel.incluso.includes('gascanalizado') || condominioSelected.incluso.includes('gascanalizado'))}
                        disabled={condominioSelected.incluso.includes('gascanalizado')}
                        onChange={() => changeSwitchInclusos('gascanalizado')} />
                    <span className="switch-text" id="gascanalizado">Gás Canalizado</span>

                    <Switch
                        checked={(imovel.incluso.includes('interfone') || condominioSelected.incluso.includes('interfone'))}
                        disabled={condominioSelected.incluso.includes('interfone')}
                        onChange={() => changeSwitchInclusos('interfone')} />
                    <span className="switch-text" id="interfone">Interfone</span>

                    <Switch
                        checked={(imovel.incluso.includes('jardim') || condominioSelected.incluso.includes('jardim'))}
                        disabled={condominioSelected.incluso.includes('jardim')}
                        onChange={() => changeSwitchInclusos('jardim')} />
                    <span className="switch-text" id="jardim">Jardim</span>

                    <Switch
                        checked={(imovel.incluso.includes('lavanderia') || condominioSelected.incluso.includes('lavanderia'))}
                        disabled={condominioSelected.incluso.includes('lavanderia')}
                        onChange={() => changeSwitchInclusos('lavanderia')} />
                    <span className="switch-text" id="lavanderia">Lavanderia</span>

                    <Switch
                        checked={(imovel.incluso.includes('portaoeletronico') || condominioSelected.incluso.includes('portaoeletronico'))}
                        disabled={condominioSelected.incluso.includes('portaoeletronico')}
                        onChange={() => changeSwitchInclusos('portaoeletronico')} />
                    <span className="switch-text" id="portaoeletronico">Portão Eletrônico</span>

                    <Switch
                        checked={(imovel.incluso.includes('portaria24horas') || condominioSelected.incluso.includes('portaria24horas'))}
                        disabled={condominioSelected.incluso.includes('portaria24horas')}
                        onChange={() => changeSwitchInclusos('portaria24horas')} />
                    <span className="switch-text" id="portaria24horas">Portaria 24 horas</span>

                    <Switch
                        checked={(imovel.incluso.includes('seguranca24horas') || condominioSelected.incluso.includes('seguranca24horas'))}
                        disabled={condominioSelected.incluso.includes('seguranca24horas')}
                        onChange={() => changeSwitchInclusos('seguranca24horas')} />
                    <span className="switch-text" id="seguranca24horas">Segurança 24 horas</span>

                    <Switch
                        checked={(imovel.incluso.includes('quintal') || condominioSelected.incluso.includes('quintal'))}
                        disabled={condominioSelected.incluso.includes('quintal')}
                        onChange={() => changeSwitchInclusos('quintal')} />
                    <span className="switch-text" id="quintal">Quintal</span>

                    <Switch
                        checked={(imovel.incluso.includes('gramado') || condominioSelected.incluso.includes('gramado'))}
                        disabled={condominioSelected.incluso.includes('gramado')}
                        onChange={() => changeSwitchInclusos('gramado')} />
                    <span className="switch-text" id="gramado">Gramado</span>

                    <Switch
                        checked={(imovel.incluso.includes('academia') || condominioSelected.incluso.includes('academia'))}
                        disabled={condominioSelected.incluso.includes('academia')}
                        onChange={() => changeSwitchInclusos('academia')} />
                    <span className="switch-text" id="academia">Academia</span>

                    <Switch
                        checked={(imovel.incluso.includes('churrasqueira') || condominioSelected.incluso.includes('churrasqueira'))}
                        disabled={condominioSelected.incluso.includes('churrasqueira')}
                        onChange={() => changeSwitchInclusos('churrasqueira')} />
                    <span className="switch-text" id="churrasqueira">Churrasqueira</span>

                    <Switch
                        checked={(imovel.incluso.includes('hidromassagem') || condominioSelected.incluso.includes('hidromassagem'))}
                        disabled={condominioSelected.incluso.includes('hidromassagem')}
                        onChange={() => changeSwitchInclusos('hidromassagem')} />
                    <span className="switch-text" id="hidromassagem">Hidromassagem</span>

                    <Switch
                        checked={(imovel.incluso.includes('homecinema') || condominioSelected.incluso.includes('homecinema'))}
                        disabled={condominioSelected.incluso.includes('homecinema')}
                        onChange={() => changeSwitchInclusos('homecinema')} />
                    <span className="switch-text" id="homecinema">Homecinema</span>

                    <Switch
                        checked={(imovel.incluso.includes('piscina') || condominioSelected.incluso.includes('piscina'))}
                        disabled={condominioSelected.incluso.includes('piscina')}
                        onChange={() => changeSwitchInclusos('piscina')} />
                    <span className="switch-text" id="piscina">Piscina</span>

                    <Switch
                        checked={(imovel.incluso.includes('playground') || condominioSelected.incluso.includes('playground'))}
                        disabled={condominioSelected.incluso.includes('playground')}
                        onChange={() => changeSwitchInclusos('playground')} />
                    <span className="switch-text" id="playground">Playground</span>

                    <Switch
                        checked={(imovel.incluso.includes('quadraesportiva') || condominioSelected.incluso.includes('quadraesportiva'))}
                        disabled={condominioSelected.incluso.includes('quadraesportiva')}
                        onChange={() => changeSwitchInclusos('quadraesportiva')} />
                    <span className="switch-text" id="quadraesportiva">Quadra Esportiva</span>

                    <Switch
                        checked={(imovel.incluso.includes('quadratenis') || condominioSelected.incluso.includes('quadratenis'))}
                        disabled={condominioSelected.incluso.includes('quadratenis')}
                        onChange={() => changeSwitchInclusos('quadratenis')} />
                    <span className="switch-text" id="quadratenis">Quadra de Tênis</span>

                    <Switch
                        checked={(imovel.incluso.includes('salamassagem') || condominioSelected.incluso.includes('salamassagem'))}
                        disabled={condominioSelected.incluso.includes('salamassagem')}
                        onChange={() => changeSwitchInclusos('salamassagem')} />
                    <span className="switch-text" id="salamassagem">Sala de Massagem</span>

                    <Switch
                        checked={(imovel.incluso.includes('salaofestas') || condominioSelected.incluso.includes('salaofestas'))}
                        disabled={condominioSelected.incluso.includes('salaofestas')}
                        onChange={() => changeSwitchInclusos('salaofestas')} />
                    <span className="switch-text" id="salaofestas">Salão de Festas</span>

                    <Switch
                        checked={(imovel.incluso.includes('salaojogos') || condominioSelected.incluso.includes('salaojogos'))}
                        disabled={condominioSelected.incluso.includes('salaojogos')}
                        onChange={() => changeSwitchInclusos('salaojogos')} />
                    <span className="switch-text" id="salaojogos">Salão de Jogos</span>

                    <Switch
                        checked={(imovel.incluso.includes('sauna') || condominioSelected.incluso.includes('sauna'))}
                        disabled={condominioSelected.incluso.includes('sauna')}
                        onChange={() => changeSwitchInclusos('sauna')} />
                    <span className="switch-text" id="sauna">Sauna</span>

                    <Switch
                        checked={(imovel.incluso.includes('wifi') || condominioSelected.incluso.includes('wifi'))}
                        disabled={condominioSelected.incluso.includes('wifi')}
                        onChange={() => changeSwitchInclusos('wifi')} />
                    <span className="switch-text" id="wifi">Wi-fi</span>

                    <Switch
                        checked={(imovel.incluso.includes('espacogourmet') || condominioSelected.incluso.includes('espacogourmet'))}
                        disabled={condominioSelected.incluso.includes('espacogourmet')}
                        onChange={() => changeSwitchInclusos('espacogourmet')} />
                    <span className="switch-text" id="espacogourmet">Espaço Gourmet</span>

                    <Switch
                        checked={(imovel.incluso.includes('garageband') || condominioSelected.incluso.includes('garageband'))}
                        disabled={condominioSelected.incluso.includes('garageband')}
                        onChange={() => changeSwitchInclusos('garageband')} />
                    <span className="switch-text" id="garageband">Garageband</span>

                    <Switch
                        checked={(imovel.incluso.includes('quadrasquash') || condominioSelected.incluso.includes('quadrasquash'))}
                        disabled={condominioSelected.incluso.includes('quadrasquash')}
                        onChange={() => changeSwitchInclusos('quadrasquash')} />
                    <span className="switch-text" id="quadrasquash">Quadra Squash</span>

                    <Switch
                        checked={(imovel.incluso.includes('solmanha') || condominioSelected.incluso.includes('solmanha'))}
                        disabled={condominioSelected.incluso.includes('solmanha')}
                        onChange={() => changeSwitchInclusos('solmanha')} />
                    <span className="switch-text" id="solmanha">Sol da manhã</span>

                    <Switch
                        checked={(imovel.incluso.includes('vistamar') || condominioSelected.incluso.includes('vistamar'))}
                        disabled={condominioSelected.incluso.includes('vistamar')}
                        onChange={() => changeSwitchInclusos('vistamar')} />
                    <span className="switch-text" id="vistamar">Vista do Mar</span>

                    <Switch
                        checked={(imovel.incluso.includes('varandagourmet') || condominioSelected.incluso.includes('varandagourmet'))}
                        disabled={condominioSelected.incluso.includes('varandagourmet')}
                        onChange={() => changeSwitchInclusos('varandagourmet')} />
                    <span className="switch-text" id="varandagourmet">Varanda Gourmet</span>

                    <Switch
                        checked={(imovel.incluso.includes('lareira') || condominioSelected.incluso.includes('lareira'))}
                        disabled={condominioSelected.incluso.includes('lareira')}
                        onChange={() => changeSwitchInclusos('lareira')} />
                    <span className="switch-text" id="lareira">Lareira</span>

                    <Switch
                        checked={(imovel.incluso.includes('cabeamentoestruturado') || condominioSelected.incluso.includes('cabeamentoestruturado'))}
                        disabled={condominioSelected.incluso.includes('cabeamentoestruturado')}
                        onChange={() => changeSwitchInclusos('cabeamentoestruturado')} />
                    <span className="switch-text" id="cabeamentoestruturado">Cabeamento Estruturado</span>

                    <Switch
                        checked={(imovel.incluso.includes('tvacabo') || condominioSelected.incluso.includes('tvacabo'))}
                        disabled={condominioSelected.incluso.includes('tvacabo')}
                        onChange={() => changeSwitchInclusos('tvacabo')} />
                    <span className="switch-text" id="tvacabo">TV a Cabo</span>

                    <Switch
                        checked={(imovel.incluso.includes('conexaointernet') || condominioSelected.incluso.includes('conexaointernet'))}
                        disabled={condominioSelected.incluso.includes('conexaointernet')}
                        onChange={() => changeSwitchInclusos('conexaointernet')} />
                    <span className="switch-text" id="conexaointernet">Conexao com internet</span>

                    <Switch
                        checked={(imovel.incluso.includes('vistamontanha') || condominioSelected.incluso.includes('vistamontanha'))}
                        disabled={condominioSelected.incluso.includes('vistamontanha')}
                        onChange={() => changeSwitchInclusos('vistamontanha')} />
                    <span className="switch-text" id="vistamontanha">Vista da Montanha</span>

                    <Switch
                        checked={(imovel.incluso.includes('vistalago') || condominioSelected.incluso.includes('vistalago'))}
                        disabled={condominioSelected.incluso.includes('vistalago')}
                        onChange={() => changeSwitchInclusos('vistalago')} />
                    <span className="switch-text" id="vistalago">Vista do Lago</span>

                    <Switch
                        checked={(imovel.incluso.includes('aceitaPet') || condominioSelected.incluso.includes('aceitaPet'))}
                        disabled={condominioSelected.incluso.includes('aceitaPet')}
                        onChange={() => changeSwitchInclusos('aceitaPet')} />
                    <span className="switch-text" id="aceitaPet">Aceita PET</span>

                    <Switch
                        checked={(imovel.incluso.includes('lancamento') || condominioSelected.incluso.includes('lancamento'))}
                        disabled={condominioSelected.incluso.includes('lancamento')}
                        onChange={() => changeSwitchInclusos('lancamento')} />
                    <span className="switch-text" id="lancamento">Lançamento</span>

                    <Switch
                        checked={(imovel.incluso.includes('emConstrucao') || condominioSelected.incluso.includes('emConstrucao'))}
                        disabled={condominioSelected.incluso.includes('emConstrucao')}
                        onChange={() => changeSwitchInclusos('emConstrucao')} />
                    <span className="switch-text" id="emConstrucao">Em Construção</span>

                </div>

                <br />
                <br />
                <br />

                <div>

                    <h3>Publicar imóvel</h3>

                    <Switch
                        checked={!imovel.disabled}
                        onChange={() => setImovel({ ...imovel, disabled: !imovel.disabled })} />
                    <span className="switch-acoes" style={{ marginLeft: 1 }} id="imovel-inativo">{!imovel.disabled ? 'Imóvel Ativo no site' : 'Imóvel Inativo no site'}</span>

                    {/* <Switch
                        checked={imovel.vivareal}
                        onChange={() => setImovel({ ...imovel, vivareal: !imovel.vivareal })} />
                    <span className="switch-acoes" style={{ marginLeft: 1 }} id="imovel-vivarel">{imovel.vivareal ? 'Viva Real Ativo' : 'Viva Real Inativo'}</span> */}

                    <Switch
                        checked={imovel.published}
                        disabled={GlobalState.userData.role === 'USER'}
                        onChange={() => setImovel({ ...imovel, published: !imovel.published })} />
                    <span className="switch-acoes" style={{ marginLeft: 1 }} id="imovel-published">{imovel.published ? 'Imóvel Publicado' : 'Imóvel em Moderação'}</span>

                    <Switch
                        checked={imovel.destaque}
                        disabled={GlobalState.userData.role === 'USER'}
                        onChange={() => setImovel({ ...imovel, destaque: !imovel.destaque })} />
                    <span className="switch-acoes" style={{ marginLeft: 1 }} id="imovel-destaque">{imovel.destaque ? 'Destaque habilitado' : 'Sem Destaque'}</span>
                    
                    <Switch
                        checked={imovel.purchased}
                        disabled={GlobalState.userData.role === 'USER'}
                        onChange={() => setImovel({ ...imovel, purchased: !imovel.purchased })} />
                    <span className="switch-acoes" style={{ marginLeft: 1 }} id="imovel-purchased">{imovel.purchased ? 'Imóvel Vendido - Não disponível para venda' : 'Imóvel disponível para venda'}</span>

                </div>


                <br />
                <br />
                <br />

                <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : ''}
                    disabled={
                        loading ||
                        imgLarges.length < 3 ||
                        imovel.tipoImovel === 0 ||
                        imovel.captadorId === 0
                    }
                >
                    {idJB ? 'Atualizar Imóvel' : 'Cadastrar Imóvel'}
                </Button>

                <Button
                    variant="outlined"
                    color="secondary"
                    className={localStorage.getItem('debug') ? '' : 'disabled'}
                    // onClick={() => console.log(AWS_ACCESS_KEY_ID)}
                    style={{ marginLeft: 30 }}
                >
                    Debug
                </Button>

            </form>

            <br />
            <br />

            <Dialog disableBackdropClick disableEscapeKeyDown open={dialogOpen} onClose={() => setDialogOpen(false)}>

                <DialogTitle>{ownerSelected ? 'Visualizar Proprietário' : 'Cadastrar Novo Proprietário'}</DialogTitle>

                <DialogContent>

                    <TextField
                        label="Nome do Proprietário"
                        type="text"
                        variant="outlined"
                        style={{ width: 500 }}
                        fullWidth
                        value={ownerSelected ? ownerSelected.nome : ownerToBeCreated.nome}
                        onChange={e => setOwnerToBeCreated({ ...ownerToBeCreated, nome: captalize(e.target.value) })}
                        disabled={ownerSelected}
                    />

                    <br />
                    <br />

                    <TextField
                        label="Telefone proprietário"
                        type="text"
                        variant="outlined"
                        style={{ width: 245 }}
                        fullWidth
                        value={ownerSelected ? ownerSelected.tel : ownerToBeCreated.tel}
                        onChange={e => setOwnerToBeCreated({ ...ownerToBeCreated, tel: onlyNumber(e.target.value) })}
                        disabled={ownerSelected}
                    />

                    <TextField
                        label="E-mail proprietário"
                        type="text"
                        variant="outlined"
                        style={{ width: 245, marginLeft: 10 }}
                        fullWidth
                        value={ownerSelected ? ownerSelected.email : ownerToBeCreated.email}
                        onChange={e => setOwnerToBeCreated({ ...ownerToBeCreated, email: e.target.value })}
                        disabled={ownerSelected}
                    />

                </DialogContent>

                <DialogActions>

                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        Fechar
                    </Button>
                    <Button onClick={insertProprietario} color="primary" className={ownerSelected ? 'disabled' : ''}>
                        OK
                    </Button>
                </DialogActions>

            </Dialog>

        </Container>
    )
}

export default CadastroImovel