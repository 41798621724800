import styled from 'styled-components'

import Config from '../../Config'

export const ContainerCarousel = styled.div`

    .carousel-root {
        @media screen and (max-width: 1300px) {
            padding-top: 43px;
        }

        @media screen and (min-width: 1300px) {
            padding-top: 85px;

            .slide img {
               padding-top: 35px;
            }
        }
    }

    #container-filtros {
        position: absolute;
        width: 414px;
        height: 390px;
        left: 888px;
        top: 125px;
        background: rgba(209, 217, 221, 0.7);

        @media screen and (max-width: 1300px) {
            width: 100%;
            top: unset;
            bottom: 20px;
            left: 0;
            position: sticky;
        }

        @media screen and (min-width: 1550px) {
            left: 1009px;
        }

        @media screen and (min-width: 1700px) {
            left: 1200px;
            top: 200px;
        }

        #titulo-filtros {
            background: rgb(55 136 137);
            height: 70px;
            padding-top: 3px;
            display: flex;
            padding-left: 45px;

            span {
                color: #fff;
                font-size: 43px;
                margin-top: 11px;
                margin-right: 13px;
            }
            
            h2 {
                font-size: 21px;
                line-height: 39px;
                text-align: center;
                font-weight: 300;
                color: #fff;
            }
        }

        #padding-filtros {
            padding: 20px;

            > div {
                margin-bottom: 10px;
            }

            .MuiSelect-outlined.MuiSelect-outlined {
                border: 1px solid #02A4FF;
                font-family: 'Roboto';
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                color: #000 !important;
                background-color: #EEEEEE;
                border-radius: 0;

                .MuiSelect-icon {
                    color: #02A4FF !important;
                }
            }
        }

    }

    .slider-home {
        width: 100%;
        margin-top: 126px;

        @media screen and (max-width: 1300px) {
            height: 553px;
            margin-top: 40px;
            
            .slide-desktop { display: none }

            .bg-content {
                position: absolute;
                width: 100%;
                height: 114px;
                top: 306px;
                background: rgba(0, 166, 165, 0.6);
                padding: 0 34px;

                h2 {
                    color: #fff;
                    padding: 10px 10px 0 10px;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                }

                .MuiButton-contained {
                    box-shadow: none;
                }
            }
        }
        
        @media screen and (min-width: 1300px) {
            height: 600px;
            margin-top: -45px;

            .slide-mobile { display: none }

            .bg-content {
                position: absolute;
                width: 443px;
                height: 217px;
                left: 35px;
                top: 292px;
                background: rgba(0, 166, 165, 0.6);
                padding: 0 34px;

                @media screen and (max-width: 1300px) {
                    height: 98px;
                    left: 0px;
                    top: 359px;
                }

                h2 {
                    color: #fff;
                    padding: 10px 10px 0 10px;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 42px;
                    text-align: center;
                }
            }
        }

        @media screen and (min-width: 1700px) {
            height: 700px;
            margin-top: -37px;
        }
    }
`

export const Container = styled.div`
    font-family: Roboto;
    font-size: 20px;
    padding-top: 10px;

    @media screen and (min-width: 1300px) {
        max-width: 1263px;
        margin: 0 auto;
    }

    #main-feed {
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 0 17px;

        #select-alugar-ou-comprar {
            border: 3px solid ${Config.primary_color};
            box-sizing: border-box;
            border-radius: 9px;
            width: 289px;
            height: 45px;
            display: flex;
            cursor: pointer;

            .select-activated {
                background: ${Config.primary_color};
                border: 3px solid ${Config.primary_color};

                span { color: #fff !important }
            }

            #select-alugar, #select-comprar {
                width: 144px;
                height: 39px;
                box-sizing: border-box;
                border-radius: 9px;
                border: 3px solid #fff;

                span {
                    font-weight: 300;
                    font-size: 18px;
                    color: ${Config.primary_color};
                    line-height: 32px;
                }
            }
        }

        #goto-search {

            @media screen and (min-width: 1300px) {
                /* width: calc(100vw / 2) !important; */
                display: none;
            }

            width: 335px;
            height: 65px;
            border: 3px solid ${Config.primary_color};
            box-sizing: border-box;
            border-radius: 9px;
            display: flex;
            padding: 19px;

            #goto-search-icon {
                margin-right: 10px;
                color: ${Config.primary_color}
            }

            p {
                font-family: Roboto;
                font-size: 16px;
                line-height: 0;
                margin-top: 12px;
                color: ${Config.primary_color}
            }
        }

        @media screen and (min-width: 1300px) {
            p#proprietario-anuncie {
                width: 600px !important;
            }
        }

        p#proprietario-anuncie {
            color: #8E5916;
            margin: 35px 0;
            width: 280px;
        }
    }

    #padding {
        padding-left: 17px;

        p { padding-right: 17px }

        @media screen and (min-width: 1200px) {
            .feed-category {width: 1228px !important }
        }

        h2#condominio-destaque-texto {
            font-family: Roboto;
            font-weight: 900;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #00A6A5;
            margin-bottom: 40px;
        }

        #condominios-destaque {
            height: 281px;
            margin-left: 10px;
            padding-right: 30px;

            .card {
                width: 230px;
                height: 281px;
                
                .card-img {
                    width: 220px;
                    height: 281px;

                    background-image: url('/loading-34.gif');
                    background-repeat: no-repeat;
                    background-color: #f1f2f3;
                    background-position: center;
                    background-size: 50px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .condominio-nome {
                    position: relative;
                    width: 202px;
                    height: 78px;
                    min-height: 78px;
                    bottom: 92px;
                    background: rgb(0 166 165 / 50%);
                    border-radius: 0;
                    padding: 19px;

                    span {
                        font-family: Roboto;
                        font-weight: 900;
                        font-size: 18px;
                        line-height: 21px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .feed-category {
            margin-bottom: 30px;

            @media screen and (max-width: 1300px) {
                scroll-snap-type: unset
            }
        }
    }

`