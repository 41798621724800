import styled from 'styled-components'

export const PublicContainer = styled.div`

    font-family: Roboto;
    font-size: 20px;
    width: 100%;
`

export const DashContainer = styled.div`

    font-family: Roboto;
    font-size: 20px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
`