import styled from 'styled-components'

export const Container = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding: 30px 20px;
    position: fixed;
    width: 100vw;
    height: 105px;
    left: 0px;
    top: 0px;
    background: rgba(246, 246, 246, 0.8);
    z-index: 99;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding-right: 50px;

    #searchBox {
        width: 546px;
        height: 50px;
        background-color: #E5E5E7;
        border-radius: 10px;
        padding: 15px;
        display: flex;
        vertical-align: middle;

        span { color: #A7A7AF }

        #searchText {
            padding-top: 0px;
            font-size: 18px;
            margin-left: 12px;
        }
    }
`