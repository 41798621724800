import React, { memo } from 'react'

function Map ({ endereco }) {

    // Documentação baseada >>https://developers.google.com/maps/documentation/embed/embedding-map

    var zoom = 14

    // search = mostra somente a região sem o marcador
    // place = mostra o condominio
    var tipo = "search"

    return (

        <iframe
            width="100%"
            height="450"
            title="alpha-select-map"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen={false}
            referrerpolicy="no-referrer-when-downgrade"
            // onLoad={() => removeInfosGmaps()}
            id="iframe-gmaps"
            disableDefaultUI
            src={`https://www.google.com/maps/embed/v1/${tipo}?key=AIzaSyCsVIyuWec4-IO0WQhHekg3T4h88q_K3Dg&q=${endereco}&zoom=${zoom}`}
        >
        </iframe>

    )
}

export default memo(Map)