import styled from 'styled-components'

import Config from '../../Config'

export const Container = styled.div`

    font-family: Roboto;
    font-size: 20px;
    padding: 20px 60px 20px 20px;
    width: 80vw;
    background-color: #F2F2F3;
    padding-top: 137px;
    height: 100%;

    #title-top { display: flex }
    h1 { margin: 0 0 0 15px }

    .MuiInputBase-root { margin-right: 20px }

    .box-upload, .box-imported {

        span {
            font-size: 14px;
            font-family: Roboto;
            color: ${Config.primary_color};
            font-weight: bold;
        }

        h2 { margin: 10px 0 }

        .box-img {
            margin-top: 10px;
            width: 100%;
            min-height: 110px;
            padding: 0 20px;
            border: solid ${Config.primary_color} 3px;
            border-radius: 15px;

            .align-input-button {
                display: flex;
                justify-content: space-between;
                height: 57px;

                #dropzone { width: 70%; height: 100% }

                #dropzone div {
                    height: 105px;
                    width: 145%;
                    padding: 40px 0;

                    span { cursor: pointer }
                }

                button { margin-top: 17px }
            }
        }

        h2 { line-height: 0 }

        .imgsSelected { padding: 10px 0 }

        img { margin-right: 8px }

    }

    .box-imported {
        .box-img { border-width: 1px }
        .imgsSelected img { cursor: pointer }
    }

    .sumOne.material-icons {
        margin-left: -5px;
        margin-right: 15px;
        margin-top: 15px;
        cursor: pointer;
    }

    .switch-text, .checkbox-text {
        font-size: 14px;
        margin-left: -8px;
    }

    .switch-text { margin-right: 13px }

    #incluso-autocomplete {

        > div { display: inline-block }

        #button-ok-inclusos { margin-top: 25px }
    }

    #incluso {
        display: inline-grid;
        grid-template-columns: 62px 15% 62px 15% 62px 15% 62px 15% 62px 15%;
        row-gap: 0.5%;
        justify-content: space-between;
        width: 100%;

        span { align-self: center }
    }
`