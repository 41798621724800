import styled from 'styled-components'

import Config from '../../Config'

import IconArrow from '../../assets/icon-arrow.svg'

export const Container = styled.div`

    font-family: Roboto;
    font-size: 20px;
    padding-top: 79px;

    @media screen and (min-width: 1800px) {
        padding-top: 88px;
    }

    #slide-fullscreen {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgb(0 0 0 / 90%);
        z-index: 9999;
        padding-right: 20px;
        
        img {
            width: auto;
            height: 80vh;

            @media screen and (max-width: 800px) {
                width: 90vw;
                height: auto;
                padding-top: 150px;
            }
        }

        .thumb {
            width: 80px;
            height: 80px;
            border: none;

            img {
                width: 80px;
                height: 80px;
                padding-top: 0 !important;
            }
        }

        li:has(.error) {
            display: none;
        }

        .thumb.selected {
            border: solid 1px #fff;
        }

        #close-fullscreen {
            position: fixed;
            top: 20px;
            left: 50px;
            z-index: 1000;
            
            span {
                color: #fff;
                font-size: 32px;
            }
            
            :hover {
                cursor: pointer;
                opacity: .8;
            }
        }
    }

    #imovel-slide-wrapper {
        width: 100vw;

        #arrow-left, #arrow-right {
            position: absolute;
            color: #fff !important;
            height: 100%;
            top: 32%;
            cursor: pointer;
            font-size: 45px;

            @media screen and (min-width: 800px) {
                top: 35%;
            }

            @media screen and (min-width: 1800px) {
                top: 28%;
            }
        }

        #arrow-left { left: 25px }

        #arrow-right { right: 25px }

        #imovel-slide-items {
            overflow-y: hidden;
            overflow-x: auto;
            display: flex;
            scroll-snap-type: x mandatory;

            /* Efeito smooth para rolar para o lado */
            scroll-behavior: smooth;

            @media screen and (min-width: 800px) {
                /* Remove a barra de scroll somente no desktop, 
                no mobile o efeito de rolar para de funcionar*/
                overflow-x: hidden !important;
            }

            .slide-item {
                scroll-snap-align: start;
                flex: none;

                background-image: url('/loading-34.gif');
                background-repeat: no-repeat;
                background-color: #f1f2f3;
                background-position: center;
                background-size: 50px;

                height: 385px;
                width: 99vw;
                border-right: #fff solid 1px;

                @media screen and (min-width: 800px) {
                    width: 33.333vw !important;
                }

                @media screen and (min-width: 1600px) {
                    width: 33.333vw !important;
                    height: 400px !important;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    cursor: pointer;
                }
            }
        }
    }

    .carousel-root {
        height: 385px;;

        .slide {
            
            img.loading-gif-background {
                object-fit: cover;
                height: 385px;

                background-image: url('/loading-34.gif');
                background-repeat: no-repeat;
                background-color: #f1f2f3;
                background-position: center;
                background-size: 50px;
            }
        }
    }

    #header-imovel {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 51.33%, rgba(0, 0, 0, 0) 100%);
        width: 100vw;
        height: 85px;
        position: absolute;
        display: flex;
        justify-content: space-between;
        padding: 25px 30px;
        position: fixed;
        z-index: 99;
        
        margin-top: -379px;

        @media screen and (min-width: 800px) {
            padding: 25px 60px;
        }

        @media screen and (min-width: 1600px) {
            margin-top: -400px !important;
            padding: 25px 100px;
        }

        #buscar, #editar, #proximo, #bypass, #ver-proprietario, #fullscreen {
            color: #fff;
            font-size: 14px;

            span {
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        #editar, #proximo, #bypass, #ver-proprietario {
            @media screen and (max-width: 800px) {
                display: none
            }
        }

        #bypass { span { .material-icons { margin-top: -13px; }}}

        #bypass:hover, #ver-proprietario:hover { cursor: pointer }
    }

    div#margin-laterais {
        padding: 30px 27px;

        @media screen and (min-width: 800px) {
            max-width: 800px;
        }
        
        @media screen and (min-width: 1600px) {
            max-width: 1000px;
            margin-left: 65px;
        }

        span#outros-no-condominio {
            font-size: 18px;
            color: #969393;
            margin-top: 30px;
            cursor: pointer;
        }
        
        hr { border-color: ${Config.primary_color} }

        h1 {
            font-size: 24px;
            line-height: 32px;
        }

        #localizacao-metragem-quartos {

            justify-content: space-between;
            display: flex;

            @media screen and (min-width: 800px) {

                justify-content: flex-start;
                #localizacao-imovel { margin-right: 40px }
            }

            span#localizacao-imovel {
                color: #000 !important;
                font-weight: 500;
            }

            span {
                font-size: 16px;
                line-height: 21px;
                color: #969393;
            }

            span#metragem-construcao-quarto {
                width: 145px;
                min-width: 145px;
                text-align: right;
            }
        }

        #icones-imovel {
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            
            .icone-imovel-item {
                flex-grow: 0;
                max-width: 25%;
                flex-basis: 25%;
                display: flex;
                padding: 10px 0;
                min-width: 160px;
                min-height: 65px;

                .icon {
                    font-size: 40px;
                }

                div {
                    margin-left: 10px;

                    p {
                        font-size: 16px;
                        margin: 0;
                    }

                    p:nth-child(2) {
                        font-size: 12px;
                    }

                    .margin-unico-item {
                        margin-top: 11px;
                    }
                }
            }
        }

        // arruma a posição quando manda pro link href="#orientar"
        [id]::before {
            content: '';
            display: block;
            height: 90px;
            margin-top: -90px;
            visibility: hidden;
        }

        #valoresImovel {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 400;

            .valorUnitario {
                display: flex;
                justify-content: space-between;
                margin: 7px 0;

                .tempoPagamento {
                    color: #969393;
                    margin-left: 5px;
                }

                .icon-info {
                    font-size: 18px;
                    color: #969393;
                    line-height: 23px;
                }
            }

            #orientar {
                margin-top: 20px;
                display: flex;

                @media screen and (max-width: 1300px) {
                    border-top: 1px solid #098485;
                    padding-top: 40px;
                    margin-top: 40px;
                }

                span {
                    font-size: 35px;
                }

                h2 {
                    font-size: 18px;
                    margin-bottom: 20px;
                    font-weight: bold;
                    margin-left: 22px;
                    margin-top: -5px;
                }
            }


            hr {
                border-color: ${Config.primary_color};
                border-style: dashed;
                margin: 18px 0;
            }

            .valorTotal { color: ${Config.primary_color} }

            @media screen and (min-width: 800px) {
                position: absolute;
                width: 382px;
                background-color: #fff;
                right: 70px;
                margin-top: -427px;
                padding: 20px 30px 40px 30px;
                border: 1px solid #e0e0e0;
                box-shadow: -12px -2px 6px 0 rgb(0 0 0 / 8%);
            }

            @media screen and (min-width: 1600px) {
                padding: 20px 30px 40px 30px;
                width: 381px;
                right: 270px;
            }

            // texto do checkbox - prefere whatsapp
            span {
                font-family: Roboto;
                font-size: 15px;
            }
        }

        #valoresImovel.fixed-container {
            position: fixed;
            top: 582px;
        }

        #descricaoImovel, #proximidadesImovel, #imoveisSimilares, #inclusosImovel, #mapaRegiao {

            h4 {
                font-size: 18px;
                font-weight: bold;
            }

            p {
                font-size: 18px;
                line-height: 26px;
            }
        }

        #descricaoImovel, #proximidadesImovel, #inclusosImovel, #mapaRegiao {
            display: flex;
            margin-top: 20px;

            span {
                margin-top: 25px;
                margin-right: 10px;
                font-size: 24px;
            }

            div {
                white-space: pre-wrap;
                overflow-wrap: break-word;
            }
        }

        #inclusosImovel {
            margin-bottom: 50px;

            #todos-itens-inclusos {
                display: flex;
                flex-wrap: wrap;
            
                div {
                    flex-grow: 0;
                    max-width: 25%;
                    flex-basis: 25%;
                    padding: 2px 0;
                    min-width: 200px;
                    display: flex;
                    margin-right: 30px;
                    
                    @media screen and (min-width: 1300px) {
                        max-height: 47px;
                    }
                    
                    p {
                        margin: 0;
                        margin-top: 23px;
                    }

                    @media screen and (max-width: 1300px) {
                        span, p {
                            margin-top: 9px;
                        }
                    }
                }
            }
        }

        #codigoImovel {
            font-size: 18px;
        }

        #condominioInfos {

            margin: 40px 0;

            #condominio-icon {
                font-size: 60px;
            }

            p#gostou-condominio {
                font-weight: bold;

                span { font-weight: 400 }
            }
        }

        #arrow-left {
            margin-left: 15px
        }

        #arrow-right {
            margin-left: 93.4vw !important;
            
            @media screen and (min-width: 1300px) {
                margin-left: 82.5vw !important;
            }
        }

        #imoveisSimilares {
            width: 90vw !important;

            h4 {
                font-size: 24px;
            }

            #similar-container {
                display: flex;

                #arrow-right-similar, #arrow-left-similar {
                    background-image: url(${IconArrow});
                }

                #arrow-left-similar, #arrow-right-similar {
                    height: 400px;
                    width: 12px;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    cursor: pointer;
                }

                #arrow-right-similar {
                    transform: rotate(180deg);
                    margin-left: 0 !important;
                }

                .feed-category {
                    width: 90vw;
                    margin-left: 20px;
                    margin-right: 20px;

                    @media screen and (max-width: 1300px) {
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                    }

                    .card-img {
                        background-image: url('/loading-34.gif');
                        background-repeat: no-repeat;
                        background-color: #f1f2f3;
                        background-position: center;
                        background-size: 50px;
                    }
                }
            }
        }

        /* DIV com id similar é a div com scroll-snap --- está sendo usada para customizar a .feed-categor somente nesse arquivo */
        #similar {
            scroll-snap-type: none !important;
        }

        /* Classe para arrumar as setas nos imoveis similares */
        .similar {
            background-color: #ffffff !important;
            width: 30px !important;
            height: 383px !important;
            opacity: .6 !important;
            margin-left: 0 !important;

            /* Animation onhover */
            -moz-transition: opacity .5s !important;
            -webkit-transition: opacity .5s !important;
            -o-transition: opacity .5s !important;
            transition: opacity .5s !important;
        }

        .similar:hover { opacity: .8 !important }
    }

    .Mui-disabled {
        color: rgba(0, 0, 0, 1) !important
    }

`