import styled from 'styled-components'

export const Container = styled.div`

    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding: 20px;
    background-color: #000;
    height: 100vh;
    padding: 100px 20px;

    #logo-jeb {
        width: auto;
        height: 64px;
        margin-bottom: 66px;
    }

    #buttons-entrar-esqueci-senha {
        display: flex;
        height: 37px;
        margin-top: 20px;

        p {
            color: #fff;
            line-height: 1px;
            font-size: 14px;
            margin-left: 35px;
        }
    }

    hr {
        margin: 50px 0;
    }

    #cadastre-se {
        display: flex;
        height: 37px;

        h2 {
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            line-height: 0;
            margin-right: 20px;
        }
    }
`